/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   title: title string (optional)
 *   loading: boolean to toggle the loading animation (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   noPadding: remove the padding
 *   restrictWidth: restrict the width of the card on large screens
 *
 **********/
import { motion } from 'framer-motion'

import { Loader, ClassHelper } from 'components/lib'
import Style from './card.tailwind.js'
import { CardVariants } from 'utils/variants'

export function Card(props) {
    const cardStyle = ClassHelper(Style, {
        shadow: props.shadow,
        center: props.center,
        noPadding: props.noPadding,
        loading: props.loading,
        className: props.className,
        last: props.last,
        restrictWidth: props.restrictWidth,
    })

    return (
        <motion.section className={cardStyle} style={{ overflow: props?.overflow }} variants={CardVariants} initial='visible' animate='visible' exit='hidden' transition={{ duration: 0.3 }}>
            {props.title && (
                <header className={Style.header}>
                    <h1 className={Style.title}>{props.title}</h1>
                </header>
            )}

            {props.loading ? <Loader /> : props.children}
        </motion.section>
    )
}
