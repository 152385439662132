import { CompanyDashboard } from 'views/categorizeme/company'
import { TransactionDashboard } from 'views/categorizeme/transaction'
import { GeneralSettings } from 'views/categorizeme/general-settings'
import { NotificationSettings } from 'views/categorizeme/notification-settings'
import { ClientUsers } from 'views/categorizeme/client-users'
import { OtherSettings } from 'views/categorizeme/other-settings'
import { Disconnect } from 'views/categorizeme/disconnect'
import { Disconnected } from 'views/categorizeme/disconnected'

const Routes = [
    {
        path: '/company',
        view: CompanyDashboard,
        layout: 'categorizeme',
        permission: 'clientStaff',
        title: 'Company',
    },
    {
        path: '/company/:companyId/transaction',
        view: TransactionDashboard,
        layout: 'categorizeme',
        permission: 'clientStaff',
        title: 'Company Transactions',
    },
    {
        path: '/company/:companyId/general-settings',
        view: GeneralSettings,
        layout: 'categorizeme',
        permission: 'staff',
        title: 'General Settings',
    },
    {
        path: '/company/:companyId/notification-settings',
        view: NotificationSettings,
        layout: 'categorizeme',
        permission: 'staff',
        title: 'Notification Settings',
    },
    {
        path: '/company/:companyId/client-users',
        view: ClientUsers,
        layout: 'categorizeme',
        permission: 'user',
        title: 'Client Users',
    },
    {
        path: '/company/:companyId/other-settings',
        view: OtherSettings,
        layout: 'categorizeme',
        permission: 'staff',
        title: 'Other Settings',
    },
    {
        path: '/company/disconnect',
        view: Disconnect,
        layout: 'fullwith',
        title: 'Disconnect',
    },
    {
        path: '/company/disconnected',
        view: Disconnected,
        layout: 'landingpage',
        title: 'Disconnected',
    },
]

export default Routes
