const Style = {
    // base: 'py-10 md:py-14 px-0 text-left bg-white',
    base: 'py-10 md:py-14 px-0 text-left',
    transparent: 'bg-transparent',
    tint: 'bg-slate-50',
    dark: 'bg-slate-700 text-white',
    brand: 'bg-brand-500 text-white',
    left: 'text-left',
    center: 'text-center',
}

export default Style
