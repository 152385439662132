import { useContext } from 'react'
import { Tooltip } from '@mantine/core'
import { IconTrashX } from '@tabler/icons-react'

import { Icon, ViewContext, ModalForm, AuthContext } from 'components/lib'
import { ApiContext } from 'context/api'
import Style from './searchbar.tailwind'

export const ClientSearchbar = (props) => {
    const viewContext = useContext(ViewContext)
    const authContext = useContext(AuthContext)
    const { advSearchValue, setSearchValue, setPage, setSearchQuery, searchQuery, setCompany, setClient, setStatus, setActivity, setAdvSearchValue, isDateFilterActive, setIsDateFilterActive, setDateFrom, setDateTo } = useContext(ApiContext)

    function handleShowHideAdvanceFilter() {
        if (authContext.searchValue) return

        viewContext.modal.show({
            title: 'Advance Filter',
            body: <ModalForm />,
        })
    }

    function handleSearchChange(e) {
        setSearchQuery(e.target.value)
        if (!e.target.value) setSearchValue('')
    }

    function handleSearch(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            setPage(1)
            setSearchValue(searchQuery)
        }
    }

    function handleClearSearch() {
        setSearchQuery('')
        setSearchValue('')
        setCompany('')
        setClient('')
        setStatus('')
        setActivity('')
        setAdvSearchValue({})
        setDateFrom('1800-01-01')
        setDateTo(new Date().toISOString().split('T')[0])
        setIsDateFilterActive(false)
        viewContext.modal.hide()
        viewContext.setLoading(true)
    }

    /* eslint-disable */
    // useEffect(() => {}, [])

    return (
        <>
            {(searchQuery || Boolean(Object.keys(advSearchValue).length) || isDateFilterActive) && (
                <span onClick={handleClearSearch} className='mr-1 cursor-pointer'>
                    <IconTrashX size='1.2rem' />
                </span>
            )}
            <input style={{ fontWeight: 'normal' }} disabled={Object.keys(advSearchValue).length || isDateFilterActive} value={searchQuery} onChange={handleSearchChange} onKeyDown={handleSearch} className={Style.input} type='text' placeholder='Search...' />

            {props.view === 'Company' && (props.permission === 'owner' || props.permission === 'staff') ? (
                <button disabled={searchQuery} className={Style.btn}>
                    <Tooltip label='Advance Filter' color='cyan' withArrow>
                        <span onClick={handleShowHideAdvanceFilter}>
                            <Icon image='sliders' size={20} color={searchQuery ? 'gray' : '#06B6D4'} />
                        </span>
                    </Tooltip>
                </button>
            ) : (
                <button onClick={() => setSearchValue(searchQuery)} className={Style.btn}>
                    <Tooltip label='Search' color='cyan' withArrow>
                        <span>
                            <Icon image='search' size={20} color='#06B6D4' />
                        </span>
                    </Tooltip>
                </button>
            )}
        </>
    )
}
