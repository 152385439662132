import { TPContainer, TPButton } from 'components/lib'
//import backgroundImage from './images/background-call-to-action.jpg'

export function TPCallToAction() {
    return (
        <section id='get-started-today' className='relative overflow-hidden bg-cyan-600 py-32'>
            <TPContainer className='relative'>
                <div className='mx-auto max-w-lg text-center'>
                    <h2 className='font-["Lexend"] text-3xl tracking-tight text-white sm:text-4xl'>Get Started Today!</h2>
                    <p className='mt-4 text-lg tracking-tight text-white'>
                        Say goodbye to the headache of exporting spreadsheets and constantly chasing clients for information. With Categorize Me, you can quickly and easily get responses from your clients about uncategorized transactions, saving you time and hassle.
                    </p>
                    <TPButton href='/signup' color='white' className='mt-10'>
                        Get 7 Days Free
                    </TPButton>
                </div>
            </TPContainer>
        </section>
    )
}
