import { useContext, useEffect } from 'react'
import { Skeleton } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'

import { ViewContext, useNavigate } from 'components/lib'
import { ApiContext } from 'context/api'

export function Disconnect() {
    const viewContext = useContext(ViewContext)
    const { handleClientDisconnectionRequest } = useContext(ApiContext)
    const navigate = useNavigate()
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const realmId = { realmId: params.get('realmId') }

    useQuery({
        queryFn: () => handleClientDisconnectionRequest(params.get('realmId'), handleOnLoadActions),
        queryKey: ['disconnect', realmId],
        enabled: Boolean(params.get('realmId')),
    })

    function handleOnLoadActions() {
        viewContext.setLoading(false)
        navigate('/company/disconnected')
    }

    /* eslint-disable */
    useEffect(() => {
        viewContext.setLoading(true)
    }, [])

    return <Skeleton visible={viewContext.loading} />
}
