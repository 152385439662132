import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Select, Tooltip } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import { ApiContext } from 'context/api'

import { Card, AccountNav, Table, Animate, TitleRow, useAPI, Icon, ViewContext, ClientButton, AuthContext } from 'components/lib'

export function ActivityLogs(props) {
    const users = useAPI('/api/v1/account/users')
    const viewContext = useContext(ViewContext)
    const authContext = useContext(AuthContext)
    const permission = authContext?.user?.permission
    const { handleFetchCompaniesRequest, handleFetchActivityLogsRequest, triggerActivityLogFilter, companyFilterLogs, staffFilterLogs, clientFilterLogs, clientStaffFilterLogs, setTriggerActivityLogFilter, setCompanyFilterLogs, setStaffFilterLogs, setClientFilterLogs, setClientStaffFilterLogs } =
        useContext(ApiContext)
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const [activityLogs, setActivityLogs] = useState([])

    const { data: getCompanies } = useQuery({
        queryFn: () => handleFetchCompaniesRequest(null),
        queryKey: ['companies'],
        enabled: !Boolean(params.get('code')) && Boolean(authContext?.user),
    })

    const { data: getActivityLogs, isLoading: isFetchActivityLogsLoading } = useQuery({
        queryFn: () => handleFetchActivityLogsRequest(handleOnLoadActions),
        queryKey: ['activityLogs', triggerActivityLogFilter],
    })

    /* eslint-disable */
    useEffect(() => {
        setTriggerActivityLogFilter(!triggerActivityLogFilter)
    }, [])

    useEffect(() => {
        const logs = getActivityLogs?.data?.map((a) => {
            return { date: a.date, user: a.user_id?.name, event: a.message }
        })
        setActivityLogs(logs)
    }, [getActivityLogs])

    function handleShowFilter() {
        viewContext.modal.show({
            title: 'Filter',
            body: (
                <div className='pb-8'>
                    <Select
                        defaultValue={companyFilterLogs}
                        onChange={(val) => setCompanyFilterLogs(val)}
                        data={getCompanies?.data?.map((c) => {
                            return { value: c.id, label: c.companyName }
                        })}
                        searchable
                        label='Company:'
                        nothingFound='Nothing found'
                        placeholder='Select Company'
                        styles={{ root: { width: '100%' } }}
                        limit={3}
                        clearable
                    />
                    {permission === 'owner' && (
                        <>
                            <Select
                                data={users?.data?.users
                                    ?.filter((u) => u.permission === 'staff')
                                    .map((u) => {
                                        return { value: u._id, label: u?.name }
                                    })}
                                searchable
                                defaultValue={staffFilterLogs}
                                onChange={(val) => setStaffFilterLogs(val)}
                                label='Staff:'
                                placeholder='Select Staff'
                                styles={{ root: { width: '100%' } }}
                                limit={3}
                                clearable
                            />
                            <Select
                                data={users?.data?.users
                                    ?.filter((u) => u.permission === 'user')
                                    .map((u) => {
                                        return { value: u._id, label: u?.name }
                                    })}
                                searchable
                                defaultValue={clientFilterLogs}
                                onChange={(val) => setClientFilterLogs(val)}
                                label='Client:'
                                placeholder='Select Client'
                                styles={{ root: { width: '100%' } }}
                                limit={3}
                                clearable
                            />
                        </>
                    )}
                    {(permission === 'owner' || permission === 'user') && (
                        <Select
                            data={users?.data?.users
                                ?.filter((u) => u.permission === 'clientStaff')
                                .map((u) => {
                                    return { value: u._id, label: u?.name }
                                })}
                            searchable
                            defaultValue={clientStaffFilterLogs}
                            onChange={(val) => setClientStaffFilterLogs(val)}
                            label='Client Staff:'
                            placeholder='Select Client Staff'
                            styles={{ root: { width: '100%' } }}
                            limit={3}
                            clearable
                        />
                    )}

                    <div className='mt-2'>
                        <ClientButton icon='' label='Search' bg='bg-cyan-500' color='text-white' callback={() => setTriggerActivityLogFilter(!triggerActivityLogFilter)} />
                        <ClientButton icon='' label='Reset' bg='bg-red-500' color='text-white' callback={handleCloseFilter} />
                    </div>
                </div>
            ),
        })
    }

    function handleOnLoadActions() {
        viewContext.modal.hide()
    }

    function handleResetFields() {
        setCompanyFilterLogs('')
        setStaffFilterLogs('')
        setClientFilterLogs('')
        setClientStaffFilterLogs('')
    }

    function handleCloseFilter() {
        viewContext.modal.hide()
        handleResetFields()
        setTriggerActivityLogFilter(!triggerActivityLogFilter)
    }

    if (!users) {
        return false
    }

    return (
        <Fragment>
            <AccountNav />
            <Animate>
                <TitleRow title='Track User Activity'>
                    <Tooltip label='Filter' color='cyan' withArrow>
                        <button onClick={handleShowFilter} className='cursor-pointer lg:m-2'>
                            <Icon image='sliders' size={30} />
                        </button>
                    </Tooltip>
                </TitleRow>

                <Card>
                    {activityLogs?.length ? (
                        <Table
                            className='restrict-width'
                            data={activityLogs}
                            loading={isFetchActivityLogsLoading}
                            show={['date', 'user', 'event']}
                            // actions={{ edit: editUser, email: true, delete: deleteUser }}
                        />
                    ) : (
                        <div className='flex justify-center items-center' style={{ height: '50vh' }}>
                            <p>No users activity.</p>
                        </div>
                    )}
                </Card>
            </Animate>
        </Fragment>
    )
}
