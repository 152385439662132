import { Tooltip } from '@mantine/core'

import { ClientStyledData, Icon } from 'components/lib'

export const formatDate = (date) => {
    const newDate = new Date(date)
    const year = newDate.getFullYear()
    const month = newDate.getMonth()
    const _date = newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`
    const months = ['January', 'February', 'March', 'April', 'May', 'June', ' July', 'August', 'September', 'October', 'November', 'December']

    return `${months[month]} ${_date}, ${year}`
}

export const formatAmount = (amount) => {
    const strFirstChar = `${amount}`.charAt(0)

    if (strFirstChar === '-') {
        const formattedAmount = `${amount}`.substring(1).split('.')[0]
        return <ClientStyledData label={`-$${formattedAmount}`} bg='bg-red-100' c='text-red-500' />
    } else {
        const formattedAmount = `${amount}`.split('.')[0]
        return <ClientStyledData label={`$${formattedAmount}`} bg='bg-green-100' c='text-green-500' />
    }
}

export const formatTransactionStatus = (type, status) => {
    if (type === 'color') {
        if (status === 'No Action Needed') {
            return 'text-green-500'
        } else if (status === 'Needs Review') {
            return 'text-blue-500'
        }
    } else if (type === 'background') {
        if (status === 'No Action Needed') {
            return 'bg-green-100'
        } else if (status === 'Needs Review') {
            return 'bg-sky-100'
        }
    } else {
        throw new Error('Invalid parameter(s)')
    }
}

export const formatTransactionStatusIcon = (status) => {
    /* eslint-disable */
    const screenWidth = screen.width

    if (status === 'No Action Needed') {
        // return (
        //     <Tooltip label='No Action Needed' color='rgb(34 197 94)' withArrow>
        //         <span className='cursor-pointer  lg:m-2 text-green-500'>
        //             <Icon image='check' color='' size={20} />
        //         </span>
        //     </Tooltip>
        // )
        return false
    } else if (status === 'Needs Review') {
        return (
            <Tooltip arrowPosition='center' inline={true} offset={screenWidth >= 1024 ? -18 : 1} arrowSize={10} events={{ hover: true, touch: true }} label='Needs Review' color='rgb(59 130 246)' withArrow>
                <span className='cursor-pointer  lg:m-2 text-blue-500'>
                    <Icon image='alert-circle' color='' size={20} />
                </span>
            </Tooltip>
        )
    }
}
