/**
 * Documentation: https://mantine.dev/core/pagination/
 */
import { useContext } from 'react'
import { Pagination } from '@mantine/core'

import { ApiContext } from 'context/api'
import Style from './pagination.tailwind'

export const ClientPagination = (props) => {
    const { setPage, page, totalPerPage, setTotalPerPage } = useContext(ApiContext)
    const sizes = [20, 50, 100]

    function handleChangeSize(e) {
        setTotalPerPage(+e.target?.value)
    }

    function handleChangePage(selectedPage) {
        if (page === selectedPage) return
        setPage(selectedPage)
    }

    return (
        <div className={Style.container}>
            <div className={Style.showItemContainer}>
                <p className='text-xs text-gray-400'>Show</p>
                <div className='mx-3 py-2 px-2 text-xs text-gray-500 bg-white border rounded'>
                    <select value={totalPerPage} /* disabled={props?.total <= totalPerPage} */ className={Style.select} onChange={handleChangeSize}>
                        {sizes.map((size, index) => {
                            if (size < 50) {
                                return (
                                    <option key={index} value={size}>
                                        {size}
                                    </option>
                                )
                            } else {
                                return (
                                    <option key={index} disabled={totalPerPage >= props?.totalResults} value={size}>
                                        {size}
                                    </option>
                                )
                            }
                        })}
                    </select>
                </div>
                <p className='text-xs text-gray-400'>of {props?.totalResults}</p>
            </div>
            <div className={Style.pagination}>
                <Pagination
                    styles={(theme) => ({
                        item: {
                            '&[data-active]': {
                                backgroundColor: '#06B6D4',
                            },
                        },
                    })}
                    page={page}
                    onChange={handleChangePage}
                    total={Math.ceil(props?.totalResults / props?.totalPerPage)}
                />
            </div>
        </div>
    )
}
