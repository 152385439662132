import { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { NotificationsProvider } from '@mantine/notifications'
import * as Sentry from '@sentry/react'
import { message } from 'antd'

// Components
import { View } from 'components/lib'
import { PrivateRoute, AuthProvider } from './auth'
import { ApiProvider } from 'context/api'
import QueryClientProviderContext from 'utils/rq'
import Axios from 'utils/axios'

// 404
import { NotFound } from 'views/error/404'

// Tailwind CSS
import '../css/output.css'

// Settings
const Settings = require('settings.json')
const StripePromise = loadStripe(Settings[process.env.NODE_ENV].stripe.publishableAPIKey)

const routes = [...require('routes/account').default, ...require('routes/app').default, ...require('routes/auth').default, ...require('routes/categorizeme').default, ...require('routes/home').default]

// Sentry.io Configuration
Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    serverName: 'categorizeme-client',
    environment: process.env.NODE_ENV,
    enableTracing: true,
    dsn: Settings[process.env.NODE_ENV].sentry.dns,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ['localhost', /^https:\/\/api\.categorize\.me\/api\/v1/],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

export default function App(props) {
    const [messageApi, contextHolder] = message.useMessage()
    const isUserLoggedIn = localStorage.getItem('user')

    // Monitor user inactivity
    const checkForInActivity = () => {
        const sessionExpireTime = localStorage.getItem('sessionExpireTime')
        const isShowMessage = localStorage.getItem('isShowMessage')
        const sessionTimeOut = document.getElementById('sessionTimeOut')

        if (sessionExpireTime < Date.now()) {
            if (!isShowMessage) {
                showMessage()
                localStorage.setItem('isShowMessage', true)
                if (sessionTimeOut) sessionTimeOut.textContent = 60
            }
            if (sessionTimeOut) {
                if (+sessionTimeOut.textContent > 0) {
                    sessionTimeOut.textContent = +sessionTimeOut.textContent - 1
                    if (+sessionTimeOut.textContent === 0) {
                        Axios({ method: 'delete', url: '/api/v1/auth' })
                        localStorage.clear()
                        window.location = '/signin'
                    }
                }
            }
        }
    }

    const updateExpireTime = () => {
        messageApi.destroy()
        localStorage.removeItem('isShowMessage')
        const sessionExpireTime = Date.now() + 240000 // 4 minutes
        localStorage.setItem('sessionExpireTime', sessionExpireTime)
    }

    const showMessage = () => {
        messageApi.open({
            type: 'warning',
            content: (
                <span>
                    Your session is going to be end in <span id='sessionTimeOut'>60</span> seconds if there is no user activity detected.
                </span>
            ),
            duration: 60,
        })
    }

    /* eslint-disable */
    useEffect(() => {
        if (isUserLoggedIn) {
            const interval = setInterval(() => {
                checkForInActivity()
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [])

    /* eslint-disable */
    useEffect(() => {
        if (isUserLoggedIn) {
            updateExpireTime()
            window.addEventListener('click', updateExpireTime)
            window.addEventListener('keypress', updateExpireTime)
            window.addEventListener('scroll', updateExpireTime)
            window.addEventListener('mousemove', updateExpireTime)
            window.addEventListener('touchstart', updateExpireTime)
            window.addEventListener('touchmove', updateExpireTime)
            return () => {
                window.removeEventListener('click', updateExpireTime)
                window.removeEventListener('keypress', updateExpireTime)
                window.removeEventListener('scroll', updateExpireTime)
                window.removeEventListener('mousemove', updateExpireTime)
                window.removeEventListener('touchstart', updateExpireTime)
                window.removeEventListener('touchmove', updateExpireTime)
            }
        }
    }, [])

    return (
        <Elements stripe={StripePromise}>
            <QueryClientProviderContext>
                <AuthProvider>
                    <ApiProvider>
                        <NotificationsProvider>
                            <BrowserRouter>
                                <Routes>
                                    {routes.map((route) => {
                                        return (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                element={
                                                    route.permission ? (
                                                        <PrivateRoute permission={route.permission}>
                                                            {contextHolder}
                                                            <View display={route.view} layout={route.layout} title={route.title} />
                                                        </PrivateRoute>
                                                    ) : (
                                                        <>
                                                            {contextHolder}
                                                            <View display={route.view} layout={route.layout} title={route.title} />
                                                        </>
                                                    )
                                                }
                                            />
                                        )
                                    })}

                                    {/* 404 */}
                                    <Route path='*' element={<View display={NotFound} layout='landingpage' title='404 Not Found' />} />
                                </Routes>
                            </BrowserRouter>
                        </NotificationsProvider>
                    </ApiProvider>
                </AuthProvider>
            </QueryClientProviderContext>
        </Elements>
    )
}
