const Style = {
    // container: 'flex justify-around items-center',
    container: 'flex items-center gap-2',
    input: 'relative mb-4 last:mb-0',
    textbox: `relative block w-full p-3 rounded bg-white border bordeer-solid border-slate-100 
    focus:bg-slate-50 appearance-none disabled:opacity-50`,
    cardbox: '!p-4',
    success: 'border border-solid border-emerald-500',
    successIcon: 'absolute top-[2.8em] right-3 z-[100]',
    error: 'relative text-red-500 mb-0 border border-solid border-red-500 bg-red-200 placeholder:text-red-600',
    warning: 'bg-orange-50 border border-solid border-orange-500',
}

export default Style
