import { Signup } from 'views/auth/signup/account'
import { SignupPlan } from 'views/auth/signup/plan'
import { SignupUser } from 'views/auth/signup/user'
import { SignupClient } from 'views/auth/signup/client'
import { Signin } from 'views/auth/signin'
import { SigninOTP } from 'views/auth/signin/otp'
import { SocialSignin } from 'views/auth/signin/social'
import { ForgotPassword } from 'views/auth/signin/forgotpassword'
import { ResetPassword } from 'views/auth/signin/resetpassword'
import { MagicSignin } from 'views/auth/signin/magic'
import { ImpersonateSignin } from 'views/auth/signin/impersonate'

const Routes = [
    {
        path: '/signup',
        view: Signup,
        layout: 'landingpage',
        title: 'Sign up to Categorize Me',
    },
    {
        path: '/signup/plan',
        view: SignupPlan,
        layout: 'landingpage',
        permission: 'owner',
        title: 'Sign up to Categorize Me',
    },
    {
        path: '/signup/user',
        view: SignupUser,
        layout: 'landingpage',
        title: 'Sign up to Categorize Me',
    },
    {
        path: '/signup/client',
        view: SignupClient,
        layout: 'landingpage',
        title: 'Sign up to Categorize Me',
    },
    {
        path: '/signin',
        view: Signin,
        layout: 'landingpage',
        title: 'Welcome to Categorize Me',
    },
    {
        path: '/signin/otp',
        view: SigninOTP,
        layout: 'landingpage',
        title: 'Enter verification code',
    },
    {
        path: '/signin/social',
        view: SocialSignin,
        layout: 'landingpage',
        title: 'Completing Sign In',
    },
    {
        path: '/magic',
        view: MagicSignin,
        layout: 'landingpage',
        title: 'Sign In',
    },
    {
        path: '/forgotpassword',
        view: ForgotPassword,
        layout: 'landingpage',
        title: 'Forgot Your Password?',
    },
    {
        path: '/resetpassword',
        view: ResetPassword,
        layout: 'landingpage',
        title: 'Reset Your Password',
    },
    {
        path: '/signin/impersonate',
        view: ImpersonateSignin,
        layout: 'landingpage',
        title: 'Sign in via Mission Control',
    },
]

export default Routes
