import { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'

import { TPContainer } from 'components/lib'
//import backgroundImage from './images/background-features.jpg'
import screenshotWorkflow from './images/screenshots/workflow.svg'
import screenshotSyncData from './images/screenshots/sync-data.svg'
import screenshotMagicLink from './images/screenshots/magic-link.svg'
import screenshotSettings from './images/screenshots/settings.svg'

const features = [
    {
        title: 'Automated Workflow',
        description: 'Never miss an expense, income or asset again! Our system will automatically notify your clients via email if they have any uncategorized transactions, prompting them to quickly add notes and receipts online through our user-friendly platform.',
        image: screenshotWorkflow,
    },
    {
        title: 'Seamlessly Sync Data',
        description: 'Say goodbye to the hassle of manually transferring data between platforms! Categorize Me seamlessly syncs all client notes and receipts for uncategorized transactions to QuickBooks Online.',
        image: screenshotSyncData,
    },
    {
        title: 'Magic Link',
        description: 'Make it easy for your clients to provide transaction details and attachments with our seamless Magic Link feature. When they receive a notification, all they need to do is click the link and add descriptions and attachments from any device - computer, tablet, or phone.',
        image: screenshotMagicLink,
    },
    {
        title: 'Customize Settings',
        description: 'Select which accounts to sync and set up notifications for transactions at your preferred time. With Categorize Me, you can invite an unlimited number of client users and assign payment accounts.',
        image: screenshotSettings,
    },
]

export function TPPrimaryFeatures() {
    let [tabOrientation, setTabOrientation] = useState('horizontal')

    useEffect(() => {
        let lgMediaQuery = window.matchMedia('(min-width: 1024px)')

        function onMediaQueryChange({ matches }) {
            setTabOrientation(matches ? 'vertical' : 'horizontal')
        }

        if (lgMediaQuery?.matches) {
            onMediaQueryChange(lgMediaQuery)
            lgMediaQuery.addEventListener('change', onMediaQueryChange)

            return () => {
                lgMediaQuery.removeEventListener('change', onMediaQueryChange)
            }
        }
    }, [])

    return (
        <section id='features' aria-label='Features for running your books' className='relative overflow-hidden bg-cyan-600 pt-20 pb-28 sm:py-32'>
            <TPContainer className='relative'>
                <div className='max-w-2xl md:mx-auto md:text-center xl:max-w-none'>
                    <h2 className='font-["Lexend"] text-3xl tracking-tight text-white sm:text-4xl md:text-5xl'>Fix Uncategorized Transactions</h2>
                    <p className='mt-6 text-lg tracking-tight text-cyan-100'>Any updates made in Categorize Me are instantly synced to QuickBooks, ensuring accuracy and efficiency.</p>
                </div>
                <Tab.Group as='div' className='mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0' vertical={tabOrientation === 'vertical'}>
                    {({ selectedIndex }) => (
                        <>
                            <div className='-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5'>
                                <Tab.List className='relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal'>
                                    {features.map((feature, featureIndex) => (
                                        <div
                                            key={feature.title}
                                            className={clsx('group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6', selectedIndex === featureIndex ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10' : 'hover:bg-white/10 lg:hover:bg-white/5')}
                                        >
                                            <h3>
                                                <Tab className={clsx('font-["Lexend"] text-lg [&:not(:focus-visible)]:focus:outline-none', selectedIndex === featureIndex ? 'text-cyan-600 lg:text-white' : 'text-cyan-100 hover:text-white lg:text-white')}>
                                                    <span className='absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl' />
                                                    {feature.title}
                                                </Tab>
                                            </h3>
                                            <p className={clsx('mt-2 hidden text-sm lg:block', selectedIndex === featureIndex ? 'text-white' : 'text-cyan-100 group-hover:text-white')}>{feature.description}</p>
                                        </div>
                                    ))}
                                </Tab.List>
                            </div>
                            <Tab.Panels className='lg:col-span-7'>
                                {features.map((feature) => (
                                    <Tab.Panel key={feature.title} unmount={false}>
                                        <div className='relative sm:px-6 lg:hidden'>
                                            <div className='absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl' />
                                            <p className='relative mx-auto max-w-2xl text-base text-white sm:text-center'>{feature.description}</p>
                                        </div>
                                        <div className='mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]'>
                                            <img className='w-full' src={feature.image} alt='' priority='true' sizes='(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem' />
                                        </div>
                                    </Tab.Panel>
                                ))}
                            </Tab.Panels>
                        </>
                    )}
                </Tab.Group>
            </TPContainer>
        </section>
    )
}
