const Style = {
    container: 'bg-white mb-5 lg:mb-0',
    components: 'w-full flex flex-col justify-center items-center gap-2 md:flex-row md:justify-between md:items-center',
    header2: 'mb-1 text-2xl font-bold',
    subtextContainer: 'flex justify-center items-center text-sm text-gray-500 font-medium',
    subtextCount: 'font-bold text-cyan-500',
    secondComponentItem: 'flex flex-col justify-center items-center gap-2 md:flex-row md:justify-between md:items-center',
}

export default Style
