import { Tooltip } from '@mantine/core'

import Style from './styled-data.tailwind'

export const ClientStyledData = (props) => {
    function formatter(number) {
        const splitNumber = String(number).split('.')
        if (!splitNumber[1]) {
            return `$${number.toLocaleString()}`
        }
        if (splitNumber[1]?.length === 1) {
            const decimal = number.toFixed(2).split('.')[1]
            const wholeNumber = number.toLocaleString().split('.')[0]
            return `$${[wholeNumber, decimal].join('.')}`
        } else {
            return `$${number.toLocaleString()}`
        }
    }

    if (isNaN(props.children)) {
        return <span className={`${Style.btn} ${props.bg} ${props.c}`}>{props.children}</span>
    }

    return (
        <Tooltip label={props.t} color='cyan' withArrow>
            <span className={`${Style.btn} ${props.bg} ${props.c}`}>{formatter(props.children)}</span>
        </Tooltip>
    )
}
