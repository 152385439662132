/***
 *
 *   HELP
 *   Information for user to get help and support
 *
 **********/

import React, { Fragment } from 'react'
import { Card, Form, Message, Loader, useAPI } from 'components/lib'
import { UpdateNotification } from 'utils/notifications'

export function Help(props) {
    const user = useAPI('/api/v1/user')

    return (
        <Fragment>
            <Message title='Need Help?' type='info' text='Contact our support team below. You can also enable or disable permission for our support team to access your account to help resolve any issues.' />

            {user.loading ? (
                <Loader />
            ) : (
                <Card title='Contact Support' restrictWidth>
                    <Form
                        data={{
                            support_enabled: {
                                type: 'radio',
                                options: ['Yes', 'No'],
                                required: true,
                                errorMessage: 'Please enter a message',
                                default: user.data?.support_enabled ? 'Yes' : 'No',
                                label: 'Can our support team access your account?',
                            },
                        }}
                        submitOnChange
                        method='PATCH'
                        url='/api/v1/user'
                    />
                    <Form
                        data={{
                            email: {
                                label: 'Email',
                                // type: 'hidden',
                                type: 'email',
                                value: user?.data?.email,
                                required: true,
                            },
                            name: {
                                label: 'Name',
                                // type: 'hidden',
                                type: 'text',
                                value: user?.data?.name,
                                required: true,
                            },
                            template: {
                                type: 'hidden',
                                value: 'help',
                            },
                            message: {
                                type: 'textarea',
                                label: 'What can we help with?',
                                errorMessage: 'Please enter a message',
                                required: true,
                            },
                        }}
                        method='POST'
                        url='/api/v1/utility/ses'
                        buttonText='Send Message'
                        callback={() => UpdateNotification({ title: 'Contact Support', message: 'Your message was successfully sent to our support team.' })}
                    />
                </Card>
            )}
        </Fragment>
    )
}
