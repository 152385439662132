/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import { Fragment, useContext } from 'react'
import { AuthContext, AppNav, Header, User } from 'components/lib'
import Style from './app.module.scss'

export function AppLayout(props) {
    // context & style
    const context = useContext(AuthContext)

    let items = []

    if (context.permission.owner) {
        items = [
            {
                label: 'Company',
                icon: 'users',
                link: '/company',
            },
            { label: 'Account', icon: 'user', link: '/account' },
            { label: 'Help', icon: 'help-circle', link: '/help' },
            { label: 'Sign Out', icon: 'log-out', action: context.signout },
        ]
    } else {
        items = [
            {
                label: 'Company',
                icon: 'users',
                link: '/company',
            },
            { label: 'Account', icon: 'user', link: '/account' },
            { label: 'Sign Out', icon: 'log-out', action: context.signout },
        ]
    }

    return (
        <Fragment>
            <AppNav items={items} />

            <main className={Style.app}>
                <Header title={props.title}>
                    <User />
                </Header>

                {<props.children {...props.data} />}
            </main>
        </Fragment>
    )
}
