import { Tooltip } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'

import { Icon } from 'components/lib'
import Style from './client-action-buttons.tailwind'

export const ClientActionButtons = (props) => {
    const { message, attachment, edit, deleteTransaction, saveApprove } = props?.callbacks
    return (
        <div className={Style.container}>
            {props?.permission === 'owner' || props?.permission === 'staff' ? (
                <Tooltip label='Email Client' color='cyan' withArrow>
                    <button onClick={() => message(props?.transaction)} className='cursor-pointer  lg:m-2'>
                        <Icon image='mail' color='#000' size={20} />
                    </button>
                </Tooltip>
            ) : (
                <Tooltip label='Email Accountant' color='cyan' withArrow>
                    <button onClick={() => message(props?.transaction)} className='cursor-pointer  lg:m-2'>
                        <Icon image='mail' color='#000' size={20} />
                    </button>
                </Tooltip>
            )}

            <Tooltip label='Attach document' color='cyan' withArrow>
                <button onClick={() => attachment(props?.transaction)} className='cursor-pointer lg:m-2'>
                    <Icon image='paperclip' color={props?.transaction?.attachments.length ? 'rgb(34 197 94)' : '#000'} size={20} />
                </button>
            </Tooltip>

            {props?.fetchTransactionType !== 'approved' && (
                <Tooltip label='Edit transaction' color='cyan' withArrow>
                    <button onClick={() => edit(props?.transaction)} className='cursor-pointer lg:m-2'>
                        <Icon image='edit' color='#000' size={20} />
                    </button>
                </Tooltip>
            )}

            {props?.fetchTransactionType === 'categorizeMe' && (
                <Tooltip label={(props?.permission === 'owner' || props?.permission === 'staff') && props?.fetchTransactionType === 'categorizeMe' ? 'Save' : 'Send to Review'} color='cyan' withArrow>
                    <button disabled={props?.isLoading || props?.isToggleApproveAllActive} onClick={() => saveApprove(props?.transaction)} className='cursor-pointer lg:m-2'>
                        <Icon image={props?.permission === 'owner' || props?.permission === 'staff' ? 'save' : 'send'} color={props?.permission === 'owner' || props?.permission === 'staff' ? '#06B6D4' : 'rgb(22 163 74)'} size={20} />
                    </button>
                </Tooltip>
            )}

            {props?.fetchTransactionType === 'underReview' && (
                <Tooltip label={props?.permission === 'owner' || props?.permission === 'staff' ? 'Approve' : 'Save'} color='cyan' withArrow>
                    <button disabled={props?.isLoading || props?.isToggleApproveAllActive} onClick={() => saveApprove(props?.transaction)} className='cursor-pointer lg:m-2'>
                        <Icon image={(props?.permission === 'owner' || props?.permission === 'staff') && props?.fetchTransactionType === 'underReview' ? 'check-circle' : 'save'} color='rgb(22 163 74)' size={20} />
                    </button>
                </Tooltip>
            )}

            {props?.fetchTransactionType === 'approved' && (
                <Tooltip label='Delete transaction' color='cyan' withArrow>
                    <button onClick={() => deleteTransaction(props?.transaction)} className='cursor-pointer lg:m-2'>
                        <IconTrash color='#000' />
                    </button>
                </Tooltip>
            )}
        </div>
    )
}
