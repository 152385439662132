import axios from 'axios'
const Settings = require('settings.json')

const user = JSON.parse(localStorage.getItem('user'))
// let token = null

const Axios = axios.create({
    baseURL: Settings[process.env.NODE_ENV].server_url,
    withCredentials: true,
    headers: {
        'X-Sentry-Token': Settings[process.env.NODE_ENV].sentry.header_token,
    },
})

if (user?.token) {
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
}

Axios.interceptors.request.use(
    async function (config) {
        // if (!token) {
        //     const response = await fetch(`${Settings[process.env.NODE_ENV].server_url}/otp`)
        //     token = response.headers.get('x-token')
        // }
        // config.headers['x-token'] = token
        return config
    },
    function (error) {
        // token = null
        return Promise.reject(error)
    }
)

Axios.interceptors.response.use(
    function (response) {
        if (response.data.errors) {
            let customError = new Error(response.data.errors[0].message)
            response.original_status = response.status
            response.status = response.data.errors[0].extensions.code
            customError.response = response
            customError.request = response.request
            customError.config = response.config
            customError.isAxiosError = true // or not
            // token = null
            return Promise.reject(customError)
        }
        // token = null
        return response
    },
    async function (error) {
        if (error.response?.status === 401 && (error.response?.statusText === 'Unauthorized' || error.response?.data?.message === 'jwt expired')) {
            await Axios({ method: 'delete', url: '/api/v1/auth' })
            localStorage.clear()
            window.location = '/signin'
        } else if (error.response?.status === 401) {
            localStorage.clear()
            window.location = '/signin'
        }
        // token = null
        return Promise.reject(error)
    }
)

export default Axios
