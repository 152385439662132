/**
 * Magic link generator.
 * 1. Able to copy the link from clip board.
 */
import { useEffect, useState } from 'react'

import { useAPI, TextInput, Icon } from 'components/lib'

export const MagicLinkGenerator = (props) => {
    const [shortlink, setShortlink] = useState()
    const [isLinkCopied, setIsLinkCopied] = useState(false)

    // Temporarily set the email to help@categorize.me
    const generatedLink = useAPI('/api/v1/auth/magic/generate-short-link', 'post', { email: props?.email })

    useEffect(() => {
        if (generatedLink?.data) {
            setShortlink(generatedLink?.data)
        }
    }, [generatedLink])

    if (!shortlink) {
        return <h1 className='text-center'>Generating Magic Link...</h1>
    }

    const handleClick = () => {
        const text = document.getElementById('short-link-input')
        text.select()
        document.execCommand('copy')
        setIsLinkCopied(true)
    }

    return (
        <>
            {isLinkCopied && <span className='text-green-500 p-1'>Link Copied!</span>}
            <div className='flex gap-2 items-center'>
                <span className='w-full'>
                    <TextInput id='short-link-input' value={shortlink} />
                </span>
                <span onClick={handleClick} className='cursor-pointer'>
                    <Icon image='copy' size={40} />
                </span>
            </div>
        </>
    )
}
