import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const MAX_AGE = 0

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            // retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
            // Time in ms that the data is refresh or refetch for any api cal or interaction. If it is set to "Infinity", it will only serve the cached data.
            // staleTime: Infinity,
            cacheTime: MAX_AGE,
            // retry: 3
            retry: 0,
            keepPreviousData: true,
        },
    },
})

const QueryClientProviderContext = ({ children }) => {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default QueryClientProviderContext
