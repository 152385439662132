import { useContext, useState, useEffect } from 'react'
import { IconNotebook, IconBuildingBank, IconFileText } from '@tabler/icons-react'
import { Tooltip } from '@mantine/core'

import { ApiContext } from 'context/api'
import Style from './text-area.tailwind'

export const ClientTextArea = (props) => {
    const [value, setValue] = useState()
    const { view } = useContext(ApiContext)

    const handleOnBlur = (e, props) => {
        if (props?.permission === 'owner' || props?.permission === 'staff') {
            if (!e.target.value) e.target.value = props?.payload || props?.description
        }

        const payload = {
            value: e.target.value,
            ...props,
        }

        props?.callback(payload)
    }

    /* eslint-disable */
    useEffect(() => {
        if ((props?.permission === 'owner' || props?.permission === 'staff') && props.fetchTransactionType === 'categorizeMe') {
            setValue(props?.payload || props?.description)
        } else if ((props?.permission === 'owner' || props?.permission === 'staff') && (props.fetchTransactionType === 'underReview' || props.fetchTransactionType === 'approved')) {
            setValue(props?.description)
        }
        if ((props?.permission === 'user' || props?.permission === 'clientStaff') && props?.fetchTransactionType === 'categorizeMe') {
            setValue(props?.payload)
        } else if ((props?.permission === 'user' || props?.permission === 'clientStaff') && (props.fetchTransactionType === 'underReview' || props.fetchTransactionType === 'approved')) {
            setValue(props?.description)
        }
    }, [props?.fetchTransactionType])

    return (
        <div className={Style.container}>
            <textarea
                className='block w-full px-4 py-3 mb-2 text-sm bg-white border outline-none rounded'
                name='field-name'
                rows='2'
                placeholder='Write a memo...'
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onFocus={(e) => props.fetchTransactionType !== 'approved' && (props?.permission === 'owner' || props?.permission === 'staff') && (e.target.value = '')}
                onBlur={(e) => props.fetchTransactionType !== 'approved' && handleOnBlur(e, props)}
                readOnly={(props?.fetchTransactionType === 'underReview' || props?.fetchTransactionType === 'approved') && (props?.permission === 'user' || props?.permission === 'clientStaff')}
                disabled={props?.fetchTransactionType === 'approved' || (props?.fetchTransactionType === 'underReview' && (props?.permission === 'user' || props?.permission === 'clientStaff'))}
            />
            {props?.isTransactionView && (
                <span style={{ fontWeight: 'unset', fontSize: '10px', lineHeight: '12px', marginBottom: '4px', display: 'block' }}>
                    <div className={!view ? 'flex flex-wrap gap-2 items-center max-w-xs' : 'flex flex-col gap-0.5 justify-center'}>
                        <span className='flex items-center'>
                            {props?.account && (
                                <Tooltip label='Payment Account' color='cyan' withArrow>
                                    <IconBuildingBank width={14} />
                                </Tooltip>
                            )}
                            {props?.account}
                        </span>
                        {props?.memo === props?.description ? (
                            <span className='flex items-center'>
                                <span className='shrink-0'>
                                    {props?.qbo_description && (
                                        <Tooltip label='Description' color='cyan' withArrow>
                                            <IconFileText width={14} />
                                        </Tooltip>
                                    )}
                                </span>
                                {/* <span style={{ wordBreak: 'break-word' }}>{props?.qbo_description}</span> */}
                                <span>{props?.qbo_description}</span>
                            </span>
                        ) : (
                            <>
                                <span className='flex items-center'>
                                    <span className='shrink-0'>
                                        {props?.memo && (
                                            <Tooltip label='Memo' color='cyan' withArrow>
                                                <IconNotebook width={14} />
                                            </Tooltip>
                                        )}
                                    </span>
                                    {/* <span style={{ wordBreak: 'break-word' }}>{props?.memo}</span> */}
                                    <span>{props?.memo}</span>
                                </span>
                                <span className='flex items-center'>
                                    <span className='shrink-0'>
                                        {props?.qbo_description && (
                                            <Tooltip label='Description' color='cyan' withArrow>
                                                <IconFileText width={14} />
                                            </Tooltip>
                                        )}
                                    </span>
                                    {/* <span style={{ wordBreak: 'break-word' }}>{props?.qbo_description}</span> */}
                                    <span>{props?.qbo_description}</span>
                                </span>
                            </>
                        )}
                    </div>
                </span>
            )}
        </div>
    )
}
