import { createRoot } from 'react-dom/client'
import { Fragment, useContext, useState, useEffect, useRef } from 'react'
import { Tooltip, Select, Skeleton, LoadingOverlay, Modal, TextInput } from '@mantine/core'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { IconInfoCircle, IconPlus, IconTrash, IconArrowsSplit2 } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import { AnimatePresence } from 'framer-motion'
import { Divider, message, TreeSelect } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import { Checkbox, Icon, Card, Animate, Row, ClientStyledData, ClientButton, ClientPagination, Table, ClientOption, ClientTextArea, ClientActionButtons, ViewContext, AuthContext, Drawer, Tree, CurrencyInput } from 'components/lib'
import { formatDate, formatTransactionStatus, formatTransactionStatusIcon } from 'utils/formatter'
import { ApiContext } from 'context/api'
import { ErrorNotification, UpdateNotification } from 'utils/notifications'
import { Classification, AccountType, AccountSubType, bulkChangeOptions } from 'utils/data'

export function TransactionDashboard() {
    const authContext = useContext(AuthContext)
    const viewContext = useContext(ViewContext)
    const {
        handleFetchTransactionsRequest,
        setButtonLoading,
        view,
        setView,
        isToggleViewActive,
        isToggleApproveAllActive,
        isToggleBulkChangeActive,
        handleFetchCompanyProfileRequest,
        companyProfile,
        transactions,
        handleFetchListRequest,
        buttonLoading,
        page,
        setPage,
        lists,
        handleFetchCompaniesCountRequest,
        searchValue,
        setIsToggleBulkChangeActive,
        setIsToggleViewActive,
        handleUploadApprovedAttachmentsRequest,
        handleUploadFilesAttachmentRequest,
        handleDeleteFilesAttachmentRequest,
        handleApproveChangesRequest,
        handleUpdateIntuitTransactionRequest,
        handleUpdateTransactionRequest,
        handleUpdateIntuitTransactionsRequest,
        handleDeleteApprovedTransactionsRequest,
        handleAccountingImmediateNotificationRequest,
        handleCheckClassLocationStatusRequest,
        handleFetchSplitTransactionsRequest,
        splitTransactions,
        setSplitTransactions,
        handleSplitTransactionsRequest,
        dateTo,
        dateFrom,
        totalPerPage,
        handleResetFilters,
        handleAdminCreateListRequest,
        handleAddEntityRequest,
        selectedListItemForBulkChange,
        setSelectedListItemForBulkChange,
        isDateFilterActive,
        refresh,
        fetchTransactionType,
        setFetchTransactionType,
        selectedBulkOption,
        setSelectedBulkOption,
        setTransactions,
        handleUpdateSplitTransactionsRequest,
        handleApproveSplitTransactionsRequest,
        handleDeleteApprovedTransactionRequest,
    } = useContext(ApiContext)
    const buttonCallbacks = {
        message: handleMessage,
        attachment: handleAttachment,
        edit: handleEdit,
        deleteTransaction: handleDeleteTransaction,
        saveApprove: handleSaveApprove,
    }
    const permission = authContext?.user.permission
    const pathname = window.location.pathname
    const companyId = pathname.split('/')[2]
    // Get the first client admin from the clients array
    const clientAdmin = companyProfile?.clients?.find((client) => client?.permission === 'user')
    const queryClient = useQueryClient()
    const [checkboxes, setCheckboxes] = useState([])
    const [multipleUpdatePayload, setMultipleUpdatePayload] = useState([])
    const singleUpdatePayload = JSON.parse(localStorage.getItem('singleUpdatePayload')) || []
    const [isAutosaveLoading, setIsAutoSaveLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage()
    const [splitItemsPayload, setSplitItemsPayload] = useState([])
    const [splitTransaction, setSplitTransaction] = useState([])
    const [splitBy, setSplitBy] = useState('')

    useQuery({
        queryFn: () => handleFetchCompaniesCountRequest(),
        queryKey: ['companyCount'],
    })

    const { data: getLists } = useQuery({
        queryFn: () => handleFetchListRequest(companyId),
        queryKey: ['lists'],
    })

    const {
        data: getTransactions,
        isLoading,
        isFetching,
    } = useQuery({
        queryFn: () => handleFetchTransactionsRequest(companyId, handleOnLoadActions),
        queryKey: ['transactions', page, totalPerPage, searchValue, dateTo, dateFrom, isDateFilterActive, refresh, fetchTransactionType],
    })

    const { data: getCompanyProfile } = useQuery({
        queryFn: () => handleFetchCompanyProfileRequest(companyId),
        queryKey: ['companyProfile', page, searchValue, fetchTransactionType],
    })

    const { data: getClassLocationStatus } = useQuery({
        queryFn: () => handleCheckClassLocationStatusRequest(companyId),
        queryKey: ['checkClassLocationStatusFromTransactions'],
    })

    const { mutateAsync: uploadFilesAttachment } = useMutation({
        mutationFn: handleUploadFilesAttachmentRequest,
        onSuccess: () => {
            queryClient.invalidateQueries(['transactions'])
            queryClient.invalidateQueries(['companyProfile'])
        },
    })

    const { mutateAsync: uploadApprovedAttachments } = useMutation({
        mutationFn: handleUploadApprovedAttachmentsRequest,
        onSuccess: () => {
            queryClient.invalidateQueries(['transactions'])
            queryClient.invalidateQueries(['companyProfile'])
        },
    })

    const { mutateAsync: deleteFilesAttachment } = useMutation({
        mutationFn: handleDeleteFilesAttachmentRequest,
        onSuccess: () => {
            queryClient.invalidateQueries(['transactions'])
            queryClient.invalidateQueries(['companyProfile'])
        },
    })

    const { mutateAsync: approveChanges } = useMutation({
        mutationFn: handleApproveChangesRequest,
        onSuccess: () => {},
    })

    const { mutateAsync: updateTransaction } = useMutation({
        mutationFn: handleUpdateTransactionRequest,
        onSuccess: () => {
            // queryClient.invalidateQueries(['transactions'])
            queryClient.invalidateQueries(['companyProfile'])
            queryClient.invalidateQueries(['lists'])
        },
    })

    const { mutateAsync: updateIntuitTransaction, isLoading: isUpdateIntuitTransactionLoading } = useMutation({
        mutationFn: handleUpdateIntuitTransactionRequest,
        onSuccess: () => {
            // queryClient.invalidateQueries(['transactions'])
            queryClient.invalidateQueries(['companyProfile'])
            queryClient.invalidateQueries(['lists'])
        },
    })

    const { mutateAsync: updateIntuitTransactions } = useMutation({
        mutationFn: handleUpdateIntuitTransactionsRequest,
        onSuccess: () => {
            queryClient.invalidateQueries(['transactions'])
            queryClient.invalidateQueries(['companyProfile'])
            queryClient.invalidateQueries(['lists'])
        },
    })

    const { mutateAsync: deleteApprovedTransactions } = useMutation({
        mutationFn: handleDeleteApprovedTransactionsRequest,
        onSuccess: () => {
            queryClient.invalidateQueries(['transactions'])
        },
    })

    const { mutateAsync: immediateNotification } = useMutation({
        mutationFn: handleAccountingImmediateNotificationRequest,
        onSuccess: () => {},
    })

    const { mutateAsync: adminCreateList, isLoading: isAdminCreateListLoading } = useMutation({
        mutationFn: handleAdminCreateListRequest,
        onSuccess: () => {},
    })

    const { mutateAsync: adminAddEntity, isLoading: isAdminAddEntityLoading } = useMutation({
        mutationFn: handleAddEntityRequest,
        onSuccess: () => {
            queryClient.invalidateQueries(['companyProfile'])
            queryClient.invalidateQueries(['lists'])
        },
    })

    function handleOnLoadActions() {
        viewContext.setLoading(false)
        setButtonLoading(false)
    }

    function handleCheckAll(e, transactions) {
        const checkboxElements = document.querySelectorAll('input[type=checkbox]')
        const isChecked = e.target.checked

        if (isChecked) {
            let checkedCheckboxes = []
            for (let i = 0; i < checkboxElements.length; i++) {
                if (checkboxElements[i]?.id !== '-1' && !checkboxes.includes(checkboxElements[i]?.id)) checkedCheckboxes.push(checkboxElements[i]?.id)
                checkboxElements[i].checked = true
            }
            setCheckboxes(checkedCheckboxes)
            const existingTransIds = multipleUpdatePayload.map((transaction) => transaction._id)
            if (permission === 'owner' || permission === 'staff') {
                if (selectedBulkOption === '1') {
                    const newTransactions = transactions?.filter((transaction) => existingTransIds.indexOf(transaction._id) === -1 && transaction?.status === 'Needs Review')
                    const newMultipleUpdatePayload = newTransactions.map((data) => ({
                        _id: data?._id,
                        transId: data?.transId,
                        transType: data?.transType,
                        lineId: data?.lineId,
                        clientId: clientAdmin?.id,
                        vendor: renderDataObject('vendor', data?.vendor.id, [...lists?.vendors], data?.vendor.type),
                        category: renderDataObject('category', data?.category.id, lists?.categories),
                        class: renderDataObject('class', data?.class.id, lists?.classes),
                        location: renderDataObject('location', data?.location.id, lists?.locations),
                        description: data?.description,
                    }))
                    setMultipleUpdatePayload(newMultipleUpdatePayload)
                } else {
                    const newTransactions = transactions?.filter((transaction) => existingTransIds.indexOf(transaction._id) === -1)
                    const newMultipleUpdatePayload = newTransactions.map((data) => ({
                        _id: data?._id,
                        transId: data?.transId,
                        transType: data?.transType,
                        lineId: data?.lineId,
                        clientId: clientAdmin?.id,
                    }))
                    setMultipleUpdatePayload(newMultipleUpdatePayload)
                }
            } else if (permission === 'user' || permission === 'clientStaff') {
                const newTransactions = transactions?.filter((transaction) => existingTransIds.indexOf(transaction._id) === -1)
                const newMultipleUpdatePayload = newTransactions.map((data) => ({
                    _id: data?._id,
                    transId: data?.transId,
                    transType: data?.transType,
                    lineId: data?.lineId,
                    clientId: authContext?.user?.accounts[0]?.user_id,
                    vendor: renderDataObject('vendor', data?.vendor.id, [...lists?.vendors], data?.vendor.type),
                    category: renderDataObject('category', data?.category.id, lists?.categories),
                    class: renderDataObject('class', data?.class.id, lists?.classes),
                    location: renderDataObject('location', data?.location.id, lists?.locations),
                    description: data?.description,
                }))
                setMultipleUpdatePayload(newMultipleUpdatePayload)
            }
            handleShowHideToggles(isChecked)
        } else {
            for (let i = 0; i < checkboxElements.length; i++) {
                checkboxElements[i].checked = false
            }
            setCheckboxes([])
            setMultipleUpdatePayload([])
            handleShowHideToggles(isChecked)
        }
    }

    function handleCheckActive(e, transaction) {
        const isChecked = e.target.checked
        if (isChecked && checkboxes.indexOf(transaction?._id) === -1) {
            setCheckboxes([...checkboxes, transaction?._id])
            if (transaction?.status === 'Needs Review') {
                const data = {
                    _id: transaction?._id,
                    transId: transaction?.transId,
                    transType: transaction?.transType,
                    lineId: transaction?.lineId,
                    companyId,
                    vendor: renderDataObject('vendor', transaction?.vendor.id, [...lists?.vendors], transaction?.vendor.type),
                    category: renderDataObject('category', transaction?.category.id, lists?.categories),
                    class: renderDataObject('class', transaction?.class.id, lists?.classes),
                    location: renderDataObject('location', transaction?.location.id, lists?.locations),
                    description: transaction?.description,
                }
                setMultipleUpdatePayload([...multipleUpdatePayload, data])
            } else {
                const data = {
                    _id: transaction?._id,
                    transId: transaction?.transId,
                    transType: transaction?.transType,
                    lineId: transaction?.lineId,
                    companyId,
                    categoryId: null,
                    category: null,
                }
                setMultipleUpdatePayload([...multipleUpdatePayload, data])
            }
        } else {
            const transIndex = checkboxes.indexOf(transaction?._id)
            transIndex > -1 && checkboxes.splice(transIndex, 1)
            const filteredMultipleUpdatePayload = multipleUpdatePayload?.filter((payload) => payload?._id !== transaction?._id)
            setMultipleUpdatePayload(filteredMultipleUpdatePayload)
        }
        const checkboxElements = document.querySelectorAll('input[type=checkbox]')
        const activeCheckboxes = []
        for (let i = 0; i < checkboxElements.length; i++) {
            if (checkboxElements[i]?.checked) {
                activeCheckboxes.push(checkboxElements[i]?.id)
            }
        }
        handleShowHideToggles(Boolean(activeCheckboxes.length))
    }

    function handleShowHideToggles(isChecked) {
        setIsToggleBulkChangeActive(isChecked)
        setSelectedBulkOption('')
        setSelectedListItemForBulkChange(undefined)
        setIsToggleViewActive(!isChecked)
    }

    // Todo: Make this function dynamic and create new component
    function handleMessage(transaction) {
        if (!companyProfile?.isActive) {
            return ErrorNotification({
                title: 'Notification',
                message: 'Client has not yet activated their account',
                icon: <IconInfoCircle />,
            })
        }

        const recipient = permission === 'owner' || permission === 'staff' ? clientAdmin?.email : permission === 'user' || permission === 'clientStaff' ? authContext?.user?.accounts[0].id : null
        viewContext.modal.show(
            {
                title: 'Transaction Details Needed',
                body: (
                    <div className='flex flex-col justify-center pb-4'>
                        <div>
                            <b>
                                <span>Date:</span>
                            </b>
                            &nbsp;&nbsp;
                            <span>{new Date(transaction?.date).toISOString().split('T')[0]}</span>
                        </div>
                        <div>
                            <b>
                                <span>Amount:</span>
                            </b>
                            &nbsp;&nbsp;
                            <span>{`$${transaction?.amount.value}`}</span>
                        </div>
                        <div>
                            <b>
                                <span>Description:</span>
                            </b>
                            &nbsp;&nbsp;
                            <span>{transaction?.description || 'N/A'}</span>
                        </div>
                        <div>
                            <b>
                                <span>Memo:</span>
                            </b>
                            &nbsp;&nbsp;
                            <span>{transaction?.memo || 'N/A'}</span>
                        </div>
                    </div>
                ),
                form: {
                    from: {
                        type: 'hidden',
                        value: authContext?.user.email,
                    },
                    to: {
                        type: 'hidden',
                        value: recipient,
                    },
                    date: {
                        type: 'hidden',
                        value: transaction?.date,
                    },
                    amount: {
                        type: 'hidden',
                        value: transaction?.amount.value,
                    },
                    description: {
                        type: 'hidden',
                        value: transaction?.description,
                    },
                    memo: {
                        type: 'hidden',
                        value: transaction?.memo,
                    },
                    message: {
                        label: 'Message (Optional):',
                        type: 'textarea',
                        required: false,
                        value: '',
                        placeholder: 'Write message...',
                        errorMessage: 'Please enter your message.',
                    },
                },
                buttonText: 'Send',
                url: '/api/v1/client/notification',
                method: 'POST',
            },
            (res) => {
                UpdateNotification({
                    title: 'Notification',
                    message: 'Successfully sent.',
                })
            }
        )
    }

    // Todo: Make this function dynamic
    function handleAttachment(transaction) {
        const attachments = transaction?.attachments

        if (permission === 'owner' || permission === 'staff') {
            viewContext.modal.show({
                title: 'Attach Document(s):',
                text: (
                    <>
                        <span>
                            Notes:<span className='text-red-500'>*</span>
                        </span>
                        <br />
                        <br />
                        <span className='!mb-0'>1. Drop files for multiple file uploads.</span>
                        <br />
                        <span className='!mb-0'>2. Maximum of 5 files to upload.</span>
                        <br />
                        <span className='!mb-0'>3. Allowed file types .pdf, .jpg, .png, .docx.</span>
                    </>
                ),
                body: (
                    <>
                        <div className='pb-4 pr-4'>
                            <input disabled={fetchTransactionType === 'approved'} type='file' id='files' multiple />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <ClientButton disabled={fetchTransactionType === 'approved'} width='!w-full' bg='bg-cyan-500' icon='upload' color='text-white' label={viewContext.loading ? `Uploading file(s)...` : 'Upload'} callback={() => handleUploadFiles(transaction)} />
                            <section id='uploaded-area'></section>
                        </div>
                    </>
                ),
            })

            setTimeout(() => {
                if (attachments.length) {
                    for (let i = 0; i < attachments.length; i++) {
                        const payload = {
                            isThereAttachments: true,
                            url: attachments[i].url,
                            displayname: attachments[i].displayname,
                            filename: attachments[i].filename,
                            filesize: attachments[i].filesize,
                            id: attachments[i].id,
                            transId: transaction.transId,
                            lineId: transaction.lineId,
                            status: attachments[i].status,
                        }
                        handleUploadedHtmlForAdmin(payload)
                        document.getElementById(`${attachments[i].id}`).addEventListener('click', handleDeleteAttachment)
                    }
                } else {
                    handleUploadedHtmlForAdmin({ isThereAttachments: false })
                }
            }, 100)
        } else if (permission === 'user' || permission === 'clientStaff') {
            viewContext.modal.show({
                title: 'Attach Document(s):',
                text: (
                    <>
                        <span>
                            Notes:<span className='text-red-500'>*</span>
                        </span>
                        <br />
                        <br />
                        <span className='!mb-0'>1. Drop files for multiple file uploads.</span>
                        <br />
                        <span className='!mb-0'>2. Maximum of 5 files to upload.</span>
                        <br />
                        <span className='!mb-0'>3. Allowed file types .pdf, .jpg, .png, .docx.</span>
                    </>
                ),
                body: (
                    <>
                        <div className='pb-4 pr-4'>
                            <input type='file' id='files' multiple />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <ClientButton width='!w-full' bg='bg-cyan-500' icon='upload' color='text-white' label={viewContext.loading ? `Uploading file(s)...` : 'Upload'} callback={() => handleUploadFiles(transaction)} />
                            <section id='uploaded-area'></section>
                        </div>
                    </>
                ),
            })

            setTimeout(() => {
                if (attachments.length) {
                    for (let i = 0; i < attachments.length; i++) {
                        const payload = {
                            isThereAttachments: true,
                            url: attachments[i].url,
                            displayname: attachments[i].displayname,
                            filename: attachments[i].filename,
                            filesize: attachments[i].filesize,
                            id: attachments[i].id,
                            transId: transaction.transId,
                            lineId: transaction.lineId,
                            status: attachments[i].status,
                        }
                        handleUploadedHtmlForClient(payload)
                        document.getElementById(`${attachments[i].id}`).addEventListener('click', handleDeleteAttachment)
                    }
                } else {
                    handleUploadedHtmlForClient({ isThereAttachments: false })
                }
            }, 100)
        }
    }

    // Todo: Make a helper that generates this template
    function handleUploadedHtmlForAdmin({ isThereAttachments, url, displayname, filename, filesize, id, transId, lineId, status }) {
        const uploadedArea = document.getElementById('uploaded-area')

        if (isThereAttachments) {
            uploadedArea.insertAdjacentHTML(
                'afterbegin',
                `
                    <ul class='bg-blue-100 mb-2 p-4 flex justify-between items-center rounded'>
                        <a href='${url}' target="_blank">
                            <div class='flex justify-between items-center gap-2'>
                                <span>
                                    <i class="fa-solid fa-file text-3xl text-blue-500"></i>
                                </span>
                                <div class='flex flex-col justify-center items-start text-xs'>
                                    <span>${formatDisplayName(displayname)} • Uploaded</span>
                                    <span>${Math.round(filesize / 1000)} KB</span>
                                </div>
                            </div>
                        </a>
                        <span class='text-gray-400 hover:text-gray-500 cursor-pointer' id='${id}' data-filename='${filename}' data-transid='${transId}' data-lineid='${lineId}' data-displayname='${displayname}' data-status='${status}'>
                            <i class="fa-solid fa-x"></i>
                        </span>
                    </ul>
                    
                `
            )
        } else {
            uploadedArea.insertAdjacentHTML(
                `afterbegin`,
                `
                    <div class='flex justify-center align-items p-4'>No files uploaded.</div>
                `
            )
        }
    }

    // Todo: Make a helper that generates this template
    function handleUploadedHtmlForClient({ isThereAttachments, url, displayname, filename, filesize, id, transId, lineId, status }) {
        const uploadedArea = document.getElementById('uploaded-area')

        if (isThereAttachments) {
            uploadedArea.insertAdjacentHTML(
                'afterbegin',
                `
                    <ul class='bg-blue-100 mb-2 p-4 flex justify-between items-center rounded'>
                        <a href='${url}' target="_blank">
                            <div class='flex justify-between items-center gap-2'>
                                <span>
                                    <i class="fa-solid fa-file text-3xl text-blue-500"></i>
                                </span>
                                <div class='flex flex-col justify-center items-start text-xs'>
                                    <span>${formatDisplayName(displayname)} • Uploaded</span>
                                    <span>${Math.round(filesize / 1000)} KB</span>
                                </div>
                            </div>
                        </a>
                        <span class='text-gray-400 hover:text-gray-500 cursor-pointer' id='${id}' data-filename='${filename}' data-transid='${transId}' data-lineid='${lineId}' data-status='${status}'>
                            <i class="fa-solid fa-x"></i>
                        </span>
                    </ul>
                    
                `
            )
        } else {
            uploadedArea.insertAdjacentHTML(
                `afterbegin`,
                `
                    <div class='flex justify-center align-items p-4'>No files uploaded.</div>
                `
            )
        }
    }

    async function handleUploadFiles(transaction) {
        const files = document.getElementById('files')
        if (!files.files.length)
            return ErrorNotification({
                title: 'File uploads',
                message: 'Cannot upload empty file(s).',
            })
        setButtonLoading(true)
        const uploadFileAttachmentResponse = await uploadFilesAttachment({ files, transId: transaction?.transId, transType: transaction?.transType, lineId: transaction?.lineId, companyId: companyProfile?.id }, handleOnLoadActions)
        if (uploadFileAttachmentResponse) {
            viewContext.modal.hide()
            UpdateNotification({
                title: 'File uploads',
                message: `Successfully uploaded ${files.files.length} file(s).`,
            })
        }
    }

    function handleDeleteAttachment() {
        const payload = {
            companyId: companyProfile?.id,
            transId: this.dataset.transid,
            lineId: this.dataset.lineid,
            attachmentId: this.id,
            filename: this.dataset.filename,
            status: this.dataset.status,
        }
        this.parentNode.remove()
        handleUploadedHtmlForClient({ isThereAttachments: false })
        deleteFilesAttachment(payload, handleOnLoadActions)
        UpdateNotification({
            title: 'File deletion',
            message: `Successfully deleted ${this.dataset.displayname} file(s).`,
        })
    }

    function handleEdit(transaction, type) {
        localStorage.setItem('transactionForEdit', JSON.stringify(transaction))
        viewContext.modal.show({
            body: <CardView usage='edit' functions={functions} data={data} transaction={transaction} />,
        })
    }

    async function handleDeleteTransaction(transaction) {
        await handleDeleteApprovedTransactionRequest({ companyId, transId: transaction?.transId, lineId: transaction?.lineId }, handleOnLoadActions)
        queryClient.invalidateQueries(['transactions'])
    }

    function formatDisplayName(str) {
        const splited_str = str.split('.')
        let formatted_str
        if (splited_str[0].length > 10) {
            formatted_str = `${splited_str[0].slice(0, 10)}....${splited_str[1]}`
        } else {
            formatted_str = str
        }
        return formatted_str
    }

    function handleRemovedUpdatePayloadFromLs(_id, companyId) {
        const payload = JSON.parse(localStorage.getItem('singleUpdatePayload'))
        const updatedpayload = payload?.filter((data) => data?._id !== _id) || []
        localStorage.setItem('singleUpdatePayload', JSON.stringify(updatedpayload))
    }

    async function handleSendBulkReview(props) {
        const payload = JSON.parse(localStorage.getItem('singleUpdatePayload')) || []
        const companyPayload = payload?.filter((p) => p.companyId === companyId)
        if (companyPayload) {
            for (const obj of companyPayload) {
                const transaction = transactions?.find((t) => t?._id === obj?._id)
                if (!obj?.description) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Adding description is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('vendor') && !obj?.vendor && !transaction?.vendor?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Vendor is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('class') && !obj?.class && !transaction?.class?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Class is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('location') && !obj?.location && !transaction?.location?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Department/Location is required.',
                    })
                }
            }
        }
        const transactionUpdatePayload = {
            companyId: companyId,
            transId: companyPayload.map((p) => p.transId),
            list: companyPayload.map((p) => {
                const { companyId, ...rest } = p
                return rest
            }),
        }
        const updateTransactionsResponse = await updateTransaction(transactionUpdatePayload, handleOnLoadActions)
        updateTransactionsResponse && queryClient.invalidateQueries(['transactions'])
        if (companyProfile?.notificationSettings.enableImmediateNotification) {
            immediateNotification(updateTransactionsResponse?.data?.trans, updateTransactionsResponse?.data?.companyId, handleOnLoadActions)
            UpdateNotification({
                title: 'Transaction update',
                message: 'Transaction was successfully sent for review',
            })
            UpdateNotification({
                title: 'Email notification',
                message: 'Accounting firm was successfully notified.',
            })
        } else {
            UpdateNotification({
                title: 'Transaction update',
                message: 'Transaction was successfully sent for review',
            })
        }
        localStorage.removeItem('singleUpdatePayload')
    }

    async function handleSaveApprove(transaction, usage) {
        viewContext.modal.hide()
        const payload = JSON.parse(localStorage.getItem('singleUpdatePayload')) || []
        const isTransactionHasUpdate = payload?.some((data) => data._id === transaction?._id && data.companyId === companyId)
        const isTransactionEditedByClient = companyProfile?.editedTransactions.some((data) => data._id === transaction?._id)
        const transactionUpdatePayload = payload?.find((data) => data._id === transaction?._id && data.companyId === companyId)
        const uncategorizedTransactions = companyProfile?.intuitSyncedAccounts || []
        // const uncategorizedTransactions = ['Ask My Accountant', 'Uncategorized Expense', 'Uncategorized Income', 'Uncategorized Asset']

        if (fetchTransactionType === 'categorizeMe') {
            if (permission === 'owner' || permission === 'staff') {
                if (uncategorizedTransactions.some((a) => transactionUpdatePayload?.category?.value.includes(a)) || (!transactionUpdatePayload?.category?.value && uncategorizedTransactions.some((a) => transaction?.category?.value.includes(a)))) {
                    return ErrorNotification({
                        title: 'Saving failed',
                        message: 'Updating category is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('vendor') && !transactionUpdatePayload?.vendor?.id && !transaction?.vendor?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Vendor is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('class') && !transactionUpdatePayload?.class?.id && !transaction?.class?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Class is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('location') && !transactionUpdatePayload?.location?.id && !transaction?.location?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Department/Location is required.',
                    })
                }
                const updatePayload = {
                    _id: transaction?._id,
                    companyId: transaction?.companyId,
                    transId: transaction?.transId,
                    transType: transaction?.transType,
                    lineId: transaction?.lineId,
                    description: transaction?.description,
                    vendor: transaction?.vendor,
                    customer: transaction?.customer,
                    category: transaction?.category,
                    class: transaction?.class,
                    location: transaction?.location,
                }
                const intuitUpdatePayload = transactionUpdatePayload ? transactionUpdatePayload : updatePayload
                const trans = {
                    // ...intuitUpdatePayload,
                    _id: intuitUpdatePayload?._id,
                    transId: intuitUpdatePayload?.transId,
                    lineId: intuitUpdatePayload?.lineId,
                    transType: intuitUpdatePayload?.transType,
                    vendor: intuitUpdatePayload?.vendor || transaction?.vendor,
                    customer: intuitUpdatePayload?.customer || transaction?.customer,
                    category: intuitUpdatePayload?.category || transaction?.category,
                    class: intuitUpdatePayload?.class || transaction?.class,
                    location: intuitUpdatePayload?.location || transaction?.location,
                    description: intuitUpdatePayload?.description || transaction?.description,
                    status: transaction?.status,
                    date: transaction?.date,
                    amount: transaction?.amount,
                    memo: transaction?.memo,
                    attachments: transaction?.attachments,
                }
                await updateIntuitTransaction({
                    // ...transactionUpdatePayload,
                    ...intuitUpdatePayload,
                })
                await deleteApprovedTransactions({
                    companyId,
                    transIds: [transaction?._id],
                    transactions: trans ? [trans] : [],
                })
                handleRemovedUpdatePayloadFromLs(transaction?._id, companyId)
                UpdateNotification({
                    title: 'Transaction update',
                    message: 'Transaction was successfully updated.',
                })
            } else if (permission === 'user' || permission === 'clientStaff') {
                if (!transactionUpdatePayload || !transactionUpdatePayload?.description) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Adding description is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('vendor') && !transactionUpdatePayload?.vendor?.id && !transaction?.vendor?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Vendor is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('class') && !transactionUpdatePayload?.class?.id && !transaction?.class?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Class is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('location') && !transactionUpdatePayload?.location?.id && !transaction?.location?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Department/Location is required.',
                    })
                }
                const updateTransactionsResponse = await updateTransaction(transactionUpdatePayload, handleOnLoadActions)
                updateTransactionsResponse && queryClient.invalidateQueries(['transactions'])
                if (companyProfile?.notificationSettings.enableImmediateNotification) {
                    immediateNotification(updateTransactionsResponse?.data?.trans, updateTransactionsResponse?.data?.companyId, handleOnLoadActions)
                    UpdateNotification({
                        title: 'Transaction update',
                        message: 'Transaction was successfully sent for review',
                    })
                    UpdateNotification({
                        title: 'Email notification',
                        message: 'Accounting firm was successfully notified.',
                    })
                } else {
                    UpdateNotification({
                        title: 'Transaction update',
                        message: 'Transaction was successfully sent for review',
                    })
                }
                handleRemovedUpdatePayloadFromLs(transaction?._id, companyId)
            }
        } else if (fetchTransactionType === 'underReview') {
            if (permission === 'owner' || permission === 'staff') {
                if (uncategorizedTransactions.some((a) => transactionUpdatePayload?.category?.value.includes(a) || transaction?.category?.value.includes(a))) {
                    return ErrorNotification({
                        title: 'Saving failed',
                        message: 'Updating category is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('vendor') && !transactionUpdatePayload?.vendor?.id && transaction?.vendor?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Vendor is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('class') && !transactionUpdatePayload?.class?.id && !transaction?.class?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Class is required.',
                    })
                }
                if (companyProfile.syncedMissingFields.includes('location') && !transactionUpdatePayload?.location?.id && !transaction?.location?.id) {
                    return ErrorNotification({
                        title: 'Sending to review failed',
                        message: 'Department/Location is required.',
                    })
                }

                const payload = companyProfile?.editedTransactions.find((p) => p._id === transaction?._id)
                if (isTransactionHasUpdate && isTransactionEditedByClient) {
                    await updateIntuitTransaction(
                        {
                            ...payload,
                            ...transactionUpdatePayload,
                        },
                        handleRemovedUpdatePayloadFromLs(transaction?._id, companyId)
                    )
                    await uploadApprovedAttachments([transaction?._id])
                    await approveChanges([transaction?._id])
                    localStorage.setItem('refreshTransactions', true)
                    queryClient.invalidateQueries(['transactions'])
                } else if (isTransactionEditedByClient) {
                    await updateIntuitTransaction({
                        companyId,
                        ...payload,
                        transType: transaction?.transType,
                    })
                    await uploadApprovedAttachments([transaction?._id])
                    await approveChanges([transaction?._id])
                    localStorage.setItem('refreshTransactions', true)
                    queryClient.invalidateQueries(['transactions'])
                }
                UpdateNotification({
                    title: 'Transaction update',
                    message: 'Transaction was successfully approved.',
                })
            }
        } else if (fetchTransactionType === 'approved') {
            return false
        }
    }

    async function handleApproveAll() {
        const payload = JSON.parse(localStorage.getItem('singleUpdatePayload')) || []
        if (!companyProfile?.editedTransactions.length && !payload.length) return UpdateNotification({ title: 'Transaction update', message: 'Transaction has no available updates.', icon: <IconInfoCircle /> })
        viewContext.setLoading(true)
        if ((permission === 'owner' || permission === 'staff') && fetchTransactionType === 'categorizeMe') {
            const requestPayload = payload?.map((payload) => ({
                ...payload,
            }))
            for (const data of requestPayload) {
                const uncategorizedTransactions = companyProfile?.intuitSyncedAccounts || []
                // const uncategorizedTransactions = ['Ask My Accountant', 'Uncategorized Expense', 'Uncategorized Income', 'Uncategorized Asset']
                const transaction = transactions?.find((transaction) => transaction?._id === data?._id)
                if (data?.category) {
                    if (uncategorizedTransactions.some((a) => data?.category?.value.includes(a))) {
                        handleOnLoadActions()
                        return ErrorNotification({
                            title: 'Saving failed',
                            message: 'Updating category is required.',
                        })
                    }
                } else {
                    if (uncategorizedTransactions.some((a) => transaction?.category?.value.includes(a))) {
                        handleOnLoadActions()
                        return ErrorNotification({
                            title: 'Saving failed',
                            message: 'Updating category is required.',
                        })
                    }
                }
            }
            const _requestPayload = requestPayload.map((payload) => {
                const transaction = transactions.find((transaction) => transaction?._id === payload?._id)
                return {
                    _id: payload?._id,
                    transId: payload?.transId,
                    lineId: payload?.lineId,
                    transType: payload?.transType,
                    vendor: payload?.vendor || transaction?.vendor,
                    customer: payload?.customer || transaction?.customer,
                    category: payload?.category || transaction?.category,
                    class: payload?.class || transaction?.class,
                    location: payload?.location || transaction?.location,
                    description: payload?.description || transaction?.description,
                    status: transaction?.status,
                    date: transaction?.date,
                    amount: transaction?.amount,
                    memo: transaction?.memo,
                    attachments: transaction?.attachments,
                }
            })
            await updateIntuitTransactions({ companyId, multipleUpdatePayload: requestPayload }, handleOnLoadActions)
            await deleteApprovedTransactions({
                companyId,
                // transIds: [checkboxes],
                transIds: checkboxes,
                transactions: _requestPayload || [],
            })
            await uploadApprovedAttachments(checkboxes)
            await approveChanges(checkboxes)
            UpdateNotification({
                title: 'Transaction update',
                message: 'Transaction was successfully approved.',
            })
            handleUncheckAllCheckboxes()
            for (let i = 0; i < payload.length; i++) {
                handleRemovedUpdatePayloadFromLs(payload[i]?._id, companyId)
            }
        } else if (fetchTransactionType === 'underReview') {
            for (const data of multipleUpdatePayload) {
                const uncategorizedTransactions = companyProfile?.intuitSyncedAccounts || []
                // const uncategorizedTransactions = ['Ask My Accountant', 'Uncategorized Expense', 'Uncategorized Income', 'Uncategorized Asset']
                if (data?.category) {
                    if (uncategorizedTransactions.some((a) => data?.category?.value.includes(a))) {
                        handleOnLoadActions()
                        return ErrorNotification({
                            title: 'Saving failed',
                            message: 'Updating category is required.',
                        })
                    }
                }
            }
            if (payload.length) {
                const transIds = multipleUpdatePayload.map((d) => d._id)
                const editedTransactions = payload.filter((d) => transIds.includes(d._id))
                const requestPayload = multipleUpdatePayload?.map((payload) => ({
                    _id: payload._id,
                    transId: payload.transId,
                    transType: payload.transType,
                    lineId: payload.lineId,
                    vendor: editedTransactions.find((d) => d._id === payload._id)?.vendor || companyProfile.editedTransactions.find((d) => d._id === payload._id)?.vendor,
                    customer: editedTransactions.find((d) => d._id === payload._id)?.customer || companyProfile.editedTransactions.find((d) => d._id === payload._id)?.customer,
                    category: editedTransactions.find((d) => d._id === payload._id)?.category || companyProfile.editedTransactions.find((d) => d._id === payload._id)?.category,
                    description: editedTransactions.find((d) => d._id === payload._id)?.description || companyProfile.editedTransactions.find((d) => d._id === payload._id)?.description,
                    class: editedTransactions.find((d) => d._id === payload._id)?.class || companyProfile.editedTransactions.find((d) => d._id === payload._id)?.class,
                    location: editedTransactions.find((d) => d._id === payload._id)?.location || companyProfile.editedTransactions.find((d) => d._id === payload._id)?.location,
                }))
                // const updatedPayloadIds = requestPayload.map((p) => p._id)
                // const clientUpdates = companyProfile?.editedTransactions.map((payload) => ({
                //     ...payload,
                //     transType: multipleUpdatePayload.find((d) => d._id === payload._id)?.transType,
                // }))
                // .filter((d) => !updatedPayloadIds.includes(d._id))
                await updateIntuitTransactions({ companyId, multipleUpdatePayload: requestPayload }, handleOnLoadActions)
                await uploadApprovedAttachments(checkboxes)
                await approveChanges(checkboxes)
                UpdateNotification({
                    title: 'Transaction update',
                    message: 'Transaction was successfully approved.',
                })
                handleUncheckAllCheckboxes()
                for (let i = 0; i < editedTransactions.length; i++) {
                    handleRemovedUpdatePayloadFromLs(editedTransactions[i]?._id, companyId)
                }
            } else {
                const payload = companyProfile?.editedTransactions.map((payload) => ({
                    ...payload,
                    transType: multipleUpdatePayload.find((d) => d._id === payload._id)?.transType,
                }))
                for (const data of payload) {
                    const uncategorizedTransactions = companyProfile?.intuitSyncedAccounts || []
                    // const uncategorizedTransactions = ['Ask My Accountant', 'Uncategorized Expense', 'Uncategorized Income', 'Uncategorized Asset']
                    if (data?.category) {
                        if (uncategorizedTransactions.some((a) => data?.category?.value.includes(a))) {
                            handleOnLoadActions()
                            return ErrorNotification({
                                title: 'Saving failed',
                                message: 'Updating category is required.',
                            })
                        }
                    }
                }
                await updateIntuitTransactions({ companyId, multipleUpdatePayload: payload }, handleOnLoadActions)
                await uploadApprovedAttachments(checkboxes)
                await approveChanges(checkboxes)
                UpdateNotification({
                    title: 'Transaction update',
                    message: 'Transaction was successfully approved.',
                })
                handleUncheckAllCheckboxes()
            }
        }
    }

    async function handleSaveBulkChange() {
        if (selectedBulkOption) {
            const option = bulkChangeOptions.filter((option) => option.id === selectedBulkOption)[0]
            if (selectedBulkOption === '1') {
                handleApproveAll()
            } else {
                if (selectedListItemForBulkChange) {
                    viewContext.setLoading(true)
                    const payload = JSON.parse(localStorage.getItem('singleUpdatePayload')) || []
                    if (payload.length) {
                        if (option.field === 'vendor') {
                            const _Ids = multipleUpdatePayload.map((payload) => payload?._id)
                            const transIds = multipleUpdatePayload.map((payload) => payload?.transId)
                            let existingSelectedTransactions = payload.filter((data) => _Ids.includes(data._id) && data.companyId === companyId)
                            const transType = option.field === 'vendor' ? transactions.filter((transaction) => _Ids.includes(transaction._id))[0]?.vendor?.type : null
                            const dataObject = renderDataObject(option.field, selectedListItemForBulkChange?.id, handleReturnFormattedList(option.field), transType)

                            if (existingSelectedTransactions.length) {
                                const editedExistingSelectedTransactions = payload.map((p) => {
                                    if (_Ids.includes(p._id) && p.companyId === companyId) {
                                        p[`${option.field}`] = dataObject
                                        return p
                                    } else {
                                        return p
                                    }
                                })
                                let data = []
                                for (let i = 0; i < editedExistingSelectedTransactions.length; i++) {
                                    const transType = option.field === 'vendor' ? transactions.find((transaction) => transaction._id === editedExistingSelectedTransactions[i]?._id)?.vendor?.type : null
                                    const dataObject = renderDataObject(option.field, selectedListItemForBulkChange?.id, handleReturnFormattedList(option.field), transType)
                                    data.push({
                                        _id: editedExistingSelectedTransactions[i]?._id,
                                        companyId,
                                        transId: editedExistingSelectedTransactions[i]?.transId,
                                        transType: editedExistingSelectedTransactions[i]?.transType,
                                        lineId: editedExistingSelectedTransactions[i]?.lineId,
                                        [`${option.field}`]: dataObject,
                                    })
                                }
                                // localStorage.setItem('singleUpdatePayload', JSON.stringify(data))
                                localStorage.setItem('singleUpdatePayload', JSON.stringify(editedExistingSelectedTransactions))
                            } else {
                                const splitTransactions = transactions
                                    .filter((transaction) => !_Ids.includes(transaction?._id) && transIds.includes(transaction?.transId) && transaction?.transType !== 'Deposit')
                                    .map((transaction) => ({
                                        _id: transaction?._id,
                                        transId: transaction?.transId,
                                        transType: transaction?.transType,
                                        lineId: transaction?.lineId,
                                    }))
                                const combinedTransactions = [...multipleUpdatePayload, ...splitTransactions]
                                let data = []
                                for (let i = 0; i < combinedTransactions.length; i++) {
                                    const transType = option.field === 'vendor' ? transactions.find((transaction) => transaction._id === combinedTransactions[i]?._id)?.vendor?.type : null
                                    const dataObject = renderDataObject(option.field, selectedListItemForBulkChange?.id, handleReturnFormattedList(option.field), transType)
                                    data.push({
                                        _id: combinedTransactions[i]?._id,
                                        companyId,
                                        transId: combinedTransactions[i]?.transId,
                                        transType: combinedTransactions[i]?.transType,
                                        lineId: combinedTransactions[i]?.lineId,
                                        [`${option.field}`]: dataObject,
                                    })
                                }
                                localStorage.setItem('singleUpdatePayload', JSON.stringify([...payload, ...data]))
                            }
                        } else {
                            const transIds = multipleUpdatePayload.map((payload) => payload._id)
                            let existingSelectedTransactions = payload.filter((data) => transIds.includes(data._id) && data.companyId === companyId)
                            const transType = option.field === 'vendor' ? transactions.filter((transaction) => transIds.includes(transaction._id))[0]?.vendor?.type : null
                            const dataObject = renderDataObject(option.field, selectedListItemForBulkChange?.id, handleReturnFormattedList(option.field), transType)
                            if (existingSelectedTransactions.length) {
                                const editedExistingSelectedTransactions = payload.map((p) => {
                                    if (transIds.includes(p._id) && p.companyId === companyId) {
                                        p[`${option.field}`] = dataObject
                                        return p
                                    } else {
                                        return p
                                    }
                                })
                                localStorage.setItem('singleUpdatePayload', JSON.stringify(editedExistingSelectedTransactions))
                            } else {
                                let data = []
                                for (let i = 0; i < multipleUpdatePayload.length; i++) {
                                    const transType = option.field === 'vendor' ? transactions.find((transaction) => transaction._id === multipleUpdatePayload[i]?._id)?.vendor?.type : null
                                    const dataObject = renderDataObject(option.field, selectedListItemForBulkChange?.id, handleReturnFormattedList(option.field), transType)
                                    data.push({
                                        _id: multipleUpdatePayload[i]?._id,
                                        companyId,
                                        transId: multipleUpdatePayload[i]?.transId,
                                        transType: multipleUpdatePayload[i]?.transType,
                                        lineId: multipleUpdatePayload[i]?.lineId,
                                        [`${option.field}`]: dataObject,
                                    })
                                }
                                localStorage.setItem('singleUpdatePayload', JSON.stringify([...payload, ...data]))
                            }
                        }
                    } else {
                        if (option.field === 'vendor') {
                            const _Ids = multipleUpdatePayload.map((payload) => payload?._id)
                            const transIds = multipleUpdatePayload.map((payload) => payload?.transId)
                            const splitTransactions = transactions
                                .filter((transaction) => !_Ids.includes(transaction?._id) && transIds.includes(transaction?.transId) && transaction?.transType !== 'Deposit')
                                .map((transaction) => ({
                                    _id: transaction?._id,
                                    transId: transaction?.transId,
                                    transType: transaction?.transType,
                                    lineId: transaction?.lineId,
                                }))
                            const combinedTransactions = [...multipleUpdatePayload, ...splitTransactions]
                            let data = []
                            for (let i = 0; i < combinedTransactions.length; i++) {
                                const transType = option.field === 'vendor' ? transactions.find((transaction) => transaction._id === combinedTransactions[i]?._id)?.vendor?.type : null
                                const dataObject = renderDataObject(option.field, selectedListItemForBulkChange?.id, handleReturnFormattedList(option.field), transType)
                                data.push({
                                    _id: combinedTransactions[i]?._id,
                                    companyId,
                                    transId: combinedTransactions[i]?.transId,
                                    transType: combinedTransactions[i]?.transType,
                                    lineId: combinedTransactions[i]?.lineId,
                                    [`${option.field}`]: dataObject,
                                })
                            }
                            localStorage.setItem('singleUpdatePayload', JSON.stringify(data))
                        } else {
                            let data = []
                            for (let i = 0; i < multipleUpdatePayload.length; i++) {
                                const transType = option.field === 'vendor' ? transactions.find((transaction) => transaction._id === multipleUpdatePayload[i]?._id)?.vendor?.type : null
                                const dataObject = renderDataObject(option.field, selectedListItemForBulkChange?.id, handleReturnFormattedList(option.field), transType)
                                data.push({
                                    _id: multipleUpdatePayload[i]?._id,
                                    companyId,
                                    transId: multipleUpdatePayload[i]?.transId,
                                    transType: multipleUpdatePayload[i]?.transType,
                                    lineId: multipleUpdatePayload[i]?.lineId,
                                    [`${option.field}`]: dataObject,
                                })
                            }
                            localStorage.setItem('singleUpdatePayload', JSON.stringify(data))
                        }
                    }

                    queryClient.invalidateQueries(['transactions'])
                    handleUncheckAllCheckboxes()
                } else {
                    return ErrorNotification({ title: 'Transactions update', message: option.error })
                }
            }
        } else {
            return ErrorNotification({ title: 'Transactions update', message: 'Please select an action.' })
        }
    }

    function handleChange(props) {
        setIsAutoSaveLoading(true)
        const { transaction, transactions, value, type } = props
        const transType = type === 'vendor' || type === 'customer' ? transaction[type].type : null
        const dataObject = type === 'description' ? value : renderDataObject(type, value, handleReturnFormattedList(type), transType)
        const payload = JSON.parse(localStorage.getItem('singleUpdatePayload')) || []

        let __payload
        if (payload?.length) {
            if (type === 'vendor' && transaction?.transType !== 'Deposit') {
                const othersPayloads = payload.filter((data) => data.transId !== transaction?.transId)
                let updatedPayload = payload.find((data) => data.transId === transaction?.transId && data.companyId === companyId)
                if (updatedPayload) {
                    const splitTransactions = payload.filter((data) => data.transId === transaction?.transId)
                    const editedSplitTransactions = splitTransactions.map((data) => {
                        data['vendor'] = dataObject
                        return data
                    })
                    __payload = [...othersPayloads, ...editedSplitTransactions]
                } else {
                    const editedTxn = transactions
                        ?.map((txn) => {
                            if (txn.transId === transaction?.transId) {
                                return {
                                    _id: txn?._id,
                                    companyId,
                                    transId: txn?.transId,
                                    transType: txn?.transType,
                                    lineId: txn?.lineId,
                                    [`${type}`]: dataObject,
                                }
                            } else {
                                return null
                            }
                        })
                        .filter((d) => Boolean(d))
                    __payload = [...othersPayloads, ...editedTxn]
                }
            } else {
                const othersPayloads = payload.filter((data) => data._id !== transaction?._id)
                let updatedPayload = payload.find((data) => data._id === transaction?._id && data.companyId === companyId)
                if (updatedPayload) {
                    updatedPayload[`${type}`] = dataObject
                    __payload = [...othersPayloads, updatedPayload]
                } else {
                    const newPayload = {
                        _id: transaction?._id,
                        companyId,
                        transId: transaction?.transId,
                        transType: transaction?.transType,
                        lineId: transaction?.lineId,
                        [`${type}`]: dataObject,
                    }
                    __payload = [...othersPayloads, newPayload]
                }
            }
        } else {
            if (type === 'vendor' && transaction?.transType !== 'Deposit') {
                __payload = transactions
                    ?.map((txn) => {
                        if (txn.transId === transaction?.transId) {
                            return {
                                _id: txn?._id,
                                companyId,
                                transId: txn?.transId,
                                transType: txn?.transType,
                                lineId: txn?.lineId,
                                [`${type}`]: dataObject,
                            }
                        } else {
                            return null
                        }
                    })
                    .filter((d) => Boolean(d))
            } else {
                let data = []
                data.push({
                    _id: transaction?._id,
                    companyId,
                    transId: transaction?.transId,
                    transType: transaction?.transType,
                    lineId: transaction?.lineId,
                    [`${type}`]: dataObject,
                })
                __payload = data
            }
        }
        localStorage.setItem('singleUpdatePayload', JSON.stringify(__payload))
        setTimeout(() => setIsAutoSaveLoading(false), 1000)

        if ((permission === 'owner' || permission === 'staff') && !props?.isCreateCategory) {
            if (type === 'vendor' && transaction?.transType !== 'Deposit') {
                const transactionsUpdatePayload = __payload.filter((data) => data?.transId === transaction?.transId && data.companyId === companyId)
                for (let i = 0; i < transactionsUpdatePayload.length; i++) {
                    updateTransaction({ ...transactionsUpdatePayload[i], isNoActionNeeded: true })
                }
            } else {
                const transactionUpdatePayload = __payload.find((data) => data._id === transaction?._id && data.companyId === companyId)
                transactionUpdatePayload && updateTransaction({ ...transactionUpdatePayload, isNoActionNeeded: true })
            }
        } else {
            queryClient.invalidateQueries(['transactions'])
        }
    }

    function handleReturnFormattedList(type) {
        if (type === 'vendor') return [...lists?.vendors]
        else if (type === 'customer') return [...lists?.customers]
        else if (type === 'category') return lists?.allCategories
        else if (type === 'class') return lists?.classes
        else if (type === 'location') return lists?.locations
        else return null
    }

    function handleUncheckAllCheckboxes() {
        const checkboxElements = document.querySelectorAll('input[type=checkbox]')
        setCheckboxes([])
        for (let i = 0; i < checkboxElements.length; i++) {
            checkboxElements[i].checked = false
        }
        setIsToggleBulkChangeActive(false)
        setSelectedBulkOption('')
        setSelectedListItemForBulkChange(undefined)
        setMultipleUpdatePayload([])
        setIsToggleViewActive(true)
    }

    function renderDataObject(type, value, list, transType) {
        let obj = {}
        if (type === 'category') {
            for (let i = 0; i < list.length; i++) {
                if (list[i].value === value) {
                    obj.id = list[i].value
                    obj.value = list[i].title
                }
                if (transType) {
                    obj.type = transType
                }
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id === value) {
                    obj.id = list[i].id
                    obj.value = list[i].value
                }
                if (transType) {
                    obj.type = transType
                }
            }
        }
        return obj
    }

    function handleSelectedListItem(props) {
        const selectedItem = props?.list.find((data) => data?.id === props?.value || data?.value === props?.value)
        let item
        if (selectedBulkOption === '2' && selectedItem && selectedItem?.hasOwnProperty('title')) {
            item = {
                id: selectedItem?.value,
                value: selectedItem?.title,
            }
        } else if (selectedBulkOption === '2' && !selectedItem) {
            const obj = lists?.allCategories?.find((category) => category.value === props?.value)
            item = {
                id: obj?.value,
                value: obj?.title,
            }
        } else {
            item = selectedItem
        }
        setSelectedListItemForBulkChange(item)
    }

    // START OF SPLIT TRANSACTION
    function handleSplitItemOnChange(element, type, transaction, _splitBy) {
        // if (type === 'amount') {
        //     // console.log(+element.value)
        //     // console.log(element.value)
        //     if (+element.value < 1 && !isNaN(+element.value)) {
        //         const val = element.value?.split('.')
        //         if (val?.length === 2 && (!val[1][1] || val[1][1] === '0')) {
        //             if (+element.value < 0) {
        //                 element.value = `-${Math.abs(element.value)}0`
        //             } else {
        //                 element.value = `${Math.abs(element.value)}0`
        //             }
        //         } else {
        //             if (+element.value < 0) {
        //                 element.value = `-${Math.abs(element.value)}`
        //             } else {
        //                 element.value = `${Math.abs(element.value)}`
        //             }
        //         }
        //     } else if (+element.value >= 1 && !isNaN(+element.value)) {
        //         if (+element.value < 0) {
        //             element.value = `-${Math.abs(element.value)}0`
        //         } else {
        //             // element.value = `${Math.abs(element.value)}0`
        //             const val = element.value?.split('.')
        //             if (val?.length === 2 && (!val[1][1] || val[1][1] === '0')) {
        //                 if (+element.value < 0) {
        //                     element.value = `-${Math.abs(element.value)}0`
        //                 } else {
        //                     if (val[1]) {
        //                         element.value = `${Math.abs(element.value)}0`
        //                     } else {
        //                         element.value = val[0]
        //                     }
        //                 }
        //             } else {
        //                 if (+element.value < 0) {
        //                     element.value = `-${Math.abs(element.value)}`
        //                 } else {
        //                     element.value = `${Math.abs(element.value)}`
        //                 }
        //             }
        //         }
        //     } else {
        //         element.value = 0
        //     }
        // }
        let value = element.value
        if (type === 'amount') {
            value = element.value ? +element.value.replace('$', '') : 0
        }
        if (type === 'list' && _splitBy === 'category') {
            const splitItemElement = document.getElementById(`item-${element.id}`)
            const splitItemElementRoot = createRoot(splitItemElement)
            if (transaction?.transType === 'Purchase') {
                if (element.value && element.value.toLowerCase().includes('accounts receivable')) {
                    splitItemElementRoot.render(handleRenderCustomerListComponent(element.id))
                } else {
                    if (splitItemElement.firstChild) {
                        splitItemElement.removeChild(splitItemElement.firstChild)
                    }
                }
            } else if (transaction?.transType === 'Deposit') {
                if (element.value && element.value.toLowerCase().includes('accounts receivable')) {
                    splitItemElementRoot.render(handleRenderCustomerListComponent(element.id))
                } else if (element.value && element.value?.toLowerCase().includes('accounts payable')) {
                    splitItemElementRoot.render(handleRenderVendorListComponent(element.id))
                } else {
                    if (splitItemElement.firstChild) {
                        splitItemElement.removeChild(splitItemElement.firstChild)
                    }
                }
            }
        }
        const splitItemsPayloadStorage = JSON.parse(localStorage.getItem('splitItemsPayload'))
        if (splitItemsPayloadStorage) {
            const otherItems = splitItemsPayloadStorage.filter((item) => item.id !== element.id)
            let existingItem = splitItemsPayloadStorage.find((item) => item.id === element.id)
            if (existingItem) {
                if (type === 'list' && _splitBy === 'category' && !['Accounts Receivable (A/R)', 'Accounts Receivable', 'Accounts Payable (A/P)', 'Accounts Payable'].includes(value)) {
                    delete existingItem?.vendorcustomer
                }
                existingItem[type] = value
                localStorage.setItem('splitItemsPayload', JSON.stringify([...otherItems, existingItem]))
            } else {
                localStorage.setItem('splitItemsPayload', JSON.stringify([...otherItems, { id: element.id, type: _splitBy, [type]: value }]))
            }
        } else {
            localStorage.setItem('splitItemsPayload', JSON.stringify([{ id: element.id, type: _splitBy, [type]: value }]))
        }
    }

    function handleDeleteSplitItemOnClick(e) {
        setSplitTransaction((prev) => prev.filter((item) => item.id !== e.currentTarget.id))
        if (JSON.parse(localStorage.getItem('splitItemsPayload'))) {
            const filteredSplitItems = JSON.parse(localStorage.getItem('splitItemsPayload')).filter((item) => item.id !== e.currentTarget.id)
            localStorage.setItem('splitItemsPayload', JSON.stringify(filteredSplitItems))
        }
    }

    function handleAddSplitTransaction({ id, list, amount, button }, transaction) {
        if (!splitBy) {
            return messageApi.open({
                type: 'error',
                content: 'Split by must be selected.',
            })
        }
        setSplitTransaction((prev) => [
            ...prev,
            {
                id,
                list,
                amount,
                button,
            },
        ])
    }

    async function handleSaveSplitTransaction(transaction, _splitTransactions) {
        const splitItemsPayloadStorage = JSON.parse(localStorage.getItem('splitItemsPayload'))
        const updatePayload = JSON.parse(localStorage.getItem('singleUpdatePayload'))
        const editedTransaction = updatePayload?.find((payload) => payload?._id === transaction?._id)
        if (splitItemsPayloadStorage) {
            splitItemsPayloadStorage.sort((a, b) => a.id - b.id)
            if (!splitItemsPayloadStorage?.length) {
                return messageApi.open({
                    type: 'error',
                    content: `Type and amount are required.`,
                })
            }
            for (const item of splitItemsPayloadStorage) {
                if (!item?.amount) {
                    return messageApi.open({
                        type: 'error',
                        content: 'Enter split amount.',
                    })
                }
                if (isNaN(item?.amount)) {
                    return messageApi.open({
                        type: 'error',
                        content: 'Amount must be a number.',
                    })
                }
                if (!item?.list || !item.amount) {
                    return messageApi.open({
                        type: 'error',
                        content: `Type and amount are required.`,
                    })
                }
                if (transaction?.transType === 'Purchase') {
                    if (item?.list && item?.list?.toLowerCase().includes('accounts receivable') && !item?.vendorcustomer) {
                        return messageApi.open({
                            type: 'error',
                            content: `Category is required for Accounts Receivable category.`,
                        })
                    }
                } else if (transaction?.transType === 'Deposit') {
                    if (item?.list && item?.list?.toLowerCase().includes('accounts receivable') && !item?.vendorcustomer) {
                        return messageApi.open({
                            type: 'error',
                            content: `Category is required for Accounts Receivable category.`,
                        })
                    } else if (item?.list && item?.list?.toLowerCase().includes('accounts payable') && !item?.vendorcustomer) {
                        return messageApi.open({
                            type: 'error',
                            content: `Vendor is required for Accounts Payable category.`,
                        })
                    }
                }
            }

            const totalSplitAmount = splitItemsPayloadStorage.reduce((acc, item) => acc + (item.amount ? +item.amount : 0), 0)

            // console.log(totalSplitAmount, +transaction?.amount.value)

            if (totalSplitAmount > +transaction?.amount.value) {
                return messageApi.open({
                    type: 'error',
                    content: `Split amount exceeded from the original amount of ${transaction?.amount.value}.`,
                })
            } else if (totalSplitAmount < +transaction?.amount.value) {
                return messageApi.open({
                    type: 'error',
                    content: `The total split amount must be equal to $${transaction?.amount.value}.`,
                })
            }

            const LineItems = _splitTransactions?.filter((t) => t.Id !== transaction?.lineId)
            let detailType
            if (transaction.transType === 'Deposit') {
                detailType = 'DepositLineDetail'
            } else if (transaction.transType === 'Purchase') {
                detailType = 'AccountBasedExpenseLineDetail'
            } else if (transaction.transType === 'JournalEntry') {
                detailType = 'JournalEntryLineDetail'
            }

            if (+transaction?.amount.value - totalSplitAmount) {
                let base = _splitTransactions.find((t) => t.Id === transaction?.lineId)
                base.Id = transaction?.lineId
                if (base.LineNum) {
                    base.LineNum = transaction?.lineNum
                }
                base.Amount = +transaction?.amount.value - totalSplitAmount
                base.Description = editedTransaction?.description || transaction?.description
                LineItems.push(base)
            } else {
                let base = _splitTransactions.find((t) => t.Id === transaction?.lineId)
                base.Id = transaction?.lineId
                if (base.LineNum) {
                    base.LineNum = transaction?.lineNum
                }
                base.Amount = +splitItemsPayloadStorage[0]?.amount
                base.Description = editedTransaction?.description || transaction?.description
                if (splitItemsPayloadStorage[0].type === 'category') {
                    base[detailType]['AccountRef'] = {
                        value: lists?.allCategories?.find((i) => i.title === splitItemsPayloadStorage[0].list)?.value,
                        name: splitItemsPayloadStorage[0].list,
                    }
                    if (transaction?.transType === 'Purchase') {
                        if (splitItemsPayloadStorage[0]?.vendorcustomer && splitItemsPayloadStorage[0]?.list && splitItemsPayloadStorage[0]?.list?.toLowerCase().includes('accounts receivable')) {
                            base[detailType]['CustomerRef'] = {
                                value: lists?.customers?.find((i) => i.value === splitItemsPayloadStorage[0]?.vendorcustomer)?.id,
                                name: splitItemsPayloadStorage[0]?.vendorcustomer,
                            }
                        }
                    } else if (transaction?.transType === 'Deposit') {
                        if (splitItemsPayloadStorage[0]?.vendorcustomer && splitItemsPayloadStorage[0]?.list && splitItemsPayloadStorage[0]?.list?.toLowerCase().includes('accounts receivable')) {
                            base[detailType]['Entity'] = {
                                value: lists?.customers?.find((i) => i.value === splitItemsPayloadStorage[0]?.vendorcustomer)?.id,
                                name: splitItemsPayloadStorage[0]?.vendorcustomer,
                                type: 'CUSTOMER',
                            }
                        } else if (splitItemsPayloadStorage[0]?.vendorcustomer && splitItemsPayloadStorage[0]?.list && splitItemsPayloadStorage[0]?.list?.toLowerCase().includes('accounts payable')) {
                            base[detailType]['Entity'] = {
                                value: lists?.vendors?.find((i) => i.value === splitItemsPayloadStorage[0]?.vendorcustomer)?.id,
                                name: splitItemsPayloadStorage[0]?.vendorcustomer,
                                type: 'VENDOR',
                            }
                        }
                    }
                } else if (splitItemsPayloadStorage[0].type === 'class') {
                    base[detailType]['ClassRef'] = {
                        value: lists?.classes?.find((i) => i.value === splitItemsPayloadStorage[0].list)?.id,
                        name: splitItemsPayloadStorage[0].list,
                    }
                }
                LineItems.push(base)
                splitItemsPayloadStorage.splice(0, 1)
            }

            for (const item of splitItemsPayloadStorage) {
                if (item.type === 'category') {
                    if (transaction?.transType === 'Purchase') {
                        if (item?.vendorcustomer && item?.list && item?.list?.toLowerCase().includes('accounts receivable')) {
                            LineItems.push({
                                Description: editedTransaction?.description || transaction?.description,
                                Amount: +item.amount,
                                DetailType: detailType,
                                [detailType]: {
                                    AccountRef: {
                                        value: lists?.allCategories?.find((i) => i.title === item.list)?.value,
                                        name: item.list,
                                    },
                                    CustomerRef: {
                                        value: lists?.customers?.find((i) => i.value === item?.vendorcustomer)?.id,
                                        name: item?.vendorcustomer,
                                    },
                                },
                            })
                        } else {
                            LineItems.push({
                                Description: editedTransaction?.description || transaction?.description,
                                Amount: +item.amount,
                                DetailType: detailType,
                                [detailType]: {
                                    AccountRef: {
                                        value: lists?.allCategories?.find((i) => i.title === item.list)?.value,
                                        name: item.list,
                                    },
                                },
                            })
                        }
                    } else if (transaction?.transType === 'Deposit') {
                        if (item?.vendorcustomer && item?.list && item?.list?.toLowerCase().includes('accounts receivable')) {
                            LineItems.push({
                                Description: editedTransaction?.description || transaction?.description,
                                Amount: +item.amount,
                                DetailType: detailType,
                                [detailType]: {
                                    AccountRef: {
                                        value: lists?.allCategories?.find((i) => i.title === item.list)?.value,
                                        name: item.list,
                                    },
                                    Entity: {
                                        value: lists?.customers?.find((i) => i.value === item?.vendorcustomer)?.id,
                                        name: item?.vendorcustomer,
                                        type: 'CUSTOMER',
                                    },
                                },
                            })
                        } else if (item?.vendorcustomer && item?.list && item?.list?.toLowerCase().includes('accounts payable')) {
                            LineItems.push({
                                Description: editedTransaction?.description || transaction?.description,
                                Amount: +item.amount,
                                DetailType: detailType,
                                [detailType]: {
                                    AccountRef: {
                                        value: lists?.allCategories?.find((i) => i.title === item.list)?.value,
                                        name: item.list,
                                    },
                                    Entity: {
                                        value: lists?.vendors?.find((i) => i.value === item?.vendorcustomer)?.id,
                                        name: item?.vendorcustomer,
                                        type: 'VENDOR',
                                    },
                                },
                            })
                        } else {
                            LineItems.push({
                                Description: editedTransaction?.description || transaction?.description,
                                Amount: +item.amount,
                                DetailType: detailType,
                                [detailType]: {
                                    AccountRef: {
                                        value: lists?.allCategories?.find((i) => i.title === item.list)?.value,
                                        name: item.list,
                                    },
                                },
                            })
                        }
                    }
                } else if (item.type === 'class') {
                    LineItems.push({
                        Description: editedTransaction?.description || transaction?.description,
                        Amount: +item.amount,
                        DetailType: detailType,
                        [detailType]: {
                            AccountRef: {
                                value: transaction?.category.id,
                                name: transaction?.category.name,
                            },
                            ClassRef: {
                                value: lists?.classes?.find((i) => i.value === item.list)?.id,
                                name: item.list,
                            },
                        },
                    })
                }
            }
            const payload = {
                companyId,
                transType: transaction?.transType,
                transId: transaction?.transId,
                splitItems: LineItems,
            }
            viewContext.setLoading(true)
            viewContext.modal.hide(true)
            setSelectedBulkOption('')

            // if (fetchTransactionType === 'categorizeMe') {
            //     const splitTransactionsResponseObject = await handleSplitTransactionsRequest(payload)
            //     const newSplitItems = splitTransactionsResponseObject.Line || []
            //     const newSplitItemsPayload = newSplitItems
            //         .map((i) => {
            //             delete transaction.createdAt
            //             delete transaction.updatedAt
            //             return {
            //                 ...transaction,
            //                 lineId: i.Id,
            //                 status: 'No Action Needed',
            //                 amount: {
            //                     value: i.Amount || transaction.amount.value,
            //                     color: transaction.amount.color,
            //                     tooltip: transaction.amount.tooltip,
            //                 },
            //                 description: i.Description || transaction?.description,
            //                 vendor:
            //                     transaction?.transType === 'Purchase'
            //                         ? {
            //                               id: splitTransactionsResponseObject?.EntityRef?.value || transaction?.vendor?.id || '',
            //                               value: splitTransactionsResponseObject?.EntityRef?.name || transaction?.vendor?.value || '',
            //                               type: splitTransactionsResponseObject?.EntityRef?.type || transaction?.vendor?.type?.toUpperCase(),
            //                           }
            //                         : transaction?.transType === 'Deposit'
            //                         ? {
            //                               id: i[i.DetailType]?.Entity?.value || transaction?.vendor?.id || '',
            //                               value: i[i.DetailType]?.Entity?.name || transaction?.vendor?.value || '',
            //                               type: i[i.DetailType]?.Entity?.type || transaction?.vendor?.type?.toUpperCase(),
            //                           }
            //                         : transaction?.transType === 'JournalEntry'
            //                         ? {
            //                               id: i[i.DetailType]?.Entity?.EntityRef?.value || transaction?.vendor?.id || '',
            //                               value: i[i.DetailType]?.Entity?.EntityRef?.name || transaction?.vendor?.value || '',
            //                               type: i[i.DetailType]?.Entity?.Type || transaction?.vendor?.type?.charAt(0).toUpperCase() + transaction?.vendor?.type.slice(1),
            //                           }
            //                         : {
            //                               id: transaction?.vendor?.id || '',
            //                               value: transaction?.vendor?.value || '',
            //                               type: transaction?.vendor?.type,
            //                           },
            //                 customer:
            //                     transaction?.transType === 'Purchase'
            //                         ? {
            //                               id: i[i.DetailType]?.CustomerRef?.value || transaction?.customer?.id || '',
            //                               value: i[i.DetailType]?.CustomerRef?.name || transaction?.customer?.value || '',
            //                           }
            //                         : transaction?.transType === 'Deposit'
            //                         ? {
            //                               id: i[i.DetailType]?.Entity?.value || transaction?.customer?.id || '',
            //                               value: i[i.DetailType]?.Entity?.name || transaction?.customer?.value || '',
            //                               type: i[i.DetailType]?.Entity?.type || transaction?.customer?.type?.toUpperCase(),
            //                           }
            //                         : transaction?.transType === 'JournalEntry'
            //                         ? {
            //                               id: i[i.DetailType]?.Entity?.EntityRef?.value || transaction?.customer?.id || '',
            //                               value: i[i.DetailType]?.Entity?.EntityRef?.name || transaction?.customer?.value || '',
            //                               type: i[i.DetailType]?.Entity?.Type || transaction?.customer?.type?.charAt(0).toUpperCase() + transaction?.customer?.type.slice(1),
            //                           }
            //                         : {
            //                               id: transaction?.customer?.id || '',
            //                               value: transaction?.customer?.value || '',
            //                               type: transaction?.customer?.type,
            //                           },
            //                 category: {
            //                     id: i[i.DetailType]?.AccountRef?.value || transaction?.category?.id || '',
            //                     value: i[i.DetailType]?.AccountRef?.name || transaction?.category?.value || '',
            //                 },
            //                 class: {
            //                     id: i[i.DetailType]?.ClassRef?.value || transaction?.class?.id || '',
            //                     value: i[i.DetailType]?.ClassRef?.name || transaction?.class?.value || '',
            //                     status: transaction?.class?.status || false,
            //                 },
            //             }
            //         })
            //         .filter((i) => !companyProfile?.intuitSyncedAccounts.some((a) => i.category.value.includes(a)))
            //     if (newSplitItemsPayload?.length) {
            //         await handleApproveSplitTransactionsRequest({ companyId, data: newSplitItemsPayload })
            //     }
            //     localStorage.setItem('refreshTransactions', true)
            //     queryClient.invalidateQueries(['transactions'])
            // } else if (fetchTransactionType === 'underReview') {
            //     const excludedTransItems = _splitTransactions.filter((t) => t.Id !== transaction.lineId).map((t) => t.Id) ?? []
            //     const splitTransactionsResponseObject = await handleSplitTransactionsRequest(payload)
            //     const newSplitItems = splitTransactionsResponseObject.Line || []
            //     // newSplitItems.shift()
            //     const newSplitItemsPayload = newSplitItems
            //         .map((i) => {
            //             delete transaction._id
            //             delete transaction.createdAt
            //             delete transaction.updatedAt
            //             return {
            //                 ...transaction,
            //                 lineId: i.Id,
            //                 status: 'Needs Review',
            //                 amount: {
            //                     value: i.Amount || transaction.amount.value,
            //                     color: transaction.amount.color,
            //                     tooltip: transaction.amount.tooltip,
            //                 },
            //                 description: i.Description || transaction?.description,
            //                 vendor:
            //                     transaction?.transType === 'Purchase'
            //                         ? {
            //                               id: splitTransactionsResponseObject?.EntityRef?.value || transaction?.vendor?.id || '',
            //                               value: splitTransactionsResponseObject?.EntityRef?.name || transaction?.vendor?.value || '',
            //                               type: splitTransactionsResponseObject?.EntityRef?.type || transaction?.vendor?.type?.toUpperCase(),
            //                           }
            //                         : transaction?.transType === 'Deposit'
            //                         ? {
            //                               id: i[i.DetailType]?.Entity?.value || transaction?.vendor?.id || '',
            //                               value: i[i.DetailType]?.Entity?.name || transaction?.vendor?.value || '',
            //                               type: i[i.DetailType]?.Entity?.type || transaction?.vendor?.type?.toUpperCase(),
            //                           }
            //                         : transaction?.transType === 'JournalEntry'
            //                         ? {
            //                               id: i[i.DetailType]?.Entity?.EntityRef?.value || transaction?.vendor?.id || '',
            //                               value: i[i.DetailType]?.Entity?.EntityRef?.name || transaction?.vendor?.value || '',
            //                               type: i[i.DetailType]?.Entity?.Type || transaction?.vendor?.type?.charAt(0).toUpperCase() + transaction?.vendor?.type.slice(1),
            //                           }
            //                         : {
            //                               id: transaction?.vendor?.id || '',
            //                               value: transaction?.vendor?.value || '',
            //                               type: transaction?.vendor?.type,
            //                           },
            //                 customer:
            //                     transaction?.transType === 'Purchase'
            //                         ? {
            //                               id: i[i.DetailType]?.CustomerRef?.value || transaction?.customer?.id || '',
            //                               value: i[i.DetailType]?.CustomerRef?.name || transaction?.customer?.value || '',
            //                           }
            //                         : transaction?.transType === 'Deposit'
            //                         ? {
            //                               id: i[i.DetailType]?.Entity?.value || transaction?.customer?.id || '',
            //                               value: i[i.DetailType]?.Entity?.name || transaction?.customer?.value || '',
            //                               type: i[i.DetailType]?.Entity?.type || transaction?.customer?.type?.toUpperCase(),
            //                           }
            //                         : transaction?.transType === 'JournalEntry'
            //                         ? {
            //                               id: i[i.DetailType]?.Entity?.EntityRef?.value || transaction?.customer?.id || '',
            //                               value: i[i.DetailType]?.Entity?.EntityRef?.name || transaction?.customer?.value || '',
            //                               type: i[i.DetailType]?.Entity?.Type || transaction?.customer?.type?.charAt(0).toUpperCase() + transaction?.customer?.type.slice(1),
            //                           }
            //                         : {
            //                               id: transaction?.customer?.id || '',
            //                               value: transaction?.customer?.value || '',
            //                               type: transaction?.customer?.type,
            //                           },
            //                 category: {
            //                     id: i[i.DetailType]?.AccountRef?.value || transaction?.category?.id || '',
            //                     value: i[i.DetailType]?.AccountRef?.name || transaction?.category?.value || '',
            //                 },
            //                 class: {
            //                     id: i[i.DetailType]?.ClassRef?.value || transaction?.class?.id || '',
            //                     value: i[i.DetailType]?.ClassRef?.name || transaction?.class?.value || '',
            //                     status: transaction?.class?.status || false,
            //                 },
            //             }
            //         })
            //         .filter((t) => !excludedTransItems.includes(t.lineId))
            //     const lineIds = newSplitItemsPayload.map((t) => t.lineId)
            //     await handleUpdateSplitTransactionsRequest({ companyId, transId: transaction?.transId, lineIds, data: newSplitItemsPayload })
            //     localStorage.setItem('refreshTransactions', true)
            //     queryClient.invalidateQueries(['transactions'])
            // }

            const splitTransactionsResponseObject = await handleSplitTransactionsRequest(payload)
            const newSplitItems = splitTransactionsResponseObject.Line || []
            const newSplitItemsPayload = newSplitItems
                .map((i) => {
                    delete transaction.createdAt
                    delete transaction.updatedAt
                    return {
                        ...transaction,
                        lineId: i.Id,
                        status: 'No Action Needed',
                        amount: {
                            value: i.Amount || transaction.amount.value,
                            color: transaction.amount.color,
                            tooltip: transaction.amount.tooltip,
                        },
                        description: i.Description || transaction?.description,
                        vendor:
                            transaction?.transType === 'Purchase'
                                ? {
                                      id: splitTransactionsResponseObject?.EntityRef?.value || transaction?.vendor?.id || '',
                                      value: splitTransactionsResponseObject?.EntityRef?.name || transaction?.vendor?.value || '',
                                      type: splitTransactionsResponseObject?.EntityRef?.type || transaction?.vendor?.type?.toUpperCase(),
                                  }
                                : transaction?.transType === 'Deposit'
                                ? {
                                      id: i[i.DetailType]?.Entity?.value || transaction?.vendor?.id || '',
                                      value: i[i.DetailType]?.Entity?.name || transaction?.vendor?.value || '',
                                      type: i[i.DetailType]?.Entity?.type || transaction?.vendor?.type?.toUpperCase(),
                                  }
                                : transaction?.transType === 'JournalEntry'
                                ? {
                                      id: i[i.DetailType]?.Entity?.EntityRef?.value || transaction?.vendor?.id || '',
                                      value: i[i.DetailType]?.Entity?.EntityRef?.name || transaction?.vendor?.value || '',
                                      type: i[i.DetailType]?.Entity?.Type || transaction?.vendor?.type?.charAt(0).toUpperCase() + transaction?.vendor?.type.slice(1),
                                  }
                                : {
                                      id: transaction?.vendor?.id || '',
                                      value: transaction?.vendor?.value || '',
                                      type: transaction?.vendor?.type,
                                  },
                        customer:
                            transaction?.transType === 'Purchase'
                                ? {
                                      id: i[i.DetailType]?.CustomerRef?.value || transaction?.customer?.id || '',
                                      value: i[i.DetailType]?.CustomerRef?.name || transaction?.customer?.value || '',
                                  }
                                : transaction?.transType === 'Deposit'
                                ? {
                                      id: i[i.DetailType]?.Entity?.value || transaction?.customer?.id || '',
                                      value: i[i.DetailType]?.Entity?.name || transaction?.customer?.value || '',
                                      type: i[i.DetailType]?.Entity?.type || transaction?.customer?.type?.toUpperCase(),
                                  }
                                : transaction?.transType === 'JournalEntry'
                                ? {
                                      id: i[i.DetailType]?.Entity?.EntityRef?.value || transaction?.customer?.id || '',
                                      value: i[i.DetailType]?.Entity?.EntityRef?.name || transaction?.customer?.value || '',
                                      type: i[i.DetailType]?.Entity?.Type || transaction?.customer?.type?.charAt(0).toUpperCase() + transaction?.customer?.type.slice(1),
                                  }
                                : {
                                      id: transaction?.customer?.id || '',
                                      value: transaction?.customer?.value || '',
                                      type: transaction?.customer?.type,
                                  },
                        category: {
                            id: i[i.DetailType]?.AccountRef?.value || transaction?.category?.id || '',
                            value: i[i.DetailType]?.AccountRef?.name || transaction?.category?.value || '',
                        },
                        class: {
                            id: i[i.DetailType]?.ClassRef?.value || transaction?.class?.id || '',
                            value: i[i.DetailType]?.ClassRef?.name || transaction?.class?.value || '',
                            status: transaction?.class?.status || false,
                        },
                    }
                })
                .filter((i) => !companyProfile?.intuitSyncedAccounts.some((a) => i.category.value.includes(a)))
            if (newSplitItemsPayload?.length) {
                await handleApproveSplitTransactionsRequest({ companyId, data: newSplitItemsPayload })
            }
            localStorage.setItem('refreshTransactions', true)
            queryClient.invalidateQueries(['transactions'])
        }
    }

    function handleVendorCustomerOnChange(value, uuid) {
        const splitItemsPayloadStorage = JSON.parse(localStorage.getItem('splitItemsPayload'))
        const editedSplitItemPayload = splitItemsPayloadStorage.map((payload) => {
            if (payload.id === uuid) {
                return {
                    ...payload,
                    vendorcustomer: value,
                }
            }
            return payload
        })
        localStorage.setItem('splitItemsPayload', JSON.stringify(editedSplitItemPayload))
    }

    function handleRenderVendorListComponent(uuid) {
        return (
            <Select
                searchable
                style={{ width: '100%' }}
                placeholder='Vendor'
                data={lists?.vendors.map((data) => {
                    return {
                        value: data.id,
                        label: data.value,
                        id: uuid,
                    }
                })}
                id={uuid}
                onBlur={(e) => handleVendorCustomerOnChange(e.target.value, uuid)}
            />
        )
    }

    function handleRenderCustomerListComponent(uuid) {
        return (
            <Select
                searchable
                style={{ width: '100%' }}
                placeholder='Customer'
                data={lists?.customers.map((data) => {
                    return {
                        value: data.id,
                        label: data.value,
                        id: uuid,
                    }
                })}
                id={uuid}
                onBlur={(e) => handleVendorCustomerOnChange(e.target.value, uuid)}
            />
        )
    }

    function handleRenderCategoryListComponent(_splitBy, transaction, uuid) {
        return (
            <TreeSelect
                showSearch
                allowClear
                // multiple
                treeNodeFilterProp='title'
                placeholder='Select Category'
                type='category'
                treeData={handleFilterExcludedAccounts(companyProfile?.excludedAccounts, lists?.categories)?.map((d) => ({ ...d, id: uuid }))}
                style={{
                    width: '80%',
                    // minWidth: '180px',
                    height: '36px',
                    borderRadius: '4px',
                    borderColor: '#ced4da',
                }}
                onSelect={(val, options) => handleSplitItemOnChange({ value: options.title, id: options?.id ?? uuid }, 'list', transaction, _splitBy)}
            />
        )
    }

    function handleRenderClassListComponent(_splitBy, transaction, uuid) {
        return (
            <Select
                searchable
                style={{ width: '80%' }}
                // placeholder={`Select ${splitBy.charAt(0).toUpperCase() + splitBy.slice(1)}`}
                placeholder='Select Class'
                data={lists?.classes.map((data) => {
                    return {
                        value: data.id,
                        label: data.value,
                        id: uuid,
                    }
                })}
                id={uuid}
                onSelect={(e) => handleSplitItemOnChange(e.target, 'list', transaction, _splitBy)}
            />
        )
    }

    function handleSplitByOnChange(_splitBy, transaction) {
        localStorage.setItem('splitItemsPayload', JSON.stringify([]))
        setSplitTransaction([])
        setSplitBy(_splitBy)
    }

    async function handleShowSplitTransactionModal({ _splitTransaction, _splitBy, _splitItemsPayload, _checkboxes, _transactions, _splitTransactions, _transaction }) {
        const uuid = uuidv4()
        let transaction
        if (_transactions && Array.isArray(_transactions)) {
            transaction = transactions?.find((transaction) => transaction._id === _checkboxes[0])
        } else {
            transaction = _transaction
        }
        if (!_splitTransactions) {
            await handleFetchSplitTransactionsRequest({ companyId, transType: transaction?.transType, transId: transaction?.transId })
        }
        const selectByList = [{ value: 'category', label: 'Category' }]
        if (companyProfile?.isClassEnabled) {
            selectByList.push({ value: 'class', label: 'Class' })
        }
        if (selectedBulkOption && selectedBulkOption === '7') {
            if (permission === 'owner' || permission === 'staff') {
                viewContext.modal.show({
                    title: 'Split Transaction',
                    text: (
                        <div>
                            {contextHolder}
                            <div>
                                <table className='w-full border-collapse'>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Category</th>
                                            <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Class</th>
                                            <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                        <td style={{ border: '1px solid #dddddd', padding: '8px', borderWidth: 'unset!important' }}>{transaction?.category.value || '--'}</td>
                                        <td style={{ border: '1px solid #dddddd', padding: '8px', borderWidth: 'unset!important' }}>{transaction?.class.value || '--'}</td>
                                        <td style={{ border: '1px solid #dddddd', padding: '8px', borderWidth: 'unset!important' }}>{`$${transaction?.amount.value}`}</td>
                                    </tr>
                                </table>
                            </div>
                            <Divider plain style={{ marginBottom: 4 }} />
                            <div className='flex items-end gap-2'>
                                <Select style={{ width: '100%' }} label='Split by' placeholder='Select' value={_splitBy} data={selectByList} onChange={(value) => handleSplitByOnChange(value, transaction)} />
                                <button
                                    onClick={() =>
                                        handleAddSplitTransaction(
                                            {
                                                id: uuid,
                                                list: _splitBy === 'category' ? handleRenderCategoryListComponent(_splitBy, transaction, uuid) : handleRenderClassListComponent(_splitBy, transaction, uuid),
                                                // amount: <Input style={{ width: '100px' }} id={uuid} onBlur={(e) => handleSplitItemOnChange(e.target, 'amount', transaction, _splitBy)} placeholder='$' />,
                                                amount: (
                                                    <CurrencyInput
                                                        placeholder='$0.00'
                                                        type='text'
                                                        style={{ width: '100px', height: '35px', padding: '0 11px', border: '1px solid #d9d9d9', borderRadius: '5px' }}
                                                        id={uuid}
                                                        splitInputType='amount'
                                                        transaction={transaction}
                                                        splitBy={_splitBy}
                                                        callback={handleSplitItemOnChange}
                                                    />
                                                ),
                                                button: (
                                                    <span className='cursor-pointer' id={uuid} onClick={(e) => handleDeleteSplitItemOnClick(e)}>
                                                        <IconTrash size={24} />
                                                    </span>
                                                ),
                                            },
                                            transaction
                                        )
                                    }
                                    style={{ border: '1px solid #dddddd' }}
                                    className='h-[35px] rounded text-sm flex items-center'
                                >
                                    <IconPlus size={20} />
                                    Add Transaction
                                </button>
                            </div>
                            <div>
                                {_splitTransaction.map((split) => (
                                    <div key={split.id} className='flex items-center gap-2 pt-4'>
                                        {split.list}
                                        <div id={`item-${split.id}`}></div>
                                        {split.amount}
                                        {split.button}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ),
                    body: (
                        <div className='flex justify-between gap-4'>
                            {/* <ClientButton bg='bg-cyan-500' icon='columns' color='text-white' label='Split Transaction' /> */}
                            <ClientButton disabled={!_splitTransaction.length} width='!w-full' bg='bg-cyan-500' icon='save' color='text-white' label='Save Transaction' callback={() => handleSaveSplitTransaction(transaction, _splitTransactions)} />
                        </div>
                    ),
                    show: true,
                })
            }
        }
    }

    async function handleEditShowSplitTransactionModal({ _splitBy, _splitTransaction, _splitTransactions, _splitItemsPayload, _transaction }) {
        const uuid = uuidv4()
        const transaction = _transaction
        if (!_splitTransactions) {
            await handleFetchSplitTransactionsRequest({ companyId, transType: transaction?.transType, transId: transaction?.transId })
        }
        const selectByList = [{ value: 'category', label: 'Category' }]
        if (companyProfile?.isClassEnabled) {
            selectByList.push({ value: 'class', label: 'Class' })
        }
        if (permission === 'owner' || permission === 'staff') {
            viewContext.modal.show({
                title: 'Split Transaction',
                text: (
                    <div>
                        {contextHolder}
                        <div>
                            <table className='w-full border-collapse'>
                                <thead>
                                    <tr>
                                        <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Category</th>
                                        <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Class</th>
                                        <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Amount</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td style={{ border: '1px solid #dddddd', padding: '8px', borderWidth: 'unset!important' }}>{transaction?.category.value || '--'}</td>
                                    <td style={{ border: '1px solid #dddddd', padding: '8px', borderWidth: 'unset!important' }}>{transaction?.class.value || '--'}</td>
                                    <td style={{ border: '1px solid #dddddd', padding: '8px', borderWidth: 'unset!important' }}>{`$${transaction?.amount.value}`}</td>
                                </tr>
                            </table>
                        </div>
                        <Divider plain style={{ marginBottom: 4 }} />
                        <div className='flex items-end gap-2'>
                            <Select style={{ width: '100%' }} label='Split by' placeholder='Select' value={_splitBy} data={selectByList} onChange={(value) => handleSplitByOnChange(value, transaction)} />
                            <button
                                onClick={() =>
                                    handleAddSplitTransaction(
                                        {
                                            id: uuid,
                                            list: _splitBy === 'category' ? handleRenderCategoryListComponent(_splitBy, transaction, uuid) : handleRenderClassListComponent(_splitBy, transaction, uuid),
                                            vendors: handleRenderVendorListComponent(_splitBy, transaction, uuid),
                                            customers: handleRenderCustomerListComponent(_splitBy, transaction, uuid),
                                            // amount: <Input id={uuid} onBlur={(e) => handleSplitItemOnChange(e.target, 'amount', transaction, _splitBy)} placeholder='Enter amount' />,
                                            amount: (
                                                <CurrencyInput
                                                    placeholder='$0.00'
                                                    type='text'
                                                    style={{ width: '100px', height: '35px', padding: '0 11px', border: '1px solid #d9d9d9', borderRadius: '5px' }}
                                                    id={uuid}
                                                    splitInputType='amount'
                                                    transaction={transaction}
                                                    splitBy={_splitBy}
                                                    callback={handleSplitItemOnChange}
                                                />
                                            ),
                                            button: (
                                                <span className='cursor-pointer' id={uuid} onClick={(e) => handleDeleteSplitItemOnClick(e)}>
                                                    <IconTrash size={24} />
                                                </span>
                                            ),
                                        },
                                        transaction
                                    )
                                }
                                style={{ border: '1px solid #dddddd' }}
                                className='h-[35px] rounded text-sm flex items-center'
                            >
                                <IconPlus size={20} />
                                Add Transaction
                            </button>
                        </div>
                        <div>
                            {_splitTransaction.map((split) => (
                                <div key={split.id} className='flex justify-between items-center gap-2 pt-4'>
                                    {split.list}
                                    <div id={`item-${split.id}`}></div>
                                    {split.amount}
                                    {split.button}
                                </div>
                            ))}
                        </div>
                    </div>
                ),
                body: (
                    <div className='flex justify-between gap-4'>
                        {/* <ClientButton bg='bg-cyan-500' icon='columns' color='text-white' label='Split Transaction' /> */}
                        <ClientButton disabled={!_splitTransaction.length} width='!w-full' bg='bg-cyan-500' icon='save' color='text-white' label='Save Transaction' callback={() => handleSaveSplitTransaction(transaction, _splitTransactions)} />
                    </div>
                ),
                show: true,
            })
        }
    }
    // SPLIT TRANSACTION ENDS HERE

    function handleFilterExcludedAccounts(excludedAccounts, lists) {
        return lists?.filter((list) => !excludedAccounts?.includes(list?.title))
    }

    function handleDrawer(id) {
        if (fetchTransactionType !== id) {
            setPage(1)
            setTransactions([])
            viewContext.setLoading(true)
            setFetchTransactionType(id)
        }
    }

    function handleBulkChangeOption(props) {
        setSelectedBulkOption(props?.value)
    }

    function handleBulkChangeOptionList() {
        const options = permission === 'user' || permission === 'clientStaff' ? bulkChangeOptions.filter((option) => option.id !== '1' && option.id !== '7') : bulkChangeOptions
        if (!getClassLocationStatus?.isClassEnabled && !getClassLocationStatus?.isLocationEnabled) {
            return options.filter((option) => option.id !== '3' && option.id !== '4')
        } else if (!getClassLocationStatus?.isClassEnabled && getClassLocationStatus?.isLocationEnabled) {
            return options.filter((option) => option.id !== '3')
        } else if (!getClassLocationStatus?.isLocationEnabled && getClassLocationStatus?.isClassEnabled) {
            return options.filter((option) => option.id !== '4')
        } else {
            return options
        }
    }

    function handleBulkChangeList() {
        if (selectedBulkOption === '2') {
            return handleFilterExcludedAccounts(companyProfile?.excludedAccounts, lists?.categories)
        } else if (selectedBulkOption === '3') {
            return lists?.classes
        } else if (selectedBulkOption === '4') {
            return lists?.locations
        } else if (selectedBulkOption === '5') {
            return [...lists?.vendors]
        } else if (selectedBulkOption === '6') {
            return [...lists?.customers]
        } else {
            return []
        }
    }

    function handleFormatTransactionsTableData(transactions) {
        const transactionList = {
            header: [
                {
                    name: '',
                    title: <Checkbox admin option='-1' id='-1' callback={(e) => handleCheckAll(e, transactions)} />,
                },
                { name: 'status', title: '' },
                { name: 'vendor', title: 'VENDOR' },
                { name: 'date', title: 'DATE' },
                { name: 'amount', title: '$' },
                { name: 'category', title: 'CATEGORY' },
                { name: 'description', title: 'DESCRIPTION' },
                { name: 'action', title: fetchTransactionType !== 'approved' ? 'ACTION' : '' },
            ].filter(
                (item) =>
                    item.name !== 'status' &&
                    (fetchTransactionType === 'approved' ? item.name !== '' : item) &&
                    ((fetchTransactionType === 'underReview' || fetchTransactionType === 'approved') && (permission === 'user' || permission === 'clientStaff') ? item.name !== 'action' && item.name !== '' : item)
            ),

            body: transactions?.map((transaction) => ({
                '': <Checkbox option={transaction._id} id={transaction._id} value={transaction.transId} callback={(e) => handleCheckActive(e, transaction)} />,
                status: <span>{formatTransactionStatusIcon(transaction?.status)}</span>,
                vendor: (
                    <ClientOption
                        vendor
                        type='vendor'
                        transaction={transaction}
                        id={singleUpdatePayload.find((data) => data._id === transaction?._id)?.vendor?.id || transaction?.vendor?.id}
                        placeholder='Select Vendor'
                        list={[...lists?.vendors]}
                        callback={handleChange}
                        fetchTransactionType={fetchTransactionType}
                        transactions={transactions}
                        permission={permission}
                    />
                ),
                date: (
                    <ClientStyledData bg='bg-gray-100' c='text-gray-500'>
                        {formatDate(transaction.date)}
                    </ClientStyledData>
                ),
                amount: (
                    <ClientStyledData bg={transaction.amount.color.background} c={`${transaction.amount.color.text}`} t={transaction.amount?.tooltip ?? 'Refetch transactions to show the tooltip.'}>
                        {transaction.amount.value}
                    </ClientStyledData>
                ),
                category: (
                    <Tree
                        showSearch
                        allowClear
                        placeholder='Select Category'
                        type='category'
                        value={singleUpdatePayload.find((data) => data?._id === transaction?._id)?.category?.id || transaction?.category?.id}
                        list={handleFilterExcludedAccounts(companyProfile?.excludedAccounts, lists?.categories)}
                        transaction={transaction}
                        fetchTransactionType={fetchTransactionType}
                        callback={handleChange}
                        permission={permission}
                        disabled={(fetchTransactionType !== 'categorizeMe' && (permission === 'user' || permission === 'clientStaff')) || fetchTransactionType === 'approved'}
                    />
                ),
                description: (
                    <ClientTextArea
                        type='description'
                        isTransactionView
                        transaction={transaction}
                        payload={singleUpdatePayload.find((data) => data._id === transaction?._id)?.description}
                        description={transaction?.description}
                        qbo_description={transaction?.qbo_description}
                        memo={transaction?.memo}
                        account={transaction?.account_name?.value}
                        callback={handleChange}
                        fetchTransactionType={fetchTransactionType}
                        permission={permission}
                    >
                        {singleUpdatePayload.find((data) => data._id === transaction?._id)?.description || transaction?.description}
                    </ClientTextArea>
                ),
                action: <ClientActionButtons callbacks={buttonCallbacks} permission={permission} transaction={transaction} isLoading={isUpdateIntuitTransactionLoading} isToggleApproveAllActive={isToggleApproveAllActive} fetchTransactionType={fetchTransactionType} />,
            })),
        }

        transactionList.body.map((prop) => delete prop.status)

        if (fetchTransactionType === 'approved') {
            transactionList.body.map((prop) => delete prop[''])
        }

        if ((fetchTransactionType === 'underReview' || fetchTransactionType === 'approved') && (permission === 'user' || permission === 'clientStaff')) {
            transactionList.body.map((prop) => delete prop[''])
            transactionList.body.map((prop) => delete prop.action)
        }

        return transactionList
    }

    const functions = {
        handleChange,
        handleFilterExcludedAccounts,
        adminCreateList,
        adminAddEntity,
        updateIntuitTransaction,
        handleOnLoadActions,
        handleSaveApprove,
        handleMessage,
        handleAttachment,
        handleDeleteTransaction,
        handleShowSplitTransactionModal,
        handleEditShowSplitTransactionModal,
    }

    const data = {
        companyId,
        companyProfile: getCompanyProfile,
        lists: getLists,
        isAdminCreateListLoading,
        isAdminAddEntityLoading,
        isUpdateIntuitTransactionLoading,
        getClassLocationStatus,
        singleUpdatePayload,
        splitTransactions,
        splitByForEdit: splitBy,
        splitTransactionForEdit: splitTransaction,
        splitItemsPayloadForEdit: splitItemsPayload,
    }

    /* eslint-disable */
    useEffect(() => {
        viewContext.setLoading(true)
        handleResetFilters()
    }, [])

    useEffect(() => {
        if (!view && selectedBulkOption === '7' && checkboxes.length && checkboxes.length < 2) {
            handleShowSplitTransactionModal({ _splitTransaction: splitTransaction, _splitBy: splitBy, _splitItemsPayload: splitItemsPayload, _checkboxes: checkboxes, _transactions: transactions, _splitTransactions: splitTransactions })
        } else if (!view && selectedBulkOption === '7' && checkboxes.length && checkboxes.length > 1) {
            messageApi.open({
                type: 'error',
                content: 'You can only split one transaction at a time.',
            })
        }
    }, [selectedBulkOption, splitTransaction, splitBy, splitItemsPayload, checkboxes, transactions, splitTransactions])

    useEffect(() => {
        const transactionForEdit = JSON.parse(localStorage.getItem('transactionForEdit'))
        if (transactionForEdit) {
            handleEditShowSplitTransactionModal({ _splitBy: splitBy, _splitTransaction: splitTransaction, _splitTransactions: splitTransactions, _splitItemsPayload: splitItemsPayload, _transaction: transactionForEdit })
        }
    }, [selectedBulkOption, splitTransaction, splitBy, splitItemsPayload, checkboxes, splitTransactions])

    useEffect(() => {
        if (!viewContext.modal.data?.show) {
            handleUncheckAllCheckboxes()
            setSplitTransaction([])
            setSplitBy('')
            setSplitItemsPayload([])
            setSplitTransactions(undefined)
            localStorage.removeItem('splitItemsPayload')
            localStorage.removeItem('transactionForEdit')
        }
    }, [viewContext.modal.data?.show])

    window.addEventListener('resize', (e) => {
        if (window.screen.width <= 900) {
            setView(1)
        }
    })

    return (
        <>
            {isLoading ? (
                <Skeleton visible={viewContext.loading} />
            ) : (
                <>
                    <LoadingOverlay visible={buttonLoading} overlayBlur={0.5} loaderProps={{ size: 'lg', color: '#06B6D4' }} />

                    <div>
                        <Drawer
                            items={[
                                { id: 'categorizeMe', label: 'Categorize' },
                                { id: 'underReview', label: 'Review' },
                                { id: 'approved', label: 'Approved' },
                            ]}
                            activeTab={fetchTransactionType}
                            callback={handleDrawer}
                            permission={permission}
                            isAutosaveLoading={isAutosaveLoading}
                            singleUpdatePayload={singleUpdatePayload}
                            handleSendBulkReview={handleSendBulkReview}
                        />

                        {(permission === 'user' || permission === 'clientStaff') && companyProfile?.dashboardNote ? (
                            <div class='relative p-8 overflow-hidden w-full rounded mb-4 flex bg-orange-100'>
                                <svg width='30' height='30' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-triangle float-left mr-6' color='#F0AA61'>
                                    <g>
                                        <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                        <line x1='12' y1='9' x2='12' y2='13'></line>
                                        <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                    </g>
                                </svg>
                                <section class='float-left width-auto overflow-hidden md:float-none md:mt-0'>
                                    <h1 class='text-xl font-semibold m-0 mb-3 text-orange-400'>Accountant Note</h1>
                                    <p>{companyProfile?.dashboardNote}</p>
                                </section>
                            </div>
                        ) : null}

                        {Boolean(companyProfile?.editedTransactions.length) && fetchTransactionType === 'underReview' && (permission === 'user' || permission === 'clientStaff') && (
                            <div className='relative p-8 overflow-hidden w-full rounded mb-4 flex bg-orange-100'>
                                <svg width='30' height='30' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' className='feather feather-alert-triangle float-left mr-6' color='#F0AA61'>
                                    <g>
                                        <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                        <line x1='12' y1='9' x2='12' y2='13'></line>
                                        <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                    </g>
                                </svg>
                                <section className='float-left width-auto overflow-hidden md:float-none md:mt-0'>
                                    <h1 className='text-xl font-semibold m-0 mb-3 text-orange-400'>Accountant Review</h1>
                                    <p>Your Accountant is currently in the process of reviewing these transactions.</p>
                                </section>
                            </div>
                        )}

                        {isToggleViewActive && Boolean(getTransactions?.data.length) && (
                            <div className='hidden md:flex lg:flex justify-center items-center gap-2'>
                                <Tooltip label='Toggle to table view' color='cyan' withArrow>
                                    <span className={!view ? 'cursor-pointer text-cyan-500' : 'cursor-pointer'} onClick={() => setView(0)}>
                                        <Animate type='pop'>
                                            <Icon image='list' size={25} />
                                        </Animate>
                                    </span>
                                </Tooltip>
                                <Tooltip label='Toggle to card view' color='cyan' withArrow>
                                    <span className={view ? 'cursor-pointer text-cyan-500' : 'cursor-pointer'} onClick={() => setView(1)}>
                                        <Animate type='pop'>
                                            <Icon image='columns' size={25} />
                                        </Animate>
                                    </span>
                                </Tooltip>
                            </div>
                        )}

                        {isToggleBulkChangeActive && (
                            <div>
                                <Animate type='pop'>
                                    {contextHolder}
                                    <div className='flex flex-col md:flex-row justify-center items-center gap-2'>
                                        <div className='flex flex-col md:flex-row justify-center items-center gap-2'>
                                            <ClientOption type='category' placeholder='Select action...' list={handleBulkChangeOptionList()} callback={handleBulkChangeOption} />
                                            {selectedBulkOption && selectedBulkOption !== '1' && selectedBulkOption === '2' ? (
                                                <div>
                                                    <Tree showSearch allowClear placeholder='Select Category...' type='category' list={handleBulkChangeList()} fetchTransactionType={fetchTransactionType} callback={handleSelectedListItem} permission={permission} />
                                                </div>
                                            ) : selectedBulkOption && !['1', '7'].includes(selectedBulkOption) ? (
                                                <ClientOption type='category' placeholder={bulkChangeOptions.filter((option) => option.id === selectedBulkOption)[0]?.placeholder} list={handleBulkChangeList()} callback={handleSelectedListItem} />
                                            ) : null}
                                        </div>
                                        <div className='flex justify-center items-center gap-2'>
                                            <ClientButton disabled={selectedBulkOption === '7'} bg={selectedBulkOption === '7' ? 'bg-slate-300' : 'bg-cyan-500'} color='text-white' icon='save' label='Save' callback={handleSaveBulkChange} />
                                            <ClientButton bg='bg-red-500' icon='x-circle' color='text-white' label='Discard' callback={handleUncheckAllCheckboxes} />
                                        </div>
                                    </div>
                                </Animate>
                            </div>
                        )}
                    </div>

                    {getTransactions?.total > 0 && (
                        <Animate type='pop'>
                            {!view ? (
                                <div className='container mx-auto lg:overflow-x-auto lg:mx-0 lg:max-w-none xl:overflow-hidden'>
                                    <div className='p-4 my-6 bg-white shadow rounded xl:overflow-hidden'>
                                        <TableView transactions={handleFormatTransactionsTableData(transactions)} />
                                    </div>
                                </div>
                            ) : (
                                <Row color='tint' align='center' paddingTop={25}>
                                    <section className='mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                                        <AnimatePresence>
                                            {getTransactions?.data.map((transaction) => (
                                                <Card key={transaction?._id} shadow rounded className='mb-0 !p-0'>
                                                    <CardView usage='list' functions={functions} data={data} transaction={transaction} />
                                                </Card>
                                            ))}
                                        </AnimatePresence>
                                    </section>
                                </Row>
                            )}
                            <ClientPagination total={getTransactions?.total} totalPerPage={getTransactions?.totalPerPage} totalResults={getTransactions?.totalResults} />
                        </Animate>
                    )}

                    {!isFetching && getTransactions?.total < 1 && (
                        <div style={{ height: '70vh' }} className='flex justify-center items-center my-6 bg-white shadow rounded text-center lg:overflow-x-auto xl:overflow-hidden'>
                            <h1>No transaction(s) found.</h1>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

function TableView({ transactions }) {
    return <Table transaction data={transactions} />
}

function CardView({ transaction, functions, data, usage }) {
    const authContext = useContext(AuthContext)
    const viewContext = useContext(ViewContext)
    const queryClient = useQueryClient()
    const { handleChange, handleFilterExcludedAccounts, adminCreateList, updateIntuitTransaction, handleSaveApprove, handleMessage, handleAttachment, handleDeleteTransaction, adminAddEntity, handleOnLoadActions, handleEditShowSplitTransactionModal } = functions
    const { isAdminCreateListLoading, isUpdateIntuitTransactionLoading, getClassLocationStatus, /* singleUpdatePayload */ splitByForEdit, splitTransactionForEdit, splitItemsPayloadForEdit } = data
    const {
        lists,
        companyProfile,
        fetchTransactionType,
        transactions,
        buttonLoading,
        setButtonLoading,
        setSelectedBulkOption,
        selectedBulkOption,
        handleSplitTransactionsRequest,
        handleUpdateSplitTransactionsRequest,
        handleApproveSplitTransactionsRequest,
        handleFetchSplitTransactionsRequest,
        splitTransactions,
        setSplitTransactions,
    } = useContext(ApiContext)
    const permission = authContext?.user.permission
    const pathname = window.location.pathname
    const companyId = pathname.split('/')[2]
    // Get the first client admin from the clients array
    const clientAdmin = companyProfile?.clients?.find((client) => client?.permission === 'user')
    const clientId = permission === 'owner' || permission === 'staff' ? clientAdmin?.id : authContext?.user?.accounts[0]?.user_id
    const createListInput = useRef()
    const createListSelect = useRef()
    const [createListEmptyFieldError, setCreateListEmptyFieldError] = useState('')
    const [createListPayload, setCreateListPayload] = useState()
    const [classification, setClassification] = useState('Expense')
    const [accountType, setAccountType] = useState('Expense')
    const [accountSubType, setAccountSubType] = useState('Advertising Promotional')
    const [createListModalTitle, setCreateListModalTitle] = useState('')
    const [createListModalContentType, setCreateListModalContentType] = useState('')
    const [opened, { open, close }] = useDisclosure(false)
    const [vendorValue, setVendorValue] = useState()
    const [customerValue, setCustomerValue] = useState()
    const [categoryValue, setCategoryValue] = useState()
    const [classValue, setClassValue] = useState()
    const [locationValue, setLocationValue] = useState()
    const [descriptionValue, setDescriptionValue] = useState()
    const [messageApi, contextHolder] = message.useMessage()
    const [openSplitModal, setOpenSplitModal] = useState(false)
    const [splitItemsPayload, setSplitItemsPayload] = useState([])
    const [splitTransaction, setSplitTransaction] = useState([])
    const [splitBy, setSplitBy] = useState('')
    const singleUpdatePayload = JSON.parse(localStorage.getItem('singleUpdatePayload')) || []

    const isUserAllowedToAddNewList = (property) => permission === 'owner' || permission === 'staff' || (permission === 'user' && companyProfile?.[property]?.includes(clientId))

    const InputLabel = (props) => (
        <p className='text-bold'>
            <span className='text-red-500'>*</span> {props?.label}
        </p>
    )

    function handleAddList(type) {
        setCreateListModalContentType(type)
        type === 'vendor' && setCreateListModalTitle('Create a New Vendor')
        type === 'customer' && setCreateListModalTitle('Create a New Customer')
        type === 'category' && setCreateListModalTitle('Create a New Account/Category')
        type === 'class' && setCreateListModalTitle('Create a New Class')
        type === 'location' && setCreateListModalTitle('Create a New Department/Location')
        open()
    }

    function handleCreateListTextInputOnChange(type, value) {
        setCreateListEmptyFieldError('')
        let data = {
            type,
        }
        if (type === 'vendor' || type === 'customer') {
            data.payload = {
                CompanyName: value,
                DisplayName: value,
            }
        } else if (type === 'category') {
            data.payload = {
                Name: value,
                Classification: classification,
                AccountType: accountType,
                AccountSubType: accountSubType.split(' ').join(''),
            }
        } else if (type === 'class' || type === 'location') {
            data.payload = {
                Name: value,
            }
        }
        setCreateListPayload(data)
    }

    async function handleCreateListOnClick(e, transaction) {
        setButtonLoading(true)

        if (!createListInput?.current.value) {
            setCreateListEmptyFieldError('Required')
            return
        }

        let data = {
            companyId,
            transId: transaction?.transId,
            transType: transaction?.transType,
            lineId: transaction?.lineId,
            ...createListPayload,
        }

        if (createListPayload?.type === 'vendor') {
            const adminCreateListResponse = await adminCreateList({
                companyId,
                ...createListPayload,
            })
            await adminAddEntity({
                transId: transaction?.transId,
                lineId: transaction?.lineId,
                companyId,
                type: createListPayload?.type,
                entity: `${createListPayload?.type}s`,
                property: 'vendor',
                data: {
                    id: adminCreateListResponse?.Id,
                    value: adminCreateListResponse?.CompanyName,
                },
            })
            // await updateIntuitTransaction({
            //     ...data,
            //     vendor: {
            //         id: adminCreateListResponse?.Id,
            //         value: adminCreateListResponse?.CompanyName,
            //     },
            // })
            handleCardViewChange({
                transaction,
                value: adminCreateListResponse?.Id,
                type: 'vendor',
                usage,
                transactions,
            })
            handleSetSingleUpdatePayload(transaction, 'vendor', {
                id: adminCreateListResponse?.Id,
                value: adminCreateListResponse?.CompanyName,
                type: createListPayload?.type,
            })
        } else if (createListPayload?.type === 'customer') {
            const adminCreateListResponse = await adminCreateList({
                companyId,
                ...createListPayload,
            })
            await adminAddEntity({
                transId: transaction?.transId,
                lineId: transaction?.lineId,
                companyId,
                type: createListPayload?.type,
                entity: `${createListPayload?.type}s`,
                property: 'customer',
                data: {
                    id: adminCreateListResponse?.Id,
                    value: adminCreateListResponse?.CompanyName,
                },
            })
            // await updateIntuitTransaction({
            //     ...data,
            //     customer: {
            //         id: adminCreateListResponse?.Id,
            //         value: adminCreateListResponse?.CompanyName,
            //     },
            // })
            handleCardViewChange({
                transaction,
                value: adminCreateListResponse?.Id,
                type: 'customer',
                usage,
                transactions,
            })
            handleSetSingleUpdatePayload(transaction, 'customer', {
                id: adminCreateListResponse?.Id,
                value: adminCreateListResponse?.CompanyName,
                type: createListPayload?.type,
            })
        } else if (createListPayload?.type === 'category') {
            const adminCreateCategoryResponse = await adminCreateList({
                companyId,
                type: createListPayload?.type,
                payload: {
                    Name: createListPayload?.payload.Name,
                    AccountType: accountType,
                    AccountSubType: accountSubType.split(' ').join(''),
                },
            })

            await adminAddEntity({
                transId: transaction?.transId,
                lineId: transaction?.lineId,
                companyId,
                entity: 'categories',
                property: 'category',
                data: {
                    value: adminCreateCategoryResponse?.Id,
                    title: adminCreateCategoryResponse?.Name,
                    children: [],
                },
            })
            // await updateIntuitTransaction({
            //     ...data,
            //     category: {
            //         id: adminCreateCategoryResponse?.Id,
            //         value: adminCreateCategoryResponse?.Name,
            //     },
            //     isCreateCategory: true,
            // })
            handleCardViewChange({
                transaction,
                value: adminCreateCategoryResponse?.Id,
                type: createListPayload?.type,
                usage,
                isCreateCategory: true,
            })
            handleSetSingleUpdatePayload(transaction, createListPayload?.type, {
                id: adminCreateCategoryResponse?.Id,
                value: adminCreateCategoryResponse?.Name,
            })
        } else if (createListPayload?.type === 'class' || createListPayload?.type === 'location') {
            const adminCreateListResponse = await adminCreateList({
                companyId,
                ...createListPayload,
            })
            await adminAddEntity({
                transId: transaction?.transId,
                lineId: transaction?.lineId,
                companyId,
                entity: `${createListPayload?.type === 'class' ? 'classes' : 'locations'}`,
                property: createListPayload?.type,
                data: {
                    id: adminCreateListResponse?.Id,
                    value: adminCreateListResponse?.Name,
                },
            })
            // await updateIntuitTransaction({
            //     ...data,
            //     [createListPayload?.type]: {
            //         id: adminCreateListResponse?.Id,
            //         value: adminCreateListResponse?.Name,
            //     },
            // })
            handleCardViewChange({
                transaction,
                value: adminCreateListResponse?.Id,
                type: createListPayload?.type,
                usage,
            })
            handleSetSingleUpdatePayload(transaction, createListPayload?.type, {
                id: adminCreateListResponse?.Id,
                value: adminCreateListResponse?.Name,
            })
        }

        if (!isUpdateIntuitTransactionLoading) {
            setButtonLoading(false)
            close()
            UpdateNotification({
                title: 'Create list',
                message: `New ${createListInput?.current.name} has been successfully created.`,
            })
            // viewContext.modal.hide()
        }
    }

    function handleSetSingleUpdatePayload(transaction, type, newData) {
        const transType = type === 'vendor' || type === 'customer' ? transaction[type]?.type : null
        const payload = JSON.parse(localStorage.getItem('singleUpdatePayload'))
        let __payload
        if (payload?.length) {
            const othersPayloads = payload.filter((data) => data._id !== transaction?._id)
            let updatedPayload = payload.find((data) => data._id === transaction?._id && data.companyId === companyId)
            if (updatedPayload) {
                updatedPayload[`${type}`] = newData
                __payload = [...othersPayloads, updatedPayload]
            } else {
                const newPayload = {
                    _id: transaction?._id,
                    companyId,
                    transId: transaction?.transId,
                    transType: transaction?.transType,
                    lineId: transaction?.lineId,
                    [`${type}`]: newData,
                }
                __payload = [...othersPayloads, newPayload]
            }
        } else {
            let data = []
            data.push({
                _id: transaction?._id,
                companyId,
                transId: transaction?.transId,
                transType: transaction?.transType,
                lineId: transaction?.lineId,
                [`${type}`]: newData,
            })
            __payload = data
        }
        localStorage.setItem('singleUpdatePayload', JSON.stringify(__payload))
    }

    function handleCategoryOnChange(type, value) {
        if (type === 'classification') {
            setClassification(value)
            setAccountType('')
            setAccountSubType('')
        } else if (type === 'accountType') {
            setAccountType(value)
            setAccountSubType('')
        } else if (type === 'accountSubType') {
            setAccountSubType(value)
        }
    }

    function handleCardViewChange(props) {
        const { transaction, transactions, value, type, usage, isCreateCategory } = props
        handleChange({ transaction, transactions, value, type, usage, isCreateCategory })
        type === 'vendor' && setVendorValue(value)
        type === 'customer' && setCustomerValue(value)
        type === 'category' && setCategoryValue(value)
        type === 'class' && setClassValue(value)
        type === 'location' && setLocationValue(value)
    }

    function handleSplitItemOnChange(element, type, transaction, _splitBy) {
        // if (type === 'amount') {
        //     // console.log(+element.value)
        //     // console.log(element.value)
        //     if (+element.value < 1 && !isNaN(+element.value)) {
        //         const val = element.value?.split('.')
        //         if (val?.length === 2 && (!val[1][1] || val[1][1] === '0')) {
        //             if (+element.value < 0) {
        //                 element.value = `-${Math.abs(element.value)}0`
        //             } else {
        //                 element.value = `${Math.abs(element.value)}0`
        //             }
        //         } else {
        //             if (+element.value < 0) {
        //                 element.value = `-${Math.abs(element.value)}`
        //             } else {
        //                 element.value = `${Math.abs(element.value)}`
        //             }
        //         }
        //     } else if (+element.value >= 1 && !isNaN(+element.value)) {
        //         if (+element.value < 0) {
        //             element.value = `-${Math.abs(element.value)}0`
        //         } else {
        //             // element.value = `${Math.abs(element.value)}0`
        //             const val = element.value?.split('.')
        //             if (val?.length === 2 && (!val[1][1] || val[1][1] === '0')) {
        //                 if (+element.value < 0) {
        //                     element.value = `-${Math.abs(element.value)}0`
        //                 } else {
        //                     if (val[1]) {
        //                         element.value = `${Math.abs(element.value)}0`
        //                     } else {
        //                         element.value = val[0]
        //                     }
        //                 }
        //             } else {
        //                 if (+element.value < 0) {
        //                     element.value = `-${Math.abs(element.value)}`
        //                 } else {
        //                     element.value = `${Math.abs(element.value)}`
        //                 }
        //             }
        //         }
        //     } else {
        //         element.value = 0
        //     }
        // }
        let value = element.value
        if (type === 'amount') {
            value = element.value ? +element.value.replace('$', '') : 0
        }
        if (type === 'list' && _splitBy === 'category') {
            const splitItemElement = document.getElementById(`item-${element.id}`)
            const splitItemElementRoot = createRoot(splitItemElement)
            if (transaction?.transType === 'Purchase') {
                if (element.value && element.value.toLowerCase().includes('accounts receivable')) {
                    splitItemElementRoot.render(handleRenderCustomerListComponent(element.id))
                } else {
                    if (splitItemElement.firstChild) {
                        splitItemElement.removeChild(splitItemElement.firstChild)
                    }
                }
            } else if (transaction?.transType === 'Deposit') {
                if (element.value && element.value.toLowerCase().includes('accounts receivable')) {
                    splitItemElementRoot.render(handleRenderCustomerListComponent(element.id))
                } else if (element.value && element.value?.toLowerCase().includes('accounts payable')) {
                    splitItemElementRoot.render(handleRenderVendorListComponent(element.id))
                } else {
                    if (splitItemElement.firstChild) {
                        splitItemElement.removeChild(splitItemElement.firstChild)
                    }
                }
            }
        }
        const splitItemsPayloadStorage = JSON.parse(localStorage.getItem('splitItemsPayload'))
        if (splitItemsPayloadStorage) {
            const otherItems = splitItemsPayloadStorage.filter((item) => item.id !== element.id)
            let existingItem = splitItemsPayloadStorage.find((item) => item.id === element.id)
            if (existingItem) {
                if (type === 'list' && _splitBy === 'category' && !['Accounts Receivable (A/R)', 'Accounts Receivable', 'Accounts Payable (A/P)', 'Accounts Payable'].includes(value)) {
                    delete existingItem?.vendorcustomer
                }
                existingItem[type] = value
                localStorage.setItem('splitItemsPayload', JSON.stringify([...otherItems, existingItem]))
            } else {
                localStorage.setItem('splitItemsPayload', JSON.stringify([...otherItems, { id: element.id, type: _splitBy, [type]: value }]))
            }
        } else {
            localStorage.setItem('splitItemsPayload', JSON.stringify([{ id: element.id, type: _splitBy, [type]: value }]))
        }
    }

    function handleDeleteSplitItemOnClick(e) {
        setSplitTransaction((prev) => prev.filter((item) => item?.id !== e.currentTarget?.id))
        if (JSON.parse(localStorage.getItem('splitItemsPayload'))) {
            const filteredSplitItems = JSON.parse(localStorage.getItem('splitItemsPayload')).filter((item) => item.id !== e.currentTarget.id)
            localStorage.setItem('splitItemsPayload', JSON.stringify(filteredSplitItems))
        }
    }

    function handleAddSplitTransaction({ id, list, amount, button }, transaction) {
        if (!splitBy) {
            return messageApi.open({
                type: 'error',
                content: 'Split by must be selected.',
            })
        }
        setSplitTransaction((prev) => [
            ...prev,
            {
                id,
                list,
                amount,
                button,
            },
        ])
    }

    async function handleSaveSplitTransaction(transaction, _splitTransactions) {
        const splitItemsPayloadStorage = JSON.parse(localStorage.getItem('splitItemsPayload'))
        const updatePayload = JSON.parse(localStorage.getItem('singleUpdatePayload'))
        const editedTransaction = updatePayload?.find((payload) => payload?._id === transaction?._id)
        if (splitItemsPayloadStorage) {
            splitItemsPayloadStorage.sort((a, b) => a.id - b.id)
            if (!splitItemsPayloadStorage?.length) {
                return messageApi.open({
                    type: 'error',
                    content: `Type and amount are required.`,
                })
            }
            for (const item of splitItemsPayloadStorage) {
                if (!item?.amount) {
                    return messageApi.open({
                        type: 'error',
                        content: 'Enter split amount.',
                    })
                }
                if (isNaN(item?.amount)) {
                    return messageApi.open({
                        type: 'error',
                        content: 'Amount must be a number.',
                    })
                }
                if (!item?.list || !item.amount) {
                    return messageApi.open({
                        type: 'error',
                        content: `Type and amount are required.`,
                    })
                }
                if (transaction?.transType === 'Purchase') {
                    if (item?.list && item?.list?.toLowerCase().includes('accounts receivable') && !item?.vendorcustomer) {
                        return messageApi.open({
                            type: 'error',
                            content: `Category is required for Accounts Receivable category.`,
                        })
                    }
                } else if (transaction?.transType === 'Deposit') {
                    if (item?.list && item?.list?.toLowerCase().includes('accounts receivable') && !item?.vendorcustomer) {
                        return messageApi.open({
                            type: 'error',
                            content: `Category is required for Accounts Receivable category.`,
                        })
                    } else if (item?.list && item?.list?.toLowerCase().includes('accounts payable') && !item?.vendorcustomer) {
                        return messageApi.open({
                            type: 'error',
                            content: `Vendor is required for Accounts Payable category.`,
                        })
                    }
                }
            }

            const totalSplitAmount = splitItemsPayloadStorage.reduce((acc, item) => acc + (item.amount ? +item.amount : 0), 0)

            if (totalSplitAmount > +transaction?.amount.value) {
                return messageApi.open({
                    type: 'error',
                    content: `Split amount exceeded from the original amount of ${transaction?.amount.value}.`,
                })
            } else if (totalSplitAmount < +transaction?.amount.value) {
                return messageApi.open({
                    type: 'error',
                    content: `The total split amount must be equal to $${transaction?.amount.value}.`,
                })
            }

            const LineItems = _splitTransactions?.filter((t) => t.Id !== transaction?.lineId)
            const detailType = transaction.transType === 'Deposit' ? 'DepositLineDetail' : 'AccountBasedExpenseLineDetail'

            if (+transaction?.amount.value - totalSplitAmount) {
                let base = _splitTransactions.find((t) => t.Id === transaction?.lineId)
                base.Id = transaction?.lineId
                if (base.LineNum) {
                    base.LineNum = transaction?.lineNum
                }
                base.Amount = +transaction?.amount.value - totalSplitAmount
                base.Description = editedTransaction?.description || transaction?.description
                LineItems.push(base)
            } else {
                let base = _splitTransactions.find((t) => t.Id === transaction?.lineId)
                base.Id = transaction?.lineId
                if (base.LineNum) {
                    base.LineNum = transaction?.lineNum
                }
                base.Amount = +splitItemsPayloadStorage[0]?.amount
                base.Description = editedTransaction?.description || transaction?.description
                if (splitItemsPayloadStorage[0].type === 'category') {
                    base[detailType]['AccountRef'] = {
                        value: lists?.allCategories?.find((i) => i.title === splitItemsPayloadStorage[0].list)?.value,
                        name: splitItemsPayloadStorage[0].list,
                    }
                    if (transaction?.transType === 'Purchase') {
                        if (splitItemsPayloadStorage[0]?.vendorcustomer && splitItemsPayloadStorage[0]?.list && splitItemsPayloadStorage[0]?.list?.toLowerCase().includes('accounts receivable')) {
                            base[detailType]['CustomerRef'] = {
                                value: lists?.customers?.find((i) => i.value === splitItemsPayloadStorage[0]?.vendorcustomer)?.id,
                                name: splitItemsPayloadStorage[0]?.vendorcustomer,
                            }
                        }
                    } else if (transaction?.transType === 'Deposit') {
                        if (splitItemsPayloadStorage[0]?.vendorcustomer && splitItemsPayloadStorage[0]?.list && splitItemsPayloadStorage[0]?.list?.toLowerCase().includes('accounts receivable')) {
                            base[detailType]['Entity'] = {
                                value: lists?.customers?.find((i) => i.value === splitItemsPayloadStorage[0]?.vendorcustomer)?.id,
                                name: splitItemsPayloadStorage[0]?.vendorcustomer,
                                type: 'CUSTOMER',
                            }
                        } else if (splitItemsPayloadStorage[0]?.vendorcustomer && splitItemsPayloadStorage[0]?.list && splitItemsPayloadStorage[0]?.list?.toLowerCase().includes('accounts payable')) {
                            base[detailType]['Entity'] = {
                                value: lists?.vendors?.find((i) => i.value === splitItemsPayloadStorage[0]?.vendorcustomer)?.id,
                                name: splitItemsPayloadStorage[0]?.vendorcustomer,
                                type: 'VENDOR',
                            }
                        }
                    }
                } else if (splitItemsPayloadStorage[0].type === 'class') {
                    base[detailType]['ClassRef'] = {
                        value: lists?.classes?.find((i) => i.value === splitItemsPayloadStorage[0].list)?.id,
                        name: splitItemsPayloadStorage[0].list,
                    }
                }
                LineItems.push(base)
                splitItemsPayloadStorage.splice(0, 1)
            }

            for (const item of splitItemsPayloadStorage) {
                if (item.type === 'category') {
                    if (transaction?.transType === 'Purchase') {
                        if (item?.vendorcustomer && item?.list && item?.list?.toLowerCase().includes('accounts receivable')) {
                            LineItems.push({
                                Description: editedTransaction?.description || transaction?.description,
                                Amount: +item.amount,
                                DetailType: detailType,
                                [detailType]: {
                                    AccountRef: {
                                        value: lists?.allCategories?.find((i) => i.title === item.list)?.value,
                                        name: item.list,
                                    },
                                    CustomerRef: {
                                        value: lists?.customers?.find((i) => i.value === item?.vendorcustomer)?.id,
                                        name: item?.vendorcustomer,
                                    },
                                },
                            })
                        } else {
                            LineItems.push({
                                Description: editedTransaction?.description || transaction?.description,
                                Amount: +item.amount,
                                DetailType: detailType,
                                [detailType]: {
                                    AccountRef: {
                                        value: lists?.allCategories?.find((i) => i.title === item.list)?.value,
                                        name: item.list,
                                    },
                                },
                            })
                        }
                    } else if (transaction?.transType === 'Deposit') {
                        if (item?.vendorcustomer && item?.list && item?.list?.toLowerCase().includes('accounts receivable')) {
                            LineItems.push({
                                Description: editedTransaction?.description || transaction?.description,
                                Amount: +item.amount,
                                DetailType: detailType,
                                [detailType]: {
                                    AccountRef: {
                                        value: lists?.allCategories?.find((i) => i.title === item.list)?.value,
                                        name: item.list,
                                    },
                                    Entity: {
                                        value: lists?.customers?.find((i) => i.value === item?.vendorcustomer)?.id,
                                        name: item?.vendorcustomer,
                                        type: 'CUSTOMER',
                                    },
                                },
                            })
                        } else if (item?.vendorcustomer && item?.list && item?.list?.toLowerCase().includes('accounts payable')) {
                            LineItems.push({
                                Description: editedTransaction?.description || transaction?.description,
                                Amount: +item.amount,
                                DetailType: detailType,
                                [detailType]: {
                                    AccountRef: {
                                        value: lists?.allCategories?.find((i) => i.title === item.list)?.value,
                                        name: item.list,
                                    },
                                    Entity: {
                                        value: lists?.vendors?.find((i) => i.value === item?.vendorcustomer)?.id,
                                        name: item?.vendorcustomer,
                                        type: 'VENDOR',
                                    },
                                },
                            })
                        } else {
                            LineItems.push({
                                Description: editedTransaction?.description || transaction?.description,
                                Amount: +item.amount,
                                DetailType: detailType,
                                [detailType]: {
                                    AccountRef: {
                                        value: lists?.allCategories?.find((i) => i.title === item.list)?.value,
                                        name: item.list,
                                    },
                                },
                            })
                        }
                    }
                } else if (item.type === 'class') {
                    LineItems.push({
                        Description: editedTransaction?.description || transaction?.description,
                        Amount: +item.amount,
                        DetailType: detailType,
                        [detailType]: {
                            AccountRef: {
                                value: transaction?.category.id,
                                name: transaction?.category.name,
                            },
                            ClassRef: {
                                value: lists?.classes?.find((i) => i.value === item.list)?.id,
                                name: item.list,
                            },
                        },
                    })
                }
            }
            const payload = {
                companyId,
                transType: transaction?.transType,
                transId: transaction?.transId,
                splitItems: LineItems,
            }
            viewContext.setLoading(true)
            viewContext.modal.hide(true)
            setSelectedBulkOption('')
            if (fetchTransactionType === 'categorizeMe') {
                const splitTransactionsResponseObject = await handleSplitTransactionsRequest(payload)
                const newSplitItems = splitTransactionsResponseObject.Line || []
                const newSplitItemsPayload = newSplitItems
                    .map((i) => {
                        delete transaction.createdAt
                        delete transaction.updatedAt
                        return {
                            ...transaction,
                            lineId: i.Id,
                            status: 'No Action Needed',
                            amount: {
                                value: i.Amount || transaction.amount.value,
                                color: transaction.amount.color,
                                tooltip: transaction.amount.tooltip,
                            },
                            description: i.Description || transaction?.description,
                            vendor:
                                transaction?.transType === 'Purchase'
                                    ? {
                                          id: splitTransactionsResponseObject?.EntityRef?.value || transaction?.vendor?.id || '',
                                          value: splitTransactionsResponseObject?.EntityRef?.name || transaction?.vendor?.value || '',
                                          type: splitTransactionsResponseObject?.EntityRef?.type || transaction?.vendor?.type?.toUpperCase(),
                                      }
                                    : transaction?.transType === 'Deposit'
                                    ? {
                                          id: i[i.DetailType]?.Entity?.value || transaction?.vendor?.id || '',
                                          value: i[i.DetailType]?.Entity?.name || transaction?.vendor?.value || '',
                                          type: i[i.DetailType]?.Entity?.type || transaction?.vendor?.type?.toUpperCase(),
                                      }
                                    : transaction?.transType === 'JournalEntry'
                                    ? {
                                          id: i[i.DetailType]?.Entity?.EntityRef?.value || transaction?.vendor?.id || '',
                                          value: i[i.DetailType]?.Entity?.EntityRef?.name || transaction?.vendor?.value || '',
                                          type: i[i.DetailType]?.Entity?.Type || transaction?.vendor?.type?.charAt(0).toUpperCase() + transaction?.vendor?.type.slice(1),
                                      }
                                    : {
                                          id: transaction?.vendor?.id || '',
                                          value: transaction?.vendor?.value || '',
                                          type: transaction?.vendor?.type,
                                      },
                            customer:
                                transaction?.transType === 'Purchase'
                                    ? {
                                          id: i[i.DetailType]?.CustomerRef?.value || transaction?.customer?.id || '',
                                          value: i[i.DetailType]?.CustomerRef?.name || transaction?.customer?.value || '',
                                      }
                                    : transaction?.transType === 'Deposit'
                                    ? {
                                          id: i[i.DetailType]?.Entity?.value || transaction?.customer?.id || '',
                                          value: i[i.DetailType]?.Entity?.name || transaction?.customer?.value || '',
                                          type: i[i.DetailType]?.Entity?.type || transaction?.customer?.type?.toUpperCase(),
                                      }
                                    : transaction?.transType === 'JournalEntry'
                                    ? {
                                          id: i[i.DetailType]?.Entity?.EntityRef?.value || transaction?.customer?.id || '',
                                          value: i[i.DetailType]?.Entity?.EntityRef?.name || transaction?.customer?.value || '',
                                          type: i[i.DetailType]?.Entity?.Type || transaction?.customer?.type?.charAt(0).toUpperCase() + transaction?.customer?.type.slice(1),
                                      }
                                    : {
                                          id: transaction?.customer?.id || '',
                                          value: transaction?.customer?.value || '',
                                          type: transaction?.customer?.type,
                                      },
                            category: {
                                id: i[i.DetailType]?.AccountRef?.value || transaction?.category?.id || '',
                                value: i[i.DetailType]?.AccountRef?.name || transaction?.category?.value || '',
                            },
                            class: {
                                id: i[i.DetailType]?.ClassRef?.value || transaction?.class?.id || '',
                                value: i[i.DetailType]?.ClassRef?.name || transaction?.class?.value || '',
                                status: transaction?.class?.status || false,
                            },
                        }
                    })
                    .filter((i) => !companyProfile?.intuitSyncedAccounts.some((a) => i.category.value.includes(a)))
                if (newSplitItemsPayload?.length) {
                    await handleApproveSplitTransactionsRequest({ companyId, data: newSplitItemsPayload })
                }
                localStorage.setItem('refreshTransactions', true)
                queryClient.invalidateQueries(['transactions'])
            } else if (fetchTransactionType === 'underReview') {
                const excludedTransItems = _splitTransactions.filter((t) => t.Id !== transaction.lineId).map((t) => t.Id) ?? []
                const splitTransactionsResponseObject = await handleSplitTransactionsRequest(payload)
                const newSplitItems = splitTransactionsResponseObject.Line || []
                // newSplitItems.shift()
                const newSplitItemsPayload = newSplitItems
                    .map((i) => {
                        delete transaction._id
                        delete transaction.createdAt
                        delete transaction.updatedAt
                        return {
                            ...transaction,
                            lineId: i.Id,
                            status: 'Needs Review',
                            amount: {
                                value: i.Amount || transaction.amount.value,
                                color: transaction.amount.color,
                                tooltip: transaction.amount.tooltip,
                            },
                            description: i.Description || transaction?.description,
                            vendor:
                                transaction?.transType === 'Purchase'
                                    ? {
                                          id: splitTransactionsResponseObject?.EntityRef?.value || transaction?.vendor?.id || '',
                                          value: splitTransactionsResponseObject?.EntityRef?.name || transaction?.vendor?.value || '',
                                          type: splitTransactionsResponseObject?.EntityRef?.type || transaction?.vendor?.type?.toUpperCase(),
                                      }
                                    : transaction?.transType === 'Deposit'
                                    ? {
                                          id: i[i.DetailType]?.Entity?.value || transaction?.vendor?.id || '',
                                          value: i[i.DetailType]?.Entity?.name || transaction?.vendor?.value || '',
                                          type: i[i.DetailType]?.Entity?.type || transaction?.vendor?.type?.toUpperCase(),
                                      }
                                    : transaction?.transType === 'JournalEntry'
                                    ? {
                                          id: i[i.DetailType]?.Entity?.EntityRef?.value || transaction?.vendor?.id || '',
                                          value: i[i.DetailType]?.Entity?.EntityRef?.name || transaction?.vendor?.value || '',
                                          type: i[i.DetailType]?.Entity?.Type || transaction?.vendor?.type?.charAt(0).toUpperCase() + transaction?.vendor?.type.slice(1),
                                      }
                                    : {
                                          id: transaction?.vendor?.id || '',
                                          value: transaction?.vendor?.value || '',
                                          type: transaction?.vendor?.type,
                                      },
                            customer:
                                transaction?.transType === 'Purchase'
                                    ? {
                                          id: i[i.DetailType]?.CustomerRef?.value || transaction?.customer?.id || '',
                                          value: i[i.DetailType]?.CustomerRef?.name || transaction?.customer?.value || '',
                                      }
                                    : transaction?.transType === 'Deposit'
                                    ? {
                                          id: i[i.DetailType]?.Entity?.value || transaction?.customer?.id || '',
                                          value: i[i.DetailType]?.Entity?.name || transaction?.customer?.value || '',
                                          type: i[i.DetailType]?.Entity?.type || transaction?.customer?.type?.toUpperCase(),
                                      }
                                    : transaction?.transType === 'JournalEntry'
                                    ? {
                                          id: i[i.DetailType]?.Entity?.EntityRef?.value || transaction?.customer?.id || '',
                                          value: i[i.DetailType]?.Entity?.EntityRef?.name || transaction?.customer?.value || '',
                                          type: i[i.DetailType]?.Entity?.Type || transaction?.customer?.type?.charAt(0).toUpperCase() + transaction?.customer?.type.slice(1),
                                      }
                                    : {
                                          id: transaction?.customer?.id || '',
                                          value: transaction?.customer?.value || '',
                                          type: transaction?.customer?.type,
                                      },
                            category: {
                                id: i[i.DetailType]?.AccountRef?.value || transaction?.category?.id || '',
                                value: i[i.DetailType]?.AccountRef?.name || transaction?.category?.value || '',
                            },
                            class: {
                                id: i[i.DetailType]?.ClassRef?.value || transaction?.class?.id || '',
                                value: i[i.DetailType]?.ClassRef?.name || transaction?.class?.value || '',
                                status: transaction?.class?.status || false,
                            },
                        }
                    })
                    .filter((t) => !excludedTransItems.includes(t.lineId))
                const lineIds = newSplitItemsPayload.map((t) => t.lineId)
                await handleUpdateSplitTransactionsRequest({ companyId, transId: transaction?.transId, lineIds, data: newSplitItemsPayload })
                localStorage.setItem('refreshTransactions', true)
                queryClient.invalidateQueries(['transactions'])
            }
        }
    }

    function handleVendorCustomerOnChange(value, uuid) {
        const splitItemsPayloadStorage = JSON.parse(localStorage.getItem('splitItemsPayload'))
        const editedSplitItemPayload = splitItemsPayloadStorage.map((payload) => {
            if (payload.id === uuid) {
                return {
                    ...payload,
                    vendorcustomer: value,
                }
            }
            return payload
        })
        localStorage.setItem('splitItemsPayload', JSON.stringify(editedSplitItemPayload))
    }

    function handleRenderVendorListComponent(uuid) {
        return (
            <Select
                searchable
                style={{ width: '100%' }}
                placeholder='Vendor'
                data={lists?.vendors.map((data) => {
                    return {
                        value: data.id,
                        label: data.value,
                        id: uuid,
                    }
                })}
                id={uuid}
                onBlur={(e) => handleVendorCustomerOnChange(e.target.value, uuid)}
            />
        )
    }

    function handleRenderCustomerListComponent(uuid) {
        return (
            <Select
                searchable
                style={{ width: '100%' }}
                placeholder='Customer'
                data={lists?.customers.map((data) => {
                    return {
                        value: data.id,
                        label: data.value,
                        id: uuid,
                    }
                })}
                id={uuid}
                onBlur={(e) => handleVendorCustomerOnChange(e.target.value, uuid)}
            />
        )
    }

    function handleRenderCategoryListComponent(_splitBy, transaction, uuid) {
        return (
            <TreeSelect
                showSearch
                allowClear
                // multiple
                treeNodeFilterProp='title'
                placeholder='Select Category'
                type='category'
                treeData={handleFilterExcludedAccounts(companyProfile?.excludedAccounts, lists?.categories)?.map((d) => ({ ...d, id: uuid }))}
                style={{
                    width: '80%',
                    // minWidth: '180px',
                    height: '36px',
                    borderRadius: '4px',
                    borderColor: '#ced4da',
                }}
                onSelect={(val, options) => handleSplitItemOnChange({ value: options.title, id: options?.id ?? uuid }, 'list', transaction, _splitBy)}
            />
        )
    }

    function handleRenderClassListComponent(_splitBy, transaction, uuid) {
        return (
            <Select
                searchable
                style={{ width: '80%' }}
                // placeholder={`Select ${splitBy.charAt(0).toUpperCase() + splitBy.slice(1)}`}
                placeholder='Select Class'
                data={lists?.classes.map((data) => {
                    return {
                        value: data.id,
                        label: data.value,
                        id: uuid,
                    }
                })}
                id={uuid}
                onSelect={(e) => handleSplitItemOnChange(e.target, 'list', transaction, _splitBy)}
            />
        )
    }

    function handleSplitByOnChange(_splitBy, transaction) {
        localStorage.setItem('splitItemsPayload', JSON.stringify([]))
        setSplitTransaction([])
        setSplitBy(_splitBy)
    }

    async function handleShowSplitTransactionModal({ _splitBy, _splitTransaction, _splitTransactions, _splitItemsPayload, _transaction }) {
        const uuid = uuidv4()
        const transaction = _transaction
        if (!_splitTransactions) {
            await handleFetchSplitTransactionsRequest({ companyId, transType: transaction?.transType, transId: transaction?.transId })
        }
        const selectByList = [{ value: 'category', label: 'Category' }]
        if (companyProfile?.isClassEnabled) {
            selectByList.push({ value: 'class', label: 'Class' })
        }
        if (permission === 'owner' || permission === 'staff') {
            viewContext.modal.show({
                title: 'Split Transaction',
                text: (
                    <div>
                        {contextHolder}
                        <div>
                            <table className='w-full border-collapse'>
                                <thead>
                                    <tr>
                                        <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Category</th>
                                        <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Class</th>
                                        <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Amount</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td style={{ border: '1px solid #dddddd', padding: '8px', borderWidth: 'unset!important' }}>{transaction?.category.value || '--'}</td>
                                    <td style={{ border: '1px solid #dddddd', padding: '8px', borderWidth: 'unset!important' }}>{transaction?.class.value || '--'}</td>
                                    <td style={{ border: '1px solid #dddddd', padding: '8px', borderWidth: 'unset!important' }}>{`$${transaction?.amount.value}`}</td>
                                </tr>
                            </table>
                        </div>
                        <Divider plain style={{ marginBottom: 4 }} />
                        <div className='flex items-end gap-2'>
                            <Select style={{ width: '100%' }} label='Split by' placeholder='Select' value={_splitBy} data={selectByList} onChange={(value) => handleSplitByOnChange(value, transaction)} />
                            <button
                                onClick={() =>
                                    handleAddSplitTransaction(
                                        {
                                            id: uuid,
                                            list: _splitBy === 'category' ? handleRenderCategoryListComponent(_splitBy, transaction, uuid) : handleRenderClassListComponent(_splitBy, transaction, uuid),
                                            vendors: handleRenderVendorListComponent(_splitBy, transaction, uuid),
                                            customers: handleRenderCustomerListComponent(_splitBy, transaction, uuid),
                                            // amount: <Input id={uuid} onBlur={(e) => handleSplitItemOnChange(e.target, 'amount', transaction, _splitBy)} placeholder='Enter amount' />,
                                            amount: (
                                                <CurrencyInput
                                                    placeholder='$0.00'
                                                    type='text'
                                                    style={{ width: '100px', height: '35px', padding: '0 11px', border: '1px solid #d9d9d9', borderRadius: '5px' }}
                                                    id={uuid}
                                                    splitInputType='amount'
                                                    transaction={transaction}
                                                    splitBy={_splitBy}
                                                    callback={handleSplitItemOnChange}
                                                />
                                            ),
                                            button: (
                                                <span className='cursor-pointer' id={uuid} onClick={(e) => handleDeleteSplitItemOnClick(e)}>
                                                    <IconTrash size={24} />
                                                </span>
                                            ),
                                        },
                                        transaction
                                    )
                                }
                                style={{ border: '1px solid #dddddd' }}
                                className='h-[35px] rounded text-sm flex items-center'
                            >
                                <IconPlus size={20} />
                                Add Transaction
                            </button>
                        </div>
                        <div>
                            {_splitTransaction.map((split) => (
                                <div key={split.id} className='flex justify-between items-center gap-2 pt-4'>
                                    {split.list}
                                    <div id={`item-${split.id}`}></div>
                                    {split.amount}
                                    {split.button}
                                </div>
                            ))}
                        </div>
                    </div>
                ),
                body: (
                    <div className='flex justify-between gap-4'>
                        {/* <ClientButton bg='bg-cyan-500' icon='columns' color='text-white' label='Split Transaction' /> */}
                        <ClientButton disabled={!_splitTransaction.length} width='!w-full' bg='bg-cyan-500' icon='save' color='text-white' label='Save Transaction' callback={() => handleSaveSplitTransaction(transaction, _splitTransactions)} />
                    </div>
                ),
                show: true,
            })
        }
    }
    // SPLIT TRANSACTION ENDS HERE

    useEffect(() => {
        if (openSplitModal) {
            if (usage === 'edit') {
                const transactionForEdit = JSON.parse(localStorage.getItem('transactionForEdit'))
                handleEditShowSplitTransactionModal({ _splitBy: splitByForEdit, _splitTransaction: splitTransactionForEdit, _splitTransactions: splitTransactions, _splitItemsPayload: splitItemsPayloadForEdit, _transaction: transactionForEdit })
            } else {
                handleShowSplitTransactionModal({ _splitBy: splitBy, _splitTransaction: splitTransaction, _splitTransactions: splitTransactions, _splitItemsPayload: splitItemsPayload, _transaction: transaction })
            }
        }
    }, [openSplitModal, splitBy, splitTransaction, splitTransactions, splitItemsPayload, transaction])
    useEffect(() => {
        if (!viewContext.modal.data?.show) {
            setOpenSplitModal(false)
            setSplitTransaction([])
            setSplitTransactions(undefined)
            setSplitBy('')
            setSplitItemsPayload([])
            localStorage.removeItem('splitItemsPayload')
        }
    }, [viewContext.modal.data?.show])

    useEffect(() => {
        setVendorValue(transaction?.vendor?.id)
        setCustomerValue(transaction?.customer?.id)
        setCategoryValue(transaction?.category?.id)
        setClassValue(transaction?.class?.id)
        setLocationValue(transaction?.location?.id)
        setDescriptionValue(transaction?.description)
    }, [])

    return (
        <>
            {contextHolder}
            <div className='pb-5 bg-white'>
                <div className='px-2 pt-2 mb-4 flex justify-between items-center gap-1'>
                    {(fetchTransactionType === 'categorizeMe' || permission === 'owner' || permission === 'staff') && (
                        <div className='bg-slate-100 p-2 rounded flex justify-between items-center gap-2'>
                            <Tooltip label={permission === 'owner' || permission === 'staff' ? 'Email Client' : 'Email Accountant'} color='cyan' withArrow>
                                <span className='cursor-pointer' onClick={() => handleMessage(transaction)}>
                                    <Icon image='message-circle' size={20} />
                                </span>
                            </Tooltip>
                            <Tooltip label='Attach Document' color='cyan' withArrow>
                                <span className='cursor-pointer' onClick={() => handleAttachment(transaction)}>
                                    <Icon image='paperclip' color={transaction?.attachments.length ? 'rgb(34 197 94)' : '#000'} size={20} />
                                </span>
                            </Tooltip>
                            {(permission === 'owner' || permission === 'staff') && fetchTransactionType !== 'approved' && (
                                <Tooltip label='Split Transaction' color='cyan' withArrow>
                                    <span className='cursor-pointer' onClick={() => setOpenSplitModal(true)}>
                                        {/* <Icon image='paperclip' color={transaction?.attachments.length ? 'rgb(34 197 94)' : '#000'} size={20} /> */}
                                        <IconArrowsSplit2 />
                                    </span>
                                </Tooltip>
                            )}
                            {(permission === 'owner' || permission === 'staff') && fetchTransactionType === 'approved' && (
                                <Tooltip label='Delete transaction' color='cyan' withArrow>
                                    <span className='cursor-pointer' onClick={() => handleDeleteTransaction(transaction)}>
                                        <IconTrash color='#000' />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    )}

                    {permission === 'owner' || permission === 'staff' ? (
                        <span>
                            <ClientStyledData bg={formatTransactionStatus('background', transaction.status)} c={formatTransactionStatus('color', transaction.status)}>
                                {transaction.status === 'No Action Needed' && fetchTransactionType === 'approved' ? 'Approved' : transaction.status}
                            </ClientStyledData>
                        </span>
                    ) : null}

                    {fetchTransactionType !== 'approved' && (permission === 'owner' || permission === 'staff') && transaction.status === 'Needs Review' ? (
                        <span>
                            <ClientStyledData bg='bg-red-100' c='text-red-500'>
                                {'Client Updated'}
                            </ClientStyledData>
                        </span>
                    ) : null}
                </div>

                <div className='px-6 mb-2 text-left'>
                    <p className='font-bold !mb-0'>Vendor</p>
                    <div className='w-full pr-0 md:mb-0 flex justify-between items-center gap-2'>
                        <div className='w-full pr-0 md:mb-0'>
                            <Select
                                data={[...lists?.vendors].map((data) => {
                                    return {
                                        value: data.id,
                                        label: data.value,
                                    }
                                })}
                                value={singleUpdatePayload.find((data) => data._id === transaction?._id)?.vendor?.id || vendorValue}
                                onChange={(value) =>
                                    handleCardViewChange({
                                        value,
                                        type: 'vendor',
                                        transaction,
                                        usage,
                                        transactions,
                                    })
                                }
                                searchable
                                nothingFound='No options'
                                placeholder='Select vendor'
                                styles={{ root: { width: '100%' } }}
                                disabled={fetchTransactionType === 'approved'}
                                readOnly={(fetchTransactionType === 'underReview' || fetchTransactionType === 'approved') && (permission === 'user' || permission === 'clientStaff')}
                            />
                        </div>

                        {isUserAllowedToAddNewList('allowCreateNewVendorCustomer') && fetchTransactionType !== 'approved' && (
                            <span className='cursor-pointer' onClick={() => handleAddList(transaction.vendor.type || 'vendor')}>
                                <Icon image='plus-circle' size={20} />
                            </span>
                        )}
                    </div>
                </div>

                <div className='px-6 mb-2 text-left'>
                    <p className='font-bold !mb-0'>Customer</p>
                    <div className='w-full pr-0 md:mb-0 flex justify-between items-center gap-2'>
                        <div className='w-full pr-0 md:mb-0'>
                            <Select
                                data={[...lists?.customers].map((data) => {
                                    return {
                                        value: data.id,
                                        label: data.value,
                                    }
                                })}
                                value={singleUpdatePayload.find((data) => data._id === transaction?._id)?.customer?.id || customerValue}
                                onChange={(value) =>
                                    handleCardViewChange({
                                        value,
                                        type: 'customer',
                                        transaction,
                                        usage,
                                        transactions,
                                    })
                                }
                                searchable
                                nothingFound='No options'
                                placeholder='Select vendor'
                                styles={{ root: { width: '100%' } }}
                                disabled={fetchTransactionType === 'approved'}
                                readOnly={(fetchTransactionType === 'underReview' || fetchTransactionType === 'approved') && (permission === 'user' || permission === 'clientStaff')}
                            />
                        </div>

                        {isUserAllowedToAddNewList('allowCreateNewVendorCustomer') && fetchTransactionType !== 'approved' && (
                            <span className='cursor-pointer' onClick={() => handleAddList(transaction.customer.type || 'vendor')}>
                                <Icon image='plus-circle' size={20} />
                            </span>
                        )}
                    </div>
                </div>

                <div className='px-6 pb-2 text-left'>
                    <div className='text-left flex justify-between'>
                        <div>
                            <p className='font-bold !mb-0'>Date</p>
                            <ClientStyledData bg='bg-gray-100' c='text-gray-500'>
                                {formatDate(transaction.date)}
                            </ClientStyledData>
                        </div>
                        <div>
                            <p className='font-bold !mb-0'>Amount</p>
                            <ClientStyledData bg={transaction.amount.color.background} c={`${transaction.amount.color.text}`} t={transaction.amount.tooltip}>
                                {transaction.amount.value}
                            </ClientStyledData>
                        </div>
                    </div>

                    <div className='mt-6 text-left'>
                        <p className='!mb-0 font-bold'>Memo/Description</p>
                        <ClientTextArea
                            type='description'
                            usage={usage}
                            isTransactionView
                            transaction={transaction}
                            payload={singleUpdatePayload.find((data) => data._id === transaction?._id)?.description || descriptionValue}
                            description={transaction.description || descriptionValue}
                            qbo_description={transaction?.qbo_description}
                            memo={transaction.memo}
                            account={transaction?.account_name?.value}
                            callback={handleChange}
                            fetchTransactionType={fetchTransactionType}
                            permission={permission}
                        >
                            {singleUpdatePayload.find((data) => data._id === transaction?._id)?.description || transaction.description}
                        </ClientTextArea>
                    </div>

                    <p className='text-left font-bold !mb-0 mt-2'>Category</p>
                    <div className='w-full pr-0 md:mb-0 flex justify-between items-center gap-2'>
                        <div className='w-full pr-0 md:mb-0'>
                            <Tree
                                showSearch
                                allowClear
                                placeholder='Select Category'
                                type='category'
                                value={singleUpdatePayload.find((data) => data._id === transaction?._id)?.category?.id || categoryValue}
                                list={handleFilterExcludedAccounts(companyProfile?.excludedAccounts, lists?.categories)}
                                transaction={transaction}
                                usage={usage}
                                fetchTransactionType={fetchTransactionType}
                                callback={handleCardViewChange}
                                permission={permission}
                                disabled={(fetchTransactionType !== 'categorizeMe' && (permission === 'user' || permission === 'clientStaff')) || fetchTransactionType === 'approved'}
                            />
                        </div>
                        {isUserAllowedToAddNewList('allowCreateNewCategory') && fetchTransactionType !== 'approved' && (
                            <span className='cursor-pointer' onClick={() => handleAddList('category')}>
                                <Icon image='plus-circle' size={20} />
                            </span>
                        )}
                    </div>

                    {getClassLocationStatus?.isClassEnabled && (
                        <>
                            <p className='text-left font-bold !mb-0 mt-2'>Class</p>
                            <div className='mb-1 mx-auto flex justify-between items-center gap-2'>
                                <div className='w-full pr-0 md:mb-0'>
                                    <Select
                                        value={singleUpdatePayload.find((data) => data._id === transaction?._id)?.class?.id || classValue}
                                        searchable
                                        onChange={(value) =>
                                            handleCardViewChange({
                                                value,
                                                type: 'class',
                                                transaction,
                                                usage,
                                            })
                                        }
                                        nothingFound='No options'
                                        data={lists?.classes.map((data) => {
                                            return {
                                                value: data.id,
                                                label: data.value,
                                            }
                                        })}
                                        placeholder='Select Class'
                                        styles={{ root: { width: '100%' } }}
                                        disabled={(fetchTransactionType !== 'categorizeMe' && (permission === 'user' || permission === 'clientStaff')) || fetchTransactionType === 'approved'}
                                    />
                                </div>
                                {fetchTransactionType !== 'approved' && isUserAllowedToAddNewList('allowCreateNewClass') && (
                                    <span className='cursor-pointer' onClick={() => handleAddList('class')}>
                                        <Icon image='plus-circle' size={20} />
                                    </span>
                                )}
                            </div>
                        </>
                    )}

                    {getClassLocationStatus?.isLocationEnabled && (
                        <>
                            <p className='text-left font-bold !mb-0 mt-2'>Location</p>
                            <div className='mb-1 mx-auto flex justify-between items-center gap-2'>
                                <div className='w-full pr-0 md:mb-0'>
                                    <Select
                                        value={singleUpdatePayload.find((data) => data._id === transaction?._id)?.location?.id || locationValue}
                                        searchable
                                        onChange={(value) =>
                                            handleCardViewChange({
                                                value,
                                                type: 'location',
                                                transaction,
                                                usage,
                                            })
                                        }
                                        nothingFound='No options'
                                        data={lists?.locations.map((data) => {
                                            return {
                                                value: data.id,
                                                label: data.value,
                                            }
                                        })}
                                        placeholder='Select Location'
                                        styles={{ root: { width: '100%' } }}
                                        disabled={(fetchTransactionType !== 'categorizeMe' && (permission === 'user' || permission === 'clientStaff')) || fetchTransactionType === 'approved'}
                                    />
                                </div>
                                {fetchTransactionType !== 'approved' && isUserAllowedToAddNewList('allowCreateNewLocation') && (
                                    <span className='cursor-pointer' onClick={() => handleAddList('location')}>
                                        <Icon image='plus-circle' size={20} />
                                    </span>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {fetchTransactionType !== 'approved' && (permission === 'owner' || permission === 'staff') && (
                    <div className='px-6 pb-2 pt-2 text-left mb-2'>
                        <ClientButton disabled={isUpdateIntuitTransactionLoading} callback={() => handleSaveApprove(transaction, usage)} width='!w-full' icon='' label={fetchTransactionType === 'underReview' ? 'Approve' : 'Save'} bg='bg-cyan-500' color='text-white' />
                    </div>
                )}

                {fetchTransactionType === 'categorizeMe' && (permission === 'user' || permission === 'clientStaff') && (
                    <div className='px-6 pb-2 pt-2 text-left mb-2'>
                        <ClientButton disabled={isUpdateIntuitTransactionLoading} callback={() => handleSaveApprove(transaction, usage)} width='!w-full' icon='' iconEnd='send' label='Send to Review' bg='bg-green-500' color='text-white' />
                    </div>
                )}

                {/* CREATE LIST MODALS */}
                <Modal opened={opened} onClose={close} title={createListModalTitle} centered>
                    {createListModalContentType === 'vendor' && (
                        <div className='flex flex-col justify-center gap-2'>
                            <TextInput name='vendor' ref={createListInput} error={createListEmptyFieldError} label={<InputLabel label='Company Name' />} placeholder='Company name' onChange={(e) => handleCreateListTextInputOnChange('vendor', e.target.value)} />
                            <ClientButton icon='' width='auto' label='Create' bg='bg-cyan-500' color='text-white' callback={(e) => handleCreateListOnClick(e, transaction)} />
                        </div>
                    )}

                    {createListModalContentType === 'customer' && (
                        <div className='flex flex-col justify-center gap-2'>
                            <TextInput name='customer' ref={createListInput} error={createListEmptyFieldError} label={<InputLabel label='Company Name' />} placeholder='Company name' onChange={(e) => handleCreateListTextInputOnChange('customer', e.target.value)} />
                            <ClientButton icon='' width='auto' label='Create' bg='bg-cyan-500' color='text-white' callback={(e) => handleCreateListOnClick(e, transaction)} />
                        </div>
                    )}

                    {createListModalContentType === 'category' && (
                        <div className='flex flex-col justify-center gap-2'>
                            <TextInput name='category' ref={createListInput} error={createListEmptyFieldError} label={<InputLabel label='Account Name' />} placeholder='Account name' onChange={(e) => handleCreateListTextInputOnChange('category', e.target.value)} />
                            <Select
                                ref={createListSelect}
                                value={classification}
                                data={Classification.sort()}
                                onChange={(value) => handleCategoryOnChange('classification', value)}
                                searchable
                                nothingFound='No options'
                                placeholder='Select Classification'
                                styles={{ root: { width: '100%' } }}
                                label={<InputLabel label='Classification' />}
                            />
                            <Select
                                ref={createListSelect}
                                value={accountType}
                                data={AccountType(classification).sort()}
                                onChange={(value) => handleCategoryOnChange('accountType', value)}
                                searchable
                                nothingFound='No options'
                                placeholder='Select AccountType'
                                styles={{ root: { width: '100%' } }}
                                label={<InputLabel label='Account Type' />}
                            />
                            <Select
                                ref={createListSelect}
                                value={accountSubType}
                                data={AccountSubType(accountType).sort()}
                                onChange={(value) => handleCategoryOnChange('accountSubType', value)}
                                searchable
                                nothingFound='No options'
                                placeholder='Select AccountSubType'
                                styles={{ root: { width: '100%' } }}
                                label={<InputLabel label='Account Subtype' />}
                            />
                            <ClientButton icon='' width='auto' label='Create' bg='bg-cyan-500' color='text-white' callback={(e) => handleCreateListOnClick(e, transaction)} />
                        </div>
                    )}

                    {createListModalContentType === 'class' && (
                        <div className='flex flex-col justify-center gap-2'>
                            <TextInput name='class' ref={createListInput} error={createListEmptyFieldError} label={<InputLabel label='Class Name' />} placeholder='Class name' onChange={(e) => handleCreateListTextInputOnChange('class', e.target.value)} />
                            <ClientButton icon='' width='auto' label='Create' bg='bg-cyan-500' color='text-white' callback={(e) => handleCreateListOnClick(e, transaction)} />
                        </div>
                    )}

                    {createListModalContentType === 'location' && (
                        <div className='flex flex-col justify-center gap-2'>
                            <TextInput name='location' ref={createListInput} error={createListEmptyFieldError} label={<InputLabel label='Department/Location Name' />} placeholder='Department/Location name' onChange={(e) => handleCreateListTextInputOnChange('location', e.target.value)} />
                            <ClientButton icon='' width='auto' label='Create' bg='bg-cyan-500' color='text-white' callback={(e) => handleCreateListOnClick(e, transaction)} />
                        </div>
                    )}
                </Modal>
            </div>
        </>
    )
}
