/***
 *
 *   PRICING
 *   Modify the PricePlans component with your own features
 *
 **********/

import React from 'react'
import { Animate, Row, PricePlans } from 'components/lib'

export function Pricing(props) {
    return (
        <Animate type='pop'>
            <Row color='tint' align='center' title='Simple, Affordable Pricing'>
                <PricePlans />
            </Row>
        </Animate>
    )
}
