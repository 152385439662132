/***
 *
 *   SIGN UP (client)
 *   Signup form for client
 *
 **********/

import React, { useContext, useEffect } from 'react'
import { useQueryClient, useMutation } from '@tanstack/react-query'

import { Animate, Row, ViewContext, AuthContext, Card, Form, Link, useNavigate /* useAPI */ /* SocialSignin */ } from 'components/lib'
import { ApiContext } from 'context/api'
// import { ErrorNotification } from 'utils/notifications'

export function SignupClient(props) {
    const context = useContext(AuthContext)
    const viewContext = useContext(ViewContext)
    const { handleClientCheckExistenceRequest, handleUpdateCompanyRequest, setButtonLoading } = useContext(ApiContext)
    const urlParams = new URLSearchParams(window.location.search)
    const url = window.location.href
    // const id = url.substring(url.indexOf('?id=') + 4, url.indexOf('&companyId'))
    // const companyId = url.substring(url.indexOf('&companyId') + 11, url.indexOf('&token'))
    const email = url.substring(url.indexOf('&email') + 7, url.indexOf('&token'))
    // const token = url.substring(url.indexOf('&token') + 7)
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    // const { data } = useAPI(`/api/v1/invite?type=client&id=${id}`)

    function handleOnLoadActions() {
        viewContext.setLoading(false)
        setButtonLoading(false)
    }

    const { mutateAsync: mutateCompany } = useMutation({
        mutationFn: handleUpdateCompanyRequest,
        onSuccess: () => queryClient.invalidateQueries(['companies']),
    })

    async function handleCheckIfClientExist() {
        const isClientExist = await handleClientCheckExistenceRequest({ token: urlParams.get('token'), email: urlParams.get('email') }, handleOnLoadActions)
        if (isClientExist) {
            const isCompanyUpdate = await mutateCompany({ companyId: urlParams.get('companyId'), token: urlParams.get('token'), clientId: urlParams.get('id'), isActive: true }, handleOnLoadActions)
            if (isCompanyUpdate) {
                return navigate('/company')
            }
        }
        // else {
        //     return ErrorNotification({ title: 'Invitation Link Expired', message: 'The invitation link was already expired.' })
        // }
    }

    /* eslint-disable */
    useEffect(() => {
        handleCheckIfClientExist()
    }, [])
    // useEffect(() => {
    //     if (data) {
    //         handleCheckIfClientExist(data?.email)
    //         const el = document.getElementById('email')
    //         el.value = data?.email
    //     }
    // }, [data])

    return (
        <Animate type='pop'>
            <Row title='Create Your Account'>
                <Card restrictWidth center>
                    {/* <SocialSignin network={['facebook', 'twitter']} showOr invite={ id } /> */}
                    <Form
                        data={{
                            name: {
                                label: 'First Name',
                                value: '',
                                type: 'text',
                                required: true,
                                errorMessage: 'Please enter your first name',
                            },
                            email: {
                                label: 'Email',
                                value: email,
                                type: 'email',
                                required: true,
                                isDisabled: true,
                            },
                            password: {
                                label: 'Password',
                                type: 'password',
                                required: true,
                                complexPassword: true,
                            },
                            confirm_password: {
                                type: 'hidden',
                                value: null,
                            },
                            invite_id: {
                                type: 'hidden',
                                value: urlParams.get('id'),
                            },
                            companyId: {
                                type: 'hidden',
                                value: urlParams.get('companyId'),
                            },
                        }}
                        url='/api/v1/client/update'
                        method='POST'
                        // redirect='/transaction'
                        redirect='/signin'
                        buttonText='Create Account'
                        callback={context.signin}
                        token={urlParams.get('token')}
                    />

                    <div className='mt-4'>
                        Already registered? <Link url='/signin' text='Sign In' />
                    </div>
                </Card>
            </Row>
        </Animate>
    )
}
