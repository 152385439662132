import { Link } from 'components/lib'

export function Disconnected() {
    return (
        <div className='flex flex-col justify-center items-center gap-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-80'>
            <h1 className='text-5xl font-bold'>QuickBooks Disconnected</h1>
            <p>Your QuickBooks integration has been disconnected. You will no longer to be able to streamline your uncategorized transactions.</p>
            <p>
                If you’d like to re-connect Categorize.Me and your QuickBooks account,{' '}
                <Link url='/signin' color='text-gray-500'>
                    click here to log in.
                </Link>
            </p>
            <ol className='list-decimal mb-10'>
                <li>
                    <Link url='/signin' color='text-gray-500'>
                        Log in to Categorize.Me
                    </Link>
                </li>
                <li>Go to Company</li>
                <li>Select the company you would like to reconnect</li>
                <li>Click on Connect to QuickBooks</li>
                <li>Click on Search for Company</li>
                <li>Click on your desired company</li>
                <li>Click on Next</li>
                <li>Click on Connect</li>
            </ol>
        </div>
    )
}
