import { Fragment, useState, useRef, useContext, useEffect } from 'react'
import { Tooltip, Stepper } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useQueryClient, useMutation } from '@tanstack/react-query'

import { ClientButton, Icon, ViewContext, Tree } from 'components/lib'
import { ApiContext } from 'context/api'
import QBO_BUTTON from './images/C2QB_green_btn_tall_default.svg'
import Style from './stepper.tailwind'

export const ClientStepper = (props) => {
    const viewContext = useContext(ViewContext)
    const { handleUpdateCompanyRequest, requestToken, handleGetAuthorizationUriRequest } = useContext(ApiContext)
    const [active, setActive] = useState()
    const [selectedSyncDate, setSelectedSyncDate] = useState()
    const newCategories = props?.list?.map((list) => ({ value: list.value, label: list.title, children: list.children }))
    const value = newCategories?.filter((d) => requestToken?.intuitSyncedAccounts.some((a) => d.label.includes(a))).map((d) => d.value)
    const step2Item1 = useRef()
    // const step2Item2 = useRef()
    const step3Item1 = useRef()
    const queryClient = useQueryClient()

    function handleOnStepClick(step) {
        if (step === 0) {
        } else if (step === 1) {
            props.activeStep && setActive(step)
        } else if (step === 2) {
            if (step2Item1.current?.value) {
                setActive(step)
            } else {
                return
            }
        }
    }

    const { mutateAsync: updateCompany } = useMutation({
        mutationFn: handleUpdateCompanyRequest,
        onSuccess: () => {
            queryClient.invalidateQueries(['companies'])
        },
    })

    function handleSelectDateOnchange(date) {
        if (!date) return
        const year = date.getFullYear()
        const month = date.getMonth() < 9 ? (date.getMonth() + 1).toString().padStart(2, '0') : date.getMonth() + 1
        const _date = date.getDate() < 9 ? date.getDate().toString().padStart(2, '0') : date.getDate()
        setSelectedSyncDate(date)
        updateCompany({ companyId: requestToken?.companyId, syncedStartDate: `${year}-${month}-${_date}` })
    }

    function handleInviteClient() {
        if (!step3Item1.current.value) {
            step3Item1.current.style.border = '1px solid red'
            return
        }
        props?.callbacks.handleClientInvitation({ _id: requestToken?._id, id: requestToken?.companyId }, 'clientInviteEmailAddressOnboarding')
    }

    async function handleConnectToQBO() {
        const uri = await handleGetAuthorizationUriRequest()
        window.location.href = uri?.data
    }

    /* eslint-disable */
    useEffect(() => {
        if (props?.activeStep) {
            setActive(props?.activeStep)
        } else {
            setActive(0)
        }
    }, [])

    return (
        <>
            <Stepper active={active} onStepClick={handleOnStepClick} breakpoint='sm'>
                {/* STEP #1 */}
                <Stepper.Step description='Connect QuickBooks'>
                    <div className={Style.step1Container}>
                        <span onClick={handleConnectToQBO} style={{ cursor: 'pointer' }}>
                            <img src={QBO_BUTTON} alt='Connect to QuickBooks' unoptimized='true' />
                        </span>
                    </div>
                </Stepper.Step>

                {/* STEP #2 */}
                <Stepper.Step description='Choose Settings'>
                    <div className={Style.step2Container}>
                        <div className='flex flex-col justify-center gap-1'>
                            <div className='flex justify-left items-center gap-1'>
                                <p className='font-bold text-sm !m-0'>Select Sync Date</p>
                                <Tooltip label='Select the sync date of the transactions to sync' color='violet' withArrow>
                                    <span>
                                        <Icon image='help-circle' size={14} color='blue' />
                                    </span>
                                </Tooltip>
                            </div>
                            <div>
                                <DatePicker
                                    dropdownType='modal'
                                    ref={step2Item1}
                                    placeholder='Pick date'
                                    defaultValue={selectedSyncDate}
                                    onChange={handleSelectDateOnchange}
                                    styles={{
                                        calendarHeaderControl: {
                                            '&[data-selected]': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                            ':hover': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                        },
                                        calendarHeaderLevel: {
                                            '&[data-selected]': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                            ':hover': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                        },
                                        calendarHeaderLevelIcon: {
                                            ':hover': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white!important',
                                            },
                                        },
                                        day: {
                                            '&[data-selected]': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                            ':hover': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                        },
                                        monthPickerControl: {
                                            '&[data-selected]': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                            ':hover': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                        },
                                        monthPickerControlActive: {
                                            backgroundColor: 'rgb(6 182 212)',
                                            color: '#000',
                                            ':hover': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                        },
                                        yearPickerControl: {
                                            '&[data-selected]': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                            ':hover': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                        },
                                        yearPickerControlActive: {
                                            backgroundColor: 'rgb(6 182 212)',
                                            color: '#000',
                                            ':hover': {
                                                backgroundColor: 'rgb(6 182 212)',
                                                color: 'white',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <div className='flex flex-col justify-center gap-1'>
                            <div className='flex justify-left items-center gap-1'>
                                <span className='text-red-500'>*</span>
                                <p className='inline font-bold text-sm !m-0'>Choose which accounts to sync</p>
                                <Tooltip label='Type of transaction account to be sync from QBO' color='cyan' withArrow>
                                    <span>
                                        <Icon image='help-circle' size={14} color='blue' />
                                    </span>
                                </Tooltip>
                            </div>
                            <div>
                                <Tree multiple showSearch allowClear placeholder='Choose Account(s)' list={newCategories} value={value} callback={(syncedAccounts) => props?.callbacks.handleChangeSyncedAccounts(requestToken?.companyId, syncedAccounts)} />
                            </div>
                        </div>

                        <ClientButton bg='bg-cyan-600' color='text-white' icon='' label='Next' width='!w-full' callback={() => handleOnStepClick(2)} />
                    </div>
                </Stepper.Step>

                {/* STEP #3 */}
                <Stepper.Step description='Invite Client(s)'>
                    <div className={Style.step3Container}>
                        <div className='flex flex-col justify-center gap-1'>
                            <div className='flex justify-left items-center gap-1'>
                                <p className='font-bold text-sm !m-0'>Client Email</p>
                                <Tooltip label='Enter a valid email' color='cyan' withArrow>
                                    <span>
                                        <Icon image='help-circle' size={14} color='blue' />
                                    </span>
                                </Tooltip>
                            </div>
                            <div>
                                <input ref={step3Item1} id='clientInviteEmailAddressOnboarding' className='w-full pl-2 py-3 text-sm outline-none border rounded' type='text' placeholder='Client email...' />
                            </div>
                        </div>

                        <div className='flex justify-center items-center gap-2'>
                            <ClientButton bg='bg-cyan-600' color='text-white' icon='' label='Invite Client' width='!w-full' callback={handleInviteClient} />
                            <ClientButton bg='bg-cyan-600' color='text-white' icon='' label='Invite Client Later' width='!w-full' callback={viewContext.modal.hide} />
                        </div>
                    </div>
                </Stepper.Step>
            </Stepper>
        </>
    )
}
