/***
 *
 *   CLIENT LAYOUT
 *   Layout for the client pages
 *
 **********/
import { Fragment, useContext } from 'react'

import { AuthContext, AppNav, Header, ClientHeader } from 'components/lib'
import { ApiContext } from 'context/api.js'
import Style from './company.tailwind.js'

export function CategorizemeLayout(props) {
    const authContext = useContext(AuthContext)
    const { transactionsCount, companyProfile, fetchTransactionType } = useContext(ApiContext)
    const pathname = window.location.pathname
    const permission = authContext?.user?.permission

    const renderDescription = () => {
        if (pathname === '/company') {
            return 'Connected Companies'
        } else {
            return fetchTransactionType === 'categorizeMe'
                ? `${transactionsCount ? transactionsCount : ''} Uncategorized Transactions`
                : fetchTransactionType === 'underReview'
                ? transactionsCount > 0
                    ? `${transactionsCount ? transactionsCount : ''} Under Review Transactions`
                    : 'No Action Needed'
                : transactionsCount > 0
                ? `${transactionsCount ? transactionsCount : ''} Approved Transactions`
                : 'No Approved Transactions'
        }
    }

    let items = []

    if (authContext?.permission?.owner) {
        items = [
            {
                label: 'Company',
                icon: 'users',
                link: '/company',
            },
            { label: 'Account', icon: 'user', link: '/account' },
            { label: 'Help', icon: 'help-circle', link: '/help' },
            { label: 'Sign Out', icon: 'log-out', action: authContext.signout },
        ]
    } else {
        items = [
            {
                label: 'Company',
                icon: 'users',
                link: '/company',
            },
            { label: 'Account', icon: 'user', link: '/account' },
            { label: 'Sign Out', icon: 'log-out', action: authContext.signout },
        ]
    }

    return (
        <Fragment>
            <AppNav items={items} />
            <main className={Style.client}>
                <Header>
                    {(permission === 'owner' || permission === 'staff') && <ClientHeader view={props.title} permission={permission} title={props.title === 'Company' ? 'Companies' : companyProfile?.companyName} count={authContext?.uncatTrans} description={renderDescription()} />}
                    {(permission === 'user' || permission === 'clientStaff') && <ClientHeader view={props.title} permission={permission} title={props.title === 'Company' ? 'Companies' : companyProfile?.companyName} count={authContext?.uncatTrans} description={renderDescription()} />}
                </Header>
                {<props.children {...props.data} />}
            </main>
        </Fragment>
    )
}
