import { useContext } from 'react'
import { Loader } from '@mantine/core'

import { AuthContext, Icon, Logo } from 'components/lib'
import './drawer.scss'

export function Drawer(props) {
    const context = useContext(AuthContext)

    const handleLink = (id) => {
        props.callback(id)
    }

    return (
        <nav className='subnav'>
            {props.items?.map((item) => {
                if (item.permission && !context.permission[item.permission]) return false

                return (
                    <button key={item.label} className={`item ${props.activeTab === item.id && 'active'}`} onClick={() => handleLink(item.id)}>
                        {item.id === 'categorizeMe' && (
                            <span className='categorizeMeLogo'>
                                <Logo color mark width={15} height={15} />
                            </span>
                        )}
                        {item.id !== 'categorizeMe' && <Icon image={item.id === 'underReview' ? 'alert-circle' : 'check-circle'} color={item.id === 'approved' ? '#06B6D4' : ''} size={20} />}
                        {item.label}
                    </button>
                )
            })}
            {/* Autosave Indicator */}
            {props?.singleUpdatePayload.length > 0 && props?.activeTab === 'categorizeMe' && (props?.permission === 'user' || props?.permission === 'clientStaff') && (
                <div className='indicator'>
                    {props?.isAutosaveLoading ? (
                        <Loader color='gray' size='sm' />
                    ) : (
                        <div className='check'>
                            <Icon image='check' color='#968d87' />
                        </div>
                    )}
                    <div>
                        <small>
                            <b>Autosaving Edits</b>
                        </small>
                        <br />
                        <div className='sentToReview'>
                            <small>Done Editing? Send to Review</small>
                            <span className='cursor-pointer' onClick={() => props?.handleSendBulkReview()}>
                                <Icon image='send' color='rgb(22 163 74)' />
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    )
}
