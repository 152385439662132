/***
 *
 *   TABLE
 *   Please view the full documentation: https://docs.usegravity.app/ui/table
 *
 *   PROPS
 *   data: array of objects for body and header (optional)
 *   search: bool to show the search field
 *   sort: allow the table columns to be sorted
 *   loading: bool to toggle loading spinner
 *   badge - object containing column name and color to add badges to column
 *   show - array of columns (object key names) to show (shows all if not provided)
 *   hide - array of columns (object key names) to hide
 *   actions: object with edit/delete keys set to callback functions (optional)
 *
 **********/

import { Fragment, useState, useEffect, useContext } from 'react'
import { Loader, Search, ClassHelper } from 'components/lib'
import { Header } from './header'
import { Body } from './body'
import Style from './table.tailwind.js'
import { ApiContext } from 'context/api'

export function Table(props) {
    // CONTEXT
    const { setSortField, setSortOrder } = useContext(ApiContext)

    // STATE
    const [header, setHeader] = useState(null)
    const [body, setBody] = useState(null)
    const [filter, setFilter] = useState(false)

    useEffect(() => {
        if (props.data) {
            // create the headers
            let header = props.data.header || []

            if (!header.length) {
                for (let key in props.data[0]) {
                    header.push({
                        name: key,
                        title: key.replace('_', ' '),
                        sort: key === 'actions' ? false : true,
                    })
                }
            }

            // Changed from props.data to props.data.body || props.data
            setBody(props.data.body || props.data)
            setHeader(header)
        }
    }, [props.data])

    // loading
    if (props.loading) {
        return (
            <div className={Style.loading}>
                <Loader />
            </div>
        )
    }

    // no data
    if (!header && !body) return false

    function sort(column, direction) {
        direction === 'asc' ? setSortOrder(1) : setSortOrder(-1)

        if (column === 'company') {
            setSortField('companyName')
        } else if (column === 'name') {
            setSortField('clientName')
        }

        const rows = filter.length ? [...filter] : [...body]

        rows.sort(function (a, b) {
            if (a[column] && b[column]) {
                a[column].badge ? (a = a[column].label) : (a = a[column])

                b[column].badge ? (b = b[column].label) : (b = b[column])

                if (direction === 'desc') {
                    if (a > b) return -1
                    if (a < b) return 1
                    else return 0
                } else {
                    if (a < b) return -1
                    if (a > b) return 1
                    else return 0
                }
            } else {
                return false
            }
        })

        /* if (column === 'activity' || column === 'company') {
            rows.sort(function (a, b) {
                if (a[column]?.props?.children && b[column]?.props?.children) {
                    a[column]?.badge ? (a = a[column].label) : (a = a[column]?.props?.children)

                    b[column]?.badge ? (b = b[column].label) : (b = b[column]?.props?.children)

                    if (direction === 'desc') {
                        if (a > b) return -1
                        if (a < b) return 1
                        else return 0
                    } else {
                        if (a < b) return -1
                        if (a > b) return 1
                        else return 0
                    }
                } else {
                    return false
                }
            })
        } else {
            rows.sort(function (a, b) {
                if (a[column] && b[column]) {
                    a[column].badge ? (a = a[column].label) : (a = a[column])

                    b[column].badge ? (b = b[column].label) : (b = b[column])

                    if (direction === 'desc') {
                        if (a > b) return -1
                        if (a < b) return 1
                        else return 0
                    } else {
                        if (a < b) return -1
                        if (a > b) return 1
                        else return 0
                    }
                } else {
                    return false
                }
            })
        } */

        filter ? setFilter(rows) : setBody(rows)
    }

    function search(term) {
        // search each cell in each row &
        // update state to show only filtered rows
        let rowsToShow = []

        body.forEach((row) => {
            for (let cell in row) {
                // row[cell].props?.label =  include status and activity in filter
                // row[cell].props?.children = include company in filter
                if (row[cell].toString().toLowerCase().includes(term.toLowerCase())) {
                    if (!rowsToShow.includes(row)) rowsToShow.push(row)
                }
            }
        })

        setFilter(rowsToShow)
    }

    function editRowCallback(res, row) {
        let state = [...body]
        let stateRow = state[state.findIndex((x) => x.id === row.id)]
        Object.keys(res).map((key) => (stateRow[key] = res[key].value))
        setBody(state)
    }

    function deleteRowCallback(res, row) {
        let state = [...body]
        state.splice(
            state.findIndex((x) => x.id === row.id),
            1
        )
        setBody(state)
    }

    const tableStyle = ClassHelper(Style, {
        table: true,
    })

    return (
        <Fragment>
            {props.search && <Search className={Style.search} callback={search} throttle={props.throttle} />}

            <table className={!props.naked && tableStyle}>
                {header && <Header data={header} callback={sort} show={props.show} hide={props.hide} actions={props.actions} />}
                {body && (
                    <Body
                        isTransaction={props?.transaction}
                        data={filter ? filter : body}
                        show={props.show}
                        hide={props.hide}
                        badge={props.badge}
                        actions={{
                            edit: props.actions?.edit,
                            view: props.actions?.view,
                            delete: props.actions?.delete,
                            email: props.actions?.email,
                            custom: props.actions?.custom,
                        }}
                        callback={{
                            edit: editRowCallback,
                            delete: deleteRowCallback,
                        }}
                    />
                )}
            </table>
        </Fragment>
    )
}
