/***
 *
 *   BILLING / PLAN
 *   Update the billing plan
 *
 **********/

import React, { useContext } from 'react'
import { AuthContext, Card, Form, Message, usePlans } from 'components/lib'

export function BillingPlan(props) {
    const plans = usePlans()
    const context = useContext(AuthContext)

    // const plan = context?.user?.plan
    // const connectedCompaniesCount = +localStorage.getItem('connectedCompanies')

    // const isPlanLimitExceeded = plan === 'price_1MxsdoBjmhHusJiWuHqTIiGF' && connectedCompaniesCount > 50
    // const isPlanLimitExceeded = plan === 'price_1MxsdoBjmhHusJiWuHqTIiGF' && connectedCompaniesCount > 2

    return (
        <Card loading={plans.loading || props.subscription.loading} restrictWidth className={props.className}>
            {props.subscription?.data?.object && <Message type='info' title='Your Billing Cycle' text={`${props.subscription.data.object.current_period_start} to ${props.subscription.data.object.current_period_end}`} />}

            <Form
                data={{
                    plan: {
                        label: 'Your subscription plan',
                        type: 'select',
                        // isDisabled: isPlanLimitExceeded,
                        required: true,
                        default: plans?.data?.active,
                        options: plans?.data?.list,
                    },
                }}
                url='/api/v1/account/plan'
                method='PATCH'
                buttonText='Update Plan'
                callback={(res) => {
                    context.update({ plan: res.data.data.plan })
                }}
                // isDisabled={isPlanLimitExceeded}
            />
        </Card>
    )
}
