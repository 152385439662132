/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import React from 'react'
import { Animate, Row, Form, Card } from 'components/lib'
import { UpdateNotification } from 'utils/notifications'

export function ForgotPassword(props) {
    return (
        <Animate type='pop'>
            <Row title='Reset Your Password'>
                <Card restrictWidth center>
                    <p className='mb-5'>Enter your email address and we'll send you instructions to reset your password.</p>

                    <Form
                        data={{
                            email: {
                                label: 'Email',
                                type: 'email',
                                required: true,
                            },
                        }}
                        url='/api/v1/auth/password/reset/request'
                        method='POST'
                        buttonText='Reset Password'
                        callback={(res) =>
                            UpdateNotification({
                                title: 'Reset link sent',
                                message: `${res.data.message}`,
                            })
                        }
                    />
                </Card>
            </Row>
        </Animate>
    )
}
