import { Fragment, useContext, useState, useRef, useEffect } from 'react'
import { Tooltip, Loader } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { IconInfoCircle } from '@tabler/icons-react'

import { ClientButton, ClientSearchbar, Icon, Link, ClientStepper, ViewContext, AuthContext } from 'components/lib'
import { ApiContext } from 'context/api'
import { ErrorNotification, UpdateNotification } from 'utils/notifications'
import Style from './header.tailwind'
import { IsUserAllowed } from 'utils/permission'

export const ClientHeader = (props) => {
    const viewContext = useContext(ViewContext)
    const authContext = useContext(AuthContext)
    const {
        companiesCount,
        companyProfile,
        handleFetchCompaniesRequest,
        setButtonLoading,
        handleGenerateMagicLinkRequest,
        handleClientNotificationRequest,
        setDateFrom: contextSetDateFrom,
        setDateTo: contextSetDateTo,
        setPage,
        setIsDateFilterActive,
        isDateFilterActive,
        setRefresh,
        setRefreshTransactions,
        refreshTransactions,
        handleFetchCompanyInvitesRequest,
    } = useContext(ApiContext)
    // const { REACT_APP_50_CLIENTS, REACT_APP_150_CLIENTS } = process.env

    const navigate = useNavigate()
    const settings = ['General Settings', 'Notification Settings', 'Client Users', 'Other Settings']
    const pathname = window.location.pathname
    // Get the first client admin from the clients array
    const clientAdmin = companyProfile?.clients?.find((client) => client?.permission === 'user')
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()
    const dateFilterFrom = useRef()
    const dateFilterTo = useRef()

    function isClientAllowed(clients = [], allowedPermissions = []) {
        const userId = authContext?.user?.accounts[0]?.user_id
        const clientData = clients.find((client) => client.id === userId)
        return allowedPermissions.includes(clientData?.permission)
    }

    async function handleNotifyAllButton() {
        setButtonLoading(true)
        let sentCount = 0
        sentCount = 0
        const fetchCompaniesResponse = await handleFetchCompaniesRequest(null)
        /* for (let i = 0; i < fetchCompaniesResponse?.data?.length; i++) {
            const email = fetchCompaniesResponse?.data[i]?.clients?.find((client) => client?.permission === 'user' || client?.permission === 'clientStaff')?.email
            if (fetchCompaniesResponse?.data[i]?.transactions && (fetchCompaniesResponse?.data[i]?.invitedClient || fetchCompaniesResponse?.data[i]?.isActive)) {
                sentCount += 1
                const generateMagicLinkResponse = await handleGenerateMagicLinkRequest(email)
                await handleClientNotificationRequest({ clientEmail: email, companyTransactions: fetchCompaniesResponse?.data[i]?.transactions, magicLink: generateMagicLinkResponse }, handleOnLoadActions)
            }
        } */
        for (let i = 0; i < fetchCompaniesResponse?.data?.length; i++) {
            for (let j = 0; j < fetchCompaniesResponse?.data[i]?.clients?.length; j++) {
                sentCount += 1
                const generateMagicLinkResponse = await handleGenerateMagicLinkRequest(fetchCompaniesResponse?.data[i]?.clients[j]?.email)
                await handleClientNotificationRequest({ clientEmail: fetchCompaniesResponse?.data[i]?.clients[j]?.email, companyTransactions: fetchCompaniesResponse?.data[i]?.transactions, magicLink: generateMagicLinkResponse }, handleOnLoadActions)
            }
        }
        if (sentCount) {
            UpdateNotification({ title: 'Client Notification', message: 'Notification was successfully sent.' })
        } else {
            handleOnLoadActions()
            return UpdateNotification({ title: 'Client Notification', message: 'All clients has not yet been invited or active.', icon: <IconInfoCircle /> })
        }
    }

    async function handleNotifyClientNowButton() {
        setButtonLoading(true)
        if (companyProfile?.transactions && (companyProfile?.invitedClient || companyProfile?.isActive)) {
            const invitedClient = await handleFetchCompanyInvitesRequest(companyProfile?.id, handleOnLoadActions)
            const generateMagicLinkResponse = await handleGenerateMagicLinkRequest(clientAdmin?.email)
            await handleClientNotificationRequest({ clientEmail: clientAdmin?.email ?? invitedClient?.email, companyTransactions: companyProfile?.transactions, magicLink: generateMagicLinkResponse }, handleOnLoadActions)
            return UpdateNotification({ title: 'Notification', message: 'Successfully sent.' })
        } else {
            handleOnLoadActions()
            return UpdateNotification({ title: 'Client Notification', message: 'The client is not yet been invited or activated.', icon: <IconInfoCircle />, color: 'red' })
        }
    }

    function handleAddClient() {
        viewContext.modal.show({
            body: <ClientStepper />,
        })
    }

    function handleDateFilter() {
        viewContext.modal.show({
            title: 'Select Date Range',
            body: (
                <>
                    <div className='flex justify-between align-items mb-4'>
                        <div className='flex flex-col align-items gap-2'>
                            <span>From:</span>
                            <input ref={dateFilterFrom} id='dateFrom' onChange={handleDateOnChange} defaultValue={dateFrom} className='border border-solid border-gray-500 rounded p-1' type='date' />
                        </div>
                        <span className='self-center mt-4'>-</span>
                        <div className='flex flex-col align-items gap-2'>
                            <span>To:</span>
                            <input ref={dateFilterTo} id='dateTo' onChange={handleDateOnChange} defaultValue={dateTo} className='border border-solid border-gray-500 rounded p-1' type='date' />
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        <span onClick={handleSaveFilter} className='text-blue-500 underline cursor-pointer'>
                            Save Filter
                        </span>
                        <span onClick={handleResetFilter} className='text-red-500 underline cursor-pointer'>
                            Reset Filter
                        </span>
                    </div>
                </>
            ),
        })
    }

    function handleDateOnChange(e) {
        const { id, value } = e.target
        if (id === 'dateFrom') {
            setDateFrom(value)
        } else if (id === 'dateTo') {
            setDateTo(value)
        }
    }

    function handleSaveFilter() {
        const from = dateFilterFrom?.current.value
        const to = dateFilterTo?.current.value || new Date().toISOString().split('T')[0]
        if (!from) return ErrorNotification({ title: 'Date filter', message: 'From field value is required' })
        setPage(1)
        contextSetDateFrom(from)
        contextSetDateTo(to)
        setIsDateFilterActive(true)
        viewContext.modal.hide()
        viewContext.setLoading(true)
    }

    function handleResetFilter() {
        isDateFilterActive && viewContext.setLoading(true)
        viewContext.modal.hide()
        setDateFrom(undefined)
        setDateTo(undefined)
        setPage(1)
        contextSetDateFrom('1800-01-01')
        contextSetDateTo(new Date().toISOString().split('T')[0])
        setIsDateFilterActive(false)
    }

    function handleOnLoadActions() {
        viewContext.setLoading(false)
        setButtonLoading(false)
    }

    function handleRefreshTransactions() {
        setRefreshTransactions(true)
        setRefresh((prev) => prev + 1)
        viewContext.setLoading(true)
    }

    useEffect(() => {
        if (!isDateFilterActive) {
            setDateFrom(undefined)
            setDateTo(undefined)
        }
    }, [isDateFilterActive])

    if (settings.includes(props.view)) {
        return (
            <div className={Style.container}>
                <section className={Style.components}>
                    <div className={Style.firstComponentItem}>
                        <span className='font-bold text-3xl'>{companyProfile?.companyName} Settings</span>
                    </div>

                    <div className={Style.secondComponentItem}>
                        <ClientButton callback={() => navigate(`/company/${companyProfile?.id}/transaction`)} bg='bg-red-500' icon='x-circle' size={20} color='text-white' label='Close' />
                    </div>
                </section>
            </div>
        )
    }

    return (
        <div className={Style.container}>
            <section className={Style.components}>
                <div className={Style.firstComponentItem}>
                    <Fragment>
                        <div className='flex items-center gap-1'>
                            <h2 className={Style.header2}>{props?.title}</h2>
                            {IsUserAllowed(authContext?.user?.permission, ['owner', 'staff']) && props.view === 'Company Transactions' && (
                                <Link color='text-gray-500' url={`/company/${companyProfile?.id}/general-settings`}>
                                    <Tooltip label='Navigate to client settings' color='cyan' withArrow>
                                        <span className='cursor-pointer'>
                                            <Icon image='settings' size={18} />
                                        </span>
                                    </Tooltip>
                                </Link>
                            )}
                            {isClientAllowed(companyProfile?.clients, ['user']) && props.view === 'Company Transactions' && (
                                <Link color='text-gray-500' url={`/company/${companyProfile?.id}/client-users`}>
                                    <Tooltip label='Navigate to client settings' color='cyan' withArrow>
                                        <span className='cursor-pointer'>
                                            <Icon image='settings' size={18} />
                                        </span>
                                    </Tooltip>
                                </Link>
                            )}
                        </div>
                        {viewContext.loading ? (
                            <span className={Style.subtextContainer}>
                                <Loader color='cyan' size='sm' variant='bars' />
                            </span>
                        ) : (
                            <div className={Style.subtextContainer}>
                                {pathname === '/company' && (
                                    <>
                                        <span className={Style.subtextCount}>{companiesCount}</span>
                                        &nbsp;
                                    </>
                                )}
                                <span>{props.description}</span>
                                &nbsp;
                                {IsUserAllowed(authContext?.user?.permission, ['owner', 'staff']) && props.view === 'Company Transactions' && (
                                    <Tooltip label='Refresh Transactions.' color='cyan' withArrow>
                                        <button disabled={refreshTransactions} onClick={handleRefreshTransactions} style={{ background: 'rgb(6 182 212)', padding: '3px 5px', borderRadius: 3, color: '#fff' }}>
                                            <Icon className='inline' image='refresh-cw' size={18} />
                                        </button>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    </Fragment>
                </div>
                <div className={Style.secondComponentItem}>
                    <div className='flex justify-between items-center'>
                        <ClientSearchbar view={props.view} permission={props.permission} />
                    </div>
                    {IsUserAllowed(authContext?.user?.permission, ['owner', 'staff']) && (
                        <div className='flex justify-between items-center gap-1'>
                            {props.view === 'Company' && <ClientButton bg='hover:bg-cyan-500 bg-cyan-400' icon='plus-circle' color='text-white' label='Add Client' callback={handleAddClient} />}

                            {companiesCount ? (
                                <ClientButton bg='hover:bg-cyan-500 bg-cyan-400' icon='bell' color='text-white' label={props.view === 'Company Transactions' ? 'Notify Client Now' : 'Notify All'} callback={props.view === 'Company Transactions' ? handleNotifyClientNowButton : handleNotifyAllButton} />
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                    {props.view === 'Company Transactions' && <ClientButton bg={!isDateFilterActive ? 'hover:bg-cyan-500 bg-cyan-400' : 'hover:bg-red-500 bg-red-400'} icon='calendar' color='text-white' label='Date Filter' callback={handleDateFilter} />}
                </div>
            </section>
        </div>
    )
}
