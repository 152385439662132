/***
 *
 *   TaxPal LANDING PAGE LAYOUT
 *   Main website layout
 *
 **********/

import { Fragment } from 'react'
import { TPHeader, TPFooter } from 'components/lib'

export function HomeLandingPage(props) {
    return (
        <Fragment>
            <TPHeader />
            <main>{<props.children />}</main>
            <TPFooter />
        </Fragment>
    )
}
