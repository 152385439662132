import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { /*  Link, */ useLocation } from 'react-router-dom'

import { TPContainer, TPNavLink, TPButton } from 'components/lib'
import logo from './images/logos/logo-color.svg'
//import logo from './images/logos/logo-mark-white.svg'

// function MobileNavLink({ href, children }) {
//     return (
//         <Popover.Button as={Link} href={href} className='block w-full p-2'>
//             {children}
//         </Popover.Button>
//     )
// }

function MobileNavIcon({ open }) {
    return (
        <svg aria-hidden='true' className='h-3.5 w-3.5 overflow-visible stroke-slate-700' fill='none' strokeWidth={2} strokeLinecap='round'>
            <path d='M0 1H14M0 7H14M0 13H14' className={clsx('origin-center transition', open && 'scale-90 opacity-0')} />
            <path d='M2 2L12 12M12 2L2 12' className={clsx('origin-center transition', !open && 'scale-90 opacity-0')} />
        </svg>
    )
}

function MobileNavigation() {
    return (
        <Popover>
            <Popover.Button className='relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none' aria-label='Toggle Navigation'>
                {({ open }) => <MobileNavIcon open={open} />}
            </Popover.Button>
            <Transition.Root>
                <Transition.Child as={Fragment} enter='duration-150 ease-out' enterFrom='opacity-0' enterTo='opacity-100' leave='duration-150 ease-in' leaveFrom='opacity-100' leaveTo='opacity-0'>
                    <Popover.Overlay className='fixed inset-0 bg-slate-300/50' />
                </Transition.Child>
                <Transition.Child as={Fragment} enter='duration-150 ease-out' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='duration-100 ease-in' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
                    <Popover.Panel as='div' className='absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5'>
                        {/* <MobileNavLink href='/#features'>Features</MobileNavLink>
                        <MobileNavLink href='/#testimonials'>Testimonials</MobileNavLink>
                        <MobileNavLink href='/#pricing'>Pricing</MobileNavLink>
                        <hr className='m-2 border-slate-300/40' />
                        <MobileNavLink href='/signin'>Sign in</MobileNavLink> */}

                        <Popover.Button className='block w-full p-2 text-left'>
                            <a href='/#features' className='block'>
                                Features
                            </a>
                        </Popover.Button>
                        <Popover.Button className='block w-full p-2 text-left'>
                            <a href='/#testimonials' className='block'>
                                Testimonials
                            </a>
                        </Popover.Button>
                        <Popover.Button className='block w-full p-2 text-left'>
                            <a href='/#pricing' className='block'>
                                Pricing
                            </a>
                        </Popover.Button>
                        <Popover.Button className='block w-full p-2 text-left'>
                            <a href='https://docs.categorize.me' className='block'>
                                Tutorials
                            </a>
                        </Popover.Button>
                        <Popover.Button className='block w-full p-2 text-left'>
                            <a href='https://blog.categorize.me' className='block'>
                                Blog
                            </a>
                        </Popover.Button>
                        <hr className='m-2 border-slate-300/40' />
                        <Popover.Button className='block w-full p-2 text-left'>
                            <a href='/signin' className='block'>
                                Signin
                            </a>
                        </Popover.Button>
                    </Popover.Panel>
                </Transition.Child>
            </Transition.Root>
        </Popover>
    )
}

export function TPHeader() {
    const location = useLocation()

    return (
        <header className='py-10'>
            <TPContainer>
                <nav className='relative z-50 flex justify-between'>
                    <div className='flex items-center md:gap-x-12'>
                        <TPNavLink href='/' aria-label='Home'>
                            {<img src={logo} alt='' width={200} unoptimized='true' />}
                        </TPNavLink>
                        {location?.pathname === '/' && (
                            <div className='hidden md:flex md:gap-x-6'>
                                <a href='/#features'>Features</a>
                                <a href='/#testimonials'>Testimonials</a>
                                <a href='/#pricing'>Pricing</a>
                                <a href='https://docs.categorize.me'>Tutorials</a>
                                <a href='https://blog.categorize.me'>Blog</a>
                            </div>
                        )}
                    </div>
                    <div className='flex items-center gap-x-5 md:gap-x-8'>
                        {location?.pathname === '/' && (
                            <>
                                <div className='hidden md:block'>
                                    <TPNavLink href='/signin'>Sign in</TPNavLink>
                                </div>
                                <TPButton href='/signup' color='blue'>
                                    <span>
                                        Get started <span className='hidden lg:inline'>today</span>
                                    </span>
                                </TPButton>
                            </>
                        )}
                        <div className='-mr-1 md:hidden'>
                            <MobileNavigation />
                        </div>
                    </div>
                </nav>
            </TPContainer>
        </header>
    )
}
