/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/
import React, { Fragment, useContext, useState, useEffect } from 'react'
import { MultiSelect, Select, TextInput } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'

import { ApiContext } from 'context/api'
import { ViewContext, Card, Table, Button, Animate, TitleRow, useAPI, Event, ClientButton, AuthContext, SettingsNav } from 'components/lib'
import { UpdateNotification, ErrorNotification } from 'utils/notifications'
import Axios from 'utils/axios'

export function ClientUsers(props) {
    const viewContext = useContext(ViewContext)
    const authContext = useContext(AuthContext)
    const { handleEditStaffCompanyListRequest, handleFetchCompanyProfileRequest, setButtonLoading, handleInviteStaffRequest, companyProfile, handleFetchListRequest, handleFetchPaymentAccountsRequest, paymentAccounts, handleFetchAccountClientsRequest } = useContext(ApiContext)

    const pathname = window.location.pathname
    const companyId = pathname.split('/')[2]
    const id = companyId === ':companyId' ? companyProfile?.id : companyId

    const data = useAPI('/api/v1/account/clients')

    const [users, setUsers] = useState()

    function handleRemoveAddUserLSData() {
        localStorage.removeItem('addClientPayloadEmail')
        localStorage.removeItem('addClientPayloadPermission')
        localStorage.removeItem('addClientPayloadPaymentAccounts')
    }

    function handleCloseAddUserModal() {
        viewContext.modal.hide()
        handleRemoveAddUserLSData()
    }

    function handleAddUserOnchange(props, field) {
        if (field === 'email') {
            localStorage.setItem('addClientPayloadEmail', props.target.value)
        } else if (field === 'permission') {
            if (props === 'user') {
                localStorage.removeItem('addClientPayloadPaymentAccounts')
            }
            localStorage.setItem('addClientPayloadPermission', props)
            invite(localStorage.getItem('addClientPayloadPermission'))
        } else if (field === 'paymentAccounts') {
            const paymentAccountsPayload = paymentAccounts
                ?.filter((paymentAccount) => props.includes(paymentAccount.id))
                .map(({ id, value }) => ({
                    id,
                    value,
                    companyId: companyProfile.id,
                }))
            localStorage.setItem('addClientPayloadPaymentAccounts', JSON.stringify(paymentAccountsPayload))
        }
    }

    async function handleAddUserRequest() {
        setButtonLoading(true)
        const payload = {
            email: localStorage.getItem('addClientPayloadEmail'),
            permission: localStorage.getItem('addClientPayloadPermission') || 'clientStaff',
            companies: Array(companyProfile?._id),
            paymentAccounts: JSON.parse(localStorage.getItem('addClientPayloadPaymentAccounts')) || [],
            companyId: id,
        }
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (payload.email && !re.test(payload.email)) {
            return ErrorNotification({
                title: 'Invalid email',
                message: 'Please enter a valid email address',
                icon: <IconInfoCircle />,
            })
        }
        await handleInviteStaffRequest(payload, handleAddUserRequestCallback)
        handleRemoveAddUserLSData()
    }

    async function handleAddUserRequestCallback(data) {
        if (data.length) {
            viewContext.modal.hide()
            const state = [...users]

            data.forEach((invite) => {
                if (!state.find((user) => user.id === invite.id)) {
                    state.push({
                        id: invite.id,
                        companies: invite?.companies,
                        name: '--',
                        email: invite.email,
                        date_created: invite.date_sent,
                        permission: invite.permission === 'user' ? 'Client Admin' : 'Client Staff',
                        status: 'Invited',
                        actions: {
                            invite: resendInvite,
                            delete: deleteInvite,
                        },
                    })
                }
            })

            Event('invited_user')
            setUsers(state)
        } else {
            return ErrorNotification({
                title: 'Notification',
                message: data.response.data.message,
                icon: <IconInfoCircle />,
            })
        }
    }

    function invite(permission) {
        viewContext.modal.show({
            title: 'Add Staff',
            body: (
                <div className='flex flex-col justify-center pb-4 gap-5'>
                    <p>To invite more than one user, seperate the emails with a comma</p>
                    <div>
                        <p className='!m-0'>
                            Email <span className='text-red-500'>*</span>
                        </p>
                        <TextInput defaultValue='' placeholder='Email...' onChange={(e) => handleAddUserOnchange(e, 'email')} required />
                    </div>
                    {/* <div>
                        <p className='!m-0'>
                            Permission <span className='text-red-500'>*</span>
                        </p>
                        <Select
                            styles={{ defaultValueLabel: { overflow: 'unset' } }}
                            placeholder='Permission...'
                            defaultValue={permission === 'user' ? 'clientStaff' : 'staff'}
                            data={permissions?.data?.list?.filter((x) => x.value !== 'admin' && x.value !== 'owner' && x.value !== 'user' && x.value !== (permission === 'user' ? 'staff' : 'clientStaff'))}
                            onChange={(e) => handleAddUserOnchange(e, 'permission')}
                            dropdownPosition='top'
                        />
                    </div> */}
                    {/* <div>
                        <p className='!m-0'>Assigned Client(s)</p>
                        <MultiSelect
                            styles={{ defaultValueLabel: { overflow: 'unset' }, input: { maxHeight: '300px', overflowY: 'scroll', borderRadius: 'unset' } }}
                            searchable
                            placeholder='Add company'
                            nothingFound='Nothing found'
                            data={companies}
                            onChange={(companies) => handleAddUserOnchange(companies, 'companies')}
                            dropdownPosition='top'
                        />
                    </div> */}
                    <div>
                        <p className='!m-0'>
                            Permission <span className='text-red-500'>*</span>
                        </p>
                        <Select
                            styles={{ defaultValueLabel: { overflow: 'unset' } }}
                            placeholder='Permission...'
                            data={[
                                { value: 'user', label: 'Client Admin' },
                                { value: 'clientStaff', label: 'Client Staff' },
                            ]}
                            onChange={(e) => handleAddUserOnchange(e, 'permission')}
                            dropdownPosition='top'
                        />
                    </div>
                    <div>
                        <p className='!m-0'>Assigned Payment Account(s)</p>
                        <MultiSelect
                            disabled={permission === 'user'}
                            styles={{ defaultValueLabel: { overflow: 'unset' }, input: { maxHeight: '300px', overflowY: 'scroll', borderRadius: 'unset' } }}
                            searchable
                            placeholder='Add payment account(s)'
                            nothingFound='Nothing found'
                            defaultValue={JSON.parse(localStorage.getItem('addClientPayloadPaymentAccounts'))?.map((account) => account.id)}
                            data={handleFormatAccountLists(paymentAccounts)}
                            onChange={(accounts) => handleAddUserOnchange(accounts, 'paymentAccounts')}
                            dropdownPosition='top'
                        />
                    </div>
                    <div className='flex justify-center items-center gap-2'>
                        <ClientButton bg='bg-cyan-600' color='text-white' icon='' label='Save' width='!w-full' callback={handleAddUserRequest} />
                        <ClientButton bg='bg-red-500' color='text-white' icon='' label='Cancel' width='!w-full' callback={handleCloseAddUserModal} />
                    </div>
                </div>
            ),
        })
    }

    function handleFormatAccountLists(accounts) {
        return accounts?.map((account) => ({
            value: account?.id,
            label: account?.value,
        }))
    }

    function handleRemoveEditUserLSData() {
        localStorage.removeItem('editClientPayloadName')
        localStorage.removeItem('editClientPayloadEmail')
        localStorage.removeItem('editPayloadPermission')
        localStorage.removeItem('editClientPayloadPaymentAccounts')
    }

    function handleCloseEditUserModal() {
        viewContext.modal.hide()
        handleRemoveEditUserLSData()
    }

    function handleEditUserOnchange(props, field, data = null) {
        if (field === 'name') {
            localStorage.setItem('editClientPayloadName', props.target.value)
        } else if (field === 'email') {
            localStorage.setItem('editClientPayloadEmail', props.target.value)
        } else if (field === 'permission') {
            if (props === 'user') {
                localStorage.removeItem('editClientPayloadPaymentAccounts')
            }
            localStorage.setItem('editPayloadPermission', props)
            editUser(data, null, localStorage.getItem('editPayloadPermission'))
        } else if (field === 'paymentAccounts') {
            const paymentAccountsPayload = paymentAccounts
                ?.filter((paymentAccount) => props.includes(paymentAccount.id))
                .map(({ id, value }) => ({
                    id,
                    value,
                    companyId: companyProfile.id,
                }))
            localStorage.setItem('editClientPayloadPaymentAccounts', JSON.stringify(paymentAccountsPayload))
        }
    }

    async function handleEditUserRequest(data) {
        const { id } = data
        viewContext.modal.hide()
        setButtonLoading(true)
        const payload = {
            id,
            name: localStorage.getItem('editClientPayloadName') || data.name,
            email: localStorage.getItem('editClientPayloadEmail') || data.email,
            // companies: JSON.parse(localStorage.getItem('editPayloadCompanies')) || data.companies,
            // companies: Array(companyProfile?._id),
            permission: localStorage.getItem('editPayloadPermission') || (data?.permission === 'Client Staff' ? 'clientStaff' : 'user'),
            paymentAccounts: JSON.parse(localStorage.getItem('editClientPayloadPaymentAccounts')) || (localStorage.getItem('editPayloadPermission') === 'clientStaff' ? data?.paymentAccounts : []),
            companyId: companyProfile?.id,
            editClients: true,
        }
        const companyDataProfile = await handleFetchCompanyProfileRequest(companyProfile?.id, handleOnLoadActions, true)
        const clientAdmins = companyDataProfile?.clients?.filter((client) => client.permission === 'user')
        if (companyDataProfile?.clients?.length < 2 && clientAdmins?.length < 2 && payload.permission === 'clientStaff') {
            handleRemoveEditUserLSData()
            return ErrorNotification({
                title: 'Failed in editing user',
                message: 'A Client Admin must be assigned to this company. If you wish to delete or change the permissions of this user you must first add a new Client Admin.',
                icon: <IconInfoCircle />,
            })
        }
        await handleEditStaffCompanyListRequest(payload)
        const clientsData = await handleFetchAccountClientsRequest(handleOnLoadActions)
        const updatedCompanyDataProfile = await handleFetchCompanyProfileRequest(companyDataProfile?.id, handleOnLoadActions, true)
        handleFormatUserList(clientsData, updatedCompanyDataProfile)
        handleRemoveEditUserLSData()
    }

    function editUser(data, callback, permission = null) {
        const paymentAccountsValue = JSON.parse(localStorage.getItem('editClientPayloadPaymentAccounts')) ? [...data?.paymentAccounts, ...JSON.parse(localStorage.getItem('editClientPayloadPaymentAccounts'))] : data?.paymentAccounts
        const filteredPaymentAccountsByCompanyId = paymentAccountsValue.filter((account) => account.companyId === companyProfile.id)
        viewContext.modal.show({
            title: 'Update User',
            body: (
                <div className='flex flex-col justify-center pb-4 gap-5'>
                    <div>
                        <p className='!m-0'>
                            Name <span className='text-red-500'>*</span>
                        </p>
                        <TextInput disabled defaultValue={localStorage.getItem('editClientPayloadName') || data.name} placeholder='Name...' onChange={(e) => handleEditUserOnchange(e, 'name')} />
                    </div>
                    <div>
                        <p className='!m-0'>
                            Email <span className='text-red-500'>*</span>
                        </p>
                        <TextInput disabled defaultValue={localStorage.getItem('editClientPayloadEmail') || data.email} placeholder='Email...' onChange={(e) => handleEditUserOnchange(e, 'email')} />
                    </div>
                    <div>
                        <p className='!m-0'>
                            Permission <span className='text-red-500'>*</span>
                        </p>
                        <Select
                            defaultValue={localStorage.getItem('editPayloadPermission') || (data.permission === 'Client Staff' ? 'clientStaff' : 'user')}
                            styles={{ defaultValueLabel: { overflow: 'unset' } }}
                            placeholder='Permission...'
                            data={[
                                { value: 'user', label: 'Client Admin' },
                                { value: 'clientStaff', label: 'Client Staff' },
                            ]}
                            onChange={(e) => handleEditUserOnchange(e, 'permission', data)}
                            dropdownPosition='top'
                        />
                    </div>
                    <div>
                        <p className='!m-0'>Assigned Payment Account(s)</p>
                        <MultiSelect
                            disabled={permission ? permission === 'user' : data.permission === 'Client Admin'}
                            styles={{ defaultValueLabel: { overflow: 'unset' }, input: { maxHeight: '300px', overflowY: 'scroll', borderRadius: 'unset' } }}
                            searchable
                            placeholder='Add payment account(s)'
                            nothingFound='Nothing found'
                            defaultValue={data.permission === 'Client Admin' ? [] : [...new Set(filteredPaymentAccountsByCompanyId.map((account) => account.id))]}
                            data={handleFormatAccountLists(paymentAccounts)}
                            onChange={(accounts) => handleEditUserOnchange(accounts, 'paymentAccounts')}
                            dropdownPosition='top'
                        />
                    </div>
                    <div className='flex justify-center items-center gap-2'>
                        <ClientButton bg='bg-cyan-600' color='text-white' icon='' label='Save' width='!w-full' callback={() => handleEditUserRequest(data)} />
                        <ClientButton bg='bg-red-500' color='text-white' icon='' label='Cancel' width='!w-full' callback={handleCloseEditUserModal} />
                    </div>
                </div>
            ),
            overflow: 'hidden',
        })
    }

    async function deleteUser(data, callback) {
        const companyDataProfile = await handleFetchCompanyProfileRequest(id, handleOnLoadActions, true)
        const clientAdmins = companyDataProfile?.clients?.filter((client) => client.permission === 'user')
        if (clientAdmins?.length < 2 && data.permission === 'Client Admin') {
            return ErrorNotification({
                title: 'Failed in deleting user',
                message: 'A Client Admin must be assigned to this company. If you wish to delete or change the permissions of this user you must first add a new Client Admin.',
                icon: <IconInfoCircle />,
            })
        }
        return viewContext.modal.show(
            {
                title: 'Delete User',
                form: {},
                buttonText: 'Delete User',
                text: `Are you sure you want to delete ${data.name}?`,
                url: `/api/v1/user/${data.id}?companyId=${companyDataProfile?.id}`,
                method: 'DELETE',
                destructive: true,
            },
            () => {
                // viewContext.notification.show(data.name + ' was deleted', 'success', true)
                UpdateNotification({ title: 'Delete User', message: `${data.name} was deleted.` })
                callback()
            }
        )
    }

    async function deleteInvite(data, callback) {
        const companyDataProfile = await handleFetchCompanyProfileRequest(id, handleOnLoadActions, true)
        const clientAdmins = companyDataProfile?.clients?.filter((client) => client?.permission === 'user')
        if (clientAdmins?.length < 1) {
            return ErrorNotification({
                title: 'Failed in deleting user',
                message: 'A Client Admin must be assigned to this company. If you wish to delete or change the permissions of this user you must first add a new Client Admin.',
                icon: <IconInfoCircle />,
            })
        }
        viewContext.modal.show(
            {
                title: 'Delete Invite',
                form: {},
                buttonText: 'Delete Invite',
                text: `Are you sure you want to delete the invite for ${data.email}?`,
                url: `/api/v1/invite/${data.id}`,
                method: 'DELETE',
                destructive: true,
            },
            () => {
                // const state = [
                //     ...users.splice(
                //         users.find((x) => x.id === data.id),
                //         1
                //     ),
                // ]
                const newUsers = users.filter((user) => user.id !== data.id)
                UpdateNotification({ title: 'Delete Invite', message: `${data.email}'s invite was deleted.` })
                setUsers(newUsers)
            }
        )
    }

    async function resendInvite(data) {
        try {
            await Axios({
                url: '/api/v1/invite',
                method: 'post',
                data: { email: data.email },
            })

            viewContext.notification.show(`Invite re-sent to ${data.email}`, 'success', true)
        } catch (err) {
            viewContext.handleError(err)
        }
    }

    async function handleFormatUserList(userPayload, compProfile) {
        const companyClients = compProfile.clients || []
        let list = []
        if (userPayload?.data?.users?.length) {
            for (let i = 0; i < userPayload.data.users.length; i++) {
                if (userPayload.data.users[i].permission === 'user' || userPayload.data.users[i].permission === 'clientStaff') {
                    list.push({
                        id: userPayload.data.users[i].id,
                        name: userPayload.data.users[i].name,
                        email: userPayload.data.users[i].email,
                        date_created: userPayload.data.users[i].date_created,
                        permission: companyClients.find((client) => client.id === userPayload.data?.users[i].id)?.permission === 'user' ? 'Client Admin' : 'Client Staff',
                        companies: userPayload.data.users[i].companies,
                        paymentAccounts: userPayload.data.users[i]?.paymentAccounts || [],
                        status: 'Registered',
                        actions: {
                            edit: editUser,
                            email: true,
                            delete: deleteUser,
                        },
                    })
                }
            }
        }
        if (userPayload?.data?.invites?.length) {
            for (let i = 0; i < userPayload.data.invites.length; i++) {
                if (userPayload.data.invites[i].permission === 'user' || userPayload.data.invites[i].permission === 'clientStaff') {
                    list.push({
                        id: userPayload.data.invites[i].id,
                        companies: userPayload.data.invites[i].companies,
                        name: '--',
                        email: userPayload.data.invites[i].email,
                        date_created: userPayload.data.invites[i].date_sent,
                        permission: userPayload.data.invites[i].permission === 'user' ? 'Client Admin' : 'Client Staff',
                        status: 'Invited',
                        actions: {
                            invite: resendInvite,
                            delete: deleteInvite,
                        },
                    })
                }
            }
        }
        // Filter by user id
        const filteredList0 = authContext?.user?.permission === 'user' ? list.filter((user) => user.id !== authContext?.user?.accounts[0]?.user_id) : list
        // Filter by company
        const filteredList1 = filteredList0.filter((user) => compProfile?.clients?.some((client) => client.id === user.id) || user?.companies?.includes(compProfile?._id))
        setUsers(filteredList1)
    }

    function handleOnLoadActions() {
        setButtonLoading(false)
    }

    /* eslint-disable */
    useEffect(() => {
        // Fetch company profile
        !companyProfile && handleFetchCompanyProfileRequest(id, handleOnLoadActions)

        // Fetch entities
        handleFetchListRequest(id, handleOnLoadActions)

        // Fetch Payment Accounts
        !paymentAccounts && handleFetchPaymentAccountsRequest(id, handleOnLoadActions)

        // format the user list
        if (data && companyProfile) {
            handleFormatUserList(data, companyProfile)
        }
    }, [data, companyProfile, paymentAccounts])

    useEffect(() => {
        paymentAccounts && handleFetchPaymentAccountsRequest(id, handleOnLoadActions)
    }, [])

    // attach the per row actions for invites
    // if (users && users.length) {
    //     users.forEach((u) => {
    //         if (u.status === 'Invited') {
    //             u.actions = {
    //                 invite: resendInvite,
    //                 delete: deleteInvite,
    //             }
    //         } else if (u.status === 'Registered') {
    //             u.actions = {
    //                 edit: editUser,
    //                 email: true,
    //                 delete: deleteUser,
    //             }
    //         }
    //     })
    // }

    if (!users) {
        return <div></div>
    }

    return (
        <Fragment>
            <SettingsNav companyId={id} />
            <Animate>
                <TitleRow title='Manage Clients'>
                    <Button small text='Add User' action={() => invite(localStorage.getItem('addClientPayloadPermission'))} />
                </TitleRow>

                {users && users?.length ? (
                    <Card>
                        <Table
                            search
                            className='restrict-width'
                            data={users}
                            loading={data.loading}
                            // show={['name', 'email', 'date_created', 'last_login', 'permission', 'status']}
                            show={['name', 'email', 'last_login', 'permission', 'status']}
                            badge={{
                                col: 'status',
                                color: 'blue',
                                condition: [
                                    { value: 'registered', color: 'green' },
                                    { value: 'invited', color: 'blue' },
                                ],
                            }}
                            // actions={{ edit: editUser, email: true, delete: deleteUser }}
                        />
                    </Card>
                ) : (
                    <div className='flex justify-center items-center' style={{ height: '50vh' }}>
                        <p>No available users.</p>
                    </div>
                )}
            </Animate>
        </Fragment>
    )
}
