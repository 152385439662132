import { createContext, useState, useContext } from 'react'
// import { io } from 'socket.io-client'

import { AuthContext } from 'components/lib'
import { ErrorNotification, UpdateNotification } from 'utils/notifications'
import Axios from 'utils/axios'

// const socket = io(Settings[process.env.NODE_ENV].server_url, { /* extraHeaders: { 'x-otp': res.data?.otp }, */ transports: ['websocket', 'polling', 'flashsocket'] })

export const ApiContext = createContext()

export function ApiProvider(props) {
    // CONTEXT
    const authContext = useContext(AuthContext)
    const defaultTotalPerPage = 20

    // STATES
    const [buttonLoading, setButtonLoading] = useState(false)
    const [companies, setCompanies] = useState()
    const [transactions, setTransactions] = useState()
    const [paymentAccounts, setPaymentAccounts] = useState()
    const [transactionsCount, setTransactionsCount] = useState()
    const [refreshTransactions, setRefreshTransactions] = useState(true)
    const [fetchTransactionType, setFetchTransactionType] = useState()
    const [refresh, setRefresh] = useState(0)
    const [lists, setLists] = useState({ vendors: [], customers: [], categories: [], classes: [], locations: [] })
    const [requestToken, setRequestToken] = useState()

    const [searchQuery, setSearchQuery] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [advSearchValue, setAdvSearchValue] = useState({})
    const [page, setPage] = useState(1)
    const [totalPerPage, setTotalPerPage] = useState(defaultTotalPerPage)
    const [sortField, setSortField] = useState('companyName')
    const [sortOrder, setSortOrder] = useState(1)

    const [categorizeMePrevPage, setCategorizeMePrevPage] = useState(1)
    const [underReviewPrevPage, setUnderReviewPrevPage] = useState(1)
    const [approvedPrevPage, setApprovedPrevPage] = useState(1)

    const [companyProfile, setCompanyProfile] = useState()
    const [companiesCount, setCompaniesCount] = useState()
    const [company, setCompany] = useState('')
    const [client, setClient] = useState('')
    const [status, setStatus] = useState('')
    const [activity, setActivity] = useState('')
    const [dateFrom, setDateFrom] = useState('1800-01-01')
    const [dateTo, setDateTo] = useState(new Date().toISOString().split('T')[0])
    const [isDateFilterActive, setIsDateFilterActive] = useState(false)
    const [view, setView] = useState(window.screen.width <= 900 ? 1 : 0)
    const [isToggleViewActive, setIsToggleViewActive] = useState(true)
    const [isToggleApproveAllActive, setIsToggleApproveAllActive] = useState(false)
    const [isToggleBulkChangeActive, setIsToggleBulkChangeActive] = useState(false)
    const [clients, setClients] = useState()
    const [selectedListItemForBulkChange, setSelectedListItemForBulkChange] = useState()
    const [selectedBulkOption, setSelectedBulkOption] = useState('')
    const [triggerActivityLogFilter, setTriggerActivityLogFilter] = useState(false)
    const [companyFilterLogs, setCompanyFilterLogs] = useState('')
    const [staffFilterLogs, setStaffFilterLogs] = useState('')
    const [clientFilterLogs, setClientFilterLogs] = useState('')
    const [clientStaffFilterLogs, setClientStaffFilterLogs] = useState('')
    const [splitTransactions, setSplitTransactions] = useState()

    // ACCOUNTS
    async function handleFetchAccountClientsApi(callback) {
        try {
            const response = await Axios({
                url: '/api/v1/account/clients',
                method: 'get',
            })
            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    // COMPANIES
    async function handleFetchCompaniesApi(total, callback) {
        const payload = {
            accountId: authContext.user?.account_id,
            clientId: authContext.user?.accounts[0]?.user_id,
            page,
            totalPerPage,
            search: searchValue,
            advSearch: Object.keys(advSearchValue).length ? advSearchValue : null,
            sortField,
            sortOrder,
        }

        try {
            const response = await Axios({
                url: `/api/v1/company/list?total=${total || 0}`,
                method: 'post',
                data: payload,
            })
            if (response.status === 200) {
                setCompanies(response.data.data)
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleFetchCompanyProfileApi(companyId, callback, dontUpdateState) {
        try {
            const response = await Axios({
                url: `/api/v1/company/profile/${companyId}`,
                method: 'get',
            })
            if (response.status === 200) {
                !dontUpdateState && setCompanyProfile(response.data)
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleUpdateCompanyApi(payload, callback) {
        const { companyId, token, type = '0', ...others } = payload

        let requestObject = {
            url: '/api/v1/company/update',
            method: 'post',
            data: {
                type,
                companyId,
                ...others,
            },
        }

        if (token) {
            requestObject.headers = { Authorization: `Bearer ${token}` }
        }

        try {
            const response = await Axios(requestObject)
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleFetchCompaniesCountApi(callback) {
        try {
            const response = await Axios({
                url: '/api/v1/company/count',
                method: 'get',
            })

            if (response.status === 200) {
                setCompaniesCount(response.data.count)
                callback && callback()
                return response.data.count
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleDeleteCompanyApi(payload, callback) {
        try {
            await Axios({
                url: '/api/v1/company/delete',
                method: 'post',
                data: payload,
            })
            callback && callback()
            return true
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    // TRANSACTIONS
    async function handleFetchTransactionsApi(companyId, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/transaction/list',
                method: 'post',
                data: {
                    companyId,
                    page,
                    totalPerPage,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    search: searchValue,
                    refreshTransactions: refreshTransactions || JSON.parse(localStorage.getItem('refreshTransactions')),
                    fetchTransactionType: fetchTransactionType || 'underReview',
                },
            })
            if (response.status === 200) {
                if (!fetchTransactionType) {
                    if (!authContext.permission.staff) {
                        setFetchTransactionType('categorizeMe')
                    } else if (response.data.data.length > 0 && !fetchTransactionType) {
                        setFetchTransactionType('underReview')
                    } else {
                        setFetchTransactionType('categorizeMe')
                    }
                }

                setTransactions(response.data.data)
                setTransactionsCount(response.data.total)
                setRefreshTransactions(false)
                localStorage.removeItem('refreshTransactions')
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleFetchActivityLogsApi(callback) {
        try {
            const response = await Axios({
                url: '/api/v1/activitylog/list',
                method: 'post',
                data: {
                    page,
                    totalPerPage,
                    companyFilterLogs,
                    staffFilterLogs,
                    clientFilterLogs,
                    clientStaffFilterLogs,
                },
            })

            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            callback && callback()
            throw new Error()
        }
    }

    async function handleApproveChangesApi(transIds, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/transaction/deleteedited',
                method: 'post',
                data: {
                    transIds,
                    companyId: companyProfile?.id,
                },
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleUpdateTransactionApi(payload, callback) {
        const { companyId, isNoActionNeeded = false, ...others } = payload
        localStorage.setItem('refreshTransactions', JSON.stringify(true))
        try {
            const response = await Axios({
                url: '/api/v1/transaction/update',
                method: 'post',
                data: {
                    companyId,
                    payload: others,
                    isNoActionNeeded,
                },
            })
            if (response.status === 200) {
                callback && callback()
                return response
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleDeleteApprovedTransactionApi(payload, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/transaction/deleteapprovedtransaction',
                method: 'post',
                data: {
                    companyId: payload?.companyId,
                    transId: payload?.transId,
                    lineId: payload?.lineId,
                },
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleDeleteApprovedTransactionsApi(payload, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/transaction/deleteapproved',
                method: 'post',
                data: {
                    companyId: payload?.companyId,
                    transIds: payload?.transIds,
                    payloads: payload?.transactions,
                },
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleUpdateSplitTransactionsApi(payload, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/transaction/updatesplit',
                method: 'post',
                data: {
                    companyId: payload?.companyId,
                    transId: payload?.transId,
                    lineIds: payload?.lineIds,
                    payload: payload?.data,
                },
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleApproveSplitTransactionsApi(payload, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/transaction/approvesplit',
                method: 'post',
                data: {
                    companyId: payload?.companyId,
                    payload: payload?.data,
                },
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    // CLIENT
    async function handleUpdateClientsApi(payload, callback) {
        const { clientId, ...others } = payload
        try {
            const response = await Axios({
                url: '/api/v1/client/update',
                method: 'post',
                data: {
                    invite_id: clientId,
                    ...others,
                },
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            ErrorNotification({ title: 'Update error.', message: error?.message })
            callback && callback()
            throw new Error()
        }
    }

    async function handleClientNotificationApi(payload, callback) {
        const { clientEmail, companyTransactions, magicLink, message } = payload

        try {
            const response = await Axios({
                url: '/api/v1/client/notification',
                method: 'post',
                data: {
                    from: authContext?.user?.email,
                    to: clientEmail,
                    subject: `Action requested by ${authContext?.user?.accountingFirmName}`,
                    accountingFirmName: authContext?.user?.accountingFirmName,
                    trans: companyTransactions,
                    message,
                    magicLink,
                },
            })

            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    // OLD
    // async function handleClientInvitationApi(payload, callback) {
    //     const { companyId, email } = payload
    //     try {
    //         const response = await Axios({
    //             url: '/api/v1/client/invite',
    //             method: 'post',
    //             data: {
    //                 accountId: authContext.user.account_id,
    //                 companyId,
    //                 email,
    //                 sender: authContext.user.email,
    //             },
    //         })

    //         if (response.status === 200) {
    //             callback && callback()
    //             return true
    //         }
    //     } catch (error) {
    //         // ErrorNotification()
    //         callback && callback()
    //         throw new Error()
    //     }
    // }

    async function handleClientInvitationApi(payload, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/invite',
                method: 'post',
                data: {
                    ...payload,
                },
            })

            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleAccountingImmediateNotificationApi(editedTransactions, companyId, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/client/immediate/notification',
                method: 'post',
                data: {
                    from: authContext?.user?.email,
                    to: authContext?.user?.accounts[0]?.id,
                    subject: `Transaction updated by ${authContext?.user?.name}`,
                    companyName: authContext?.user?.name,
                    trans: editedTransactions,
                    companyId,
                },
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleClientCheckExistenceApi(payload, callback) {
        const { email, token } = payload
        try {
            const response = await Axios({
                url: `/api/v1/client/exist?email=${email}`,
                method: 'get',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    // STAFF
    async function handleFetchStaffCompaniesApi(callback) {
        const payload = {
            clientId: authContext.user?.accounts[0]?.user_id,
            page: `${page - 1}`,
            search: searchValue,
            advSearch: Object.keys(advSearchValue).length ? advSearchValue : undefined,
        }

        try {
            const response = await Axios({
                url: '/api/v1/staff/list',
                method: 'post',
                data: payload,
            })

            if (response.status === 200) {
                setCompanies(response.data.data)
                callback && callback()
                return response.data.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleEditStaffCompanyListApi(payload, callback) {
        // const { id, name, email, companies, paymentAccounts } = payload

        try {
            const response = await Axios({
                url: '/api/v1/user',
                method: 'patch',
                data: {
                    ...payload,
                },
            })

            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleFetchStaffUserAccountApi(callback) {
        try {
            const response = await Axios({
                url: '/api/v1/account/users',
                method: 'get',
            })

            if (response.status === 200) {
                callback && callback()
                return response
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleInviteStaffApi(payload, callback) {
        const { email, permission, companies, paymentAccounts, companyId } = payload

        try {
            const response = await Axios({
                url: '/api/v1/invite',
                method: 'post',
                data: {
                    email,
                    permission,
                    companies,
                    paymentAccounts,
                    companyId,
                },
            })

            if (response.status === 200) {
                callback && callback(response.data.data)
                return response.data.data
            }
        } catch (error) {
            callback && callback(error)
            throw new Error()
        }
    }

    // INTUIT
    async function handleGetAuthorizationUriApi(callback) {
        try {
            const response = await Axios({
                url: `/api/v1/intuit/auth`,
                method: 'get',
            })

            if (response.status === 200) {
                setRequestToken(response.data)
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleRequestTokenApi(params, callback) {
        try {
            const response = await Axios({
                url: `/api/v1/intuit/token?code=${params.get('code')}&state=${params.get('state')}&realmId=${params.get('realmId')}&accountId=${authContext?.user?.account_id}`,
                method: 'get',
            })

            if (response.status === 200) {
                setRequestToken(response.data)
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleFetchListApi(companyId, callback) {
        try {
            const response = await Axios({
                url: `/api/v1/intuit/list?companyId=${companyId}`,
                method: 'get',
            })

            if (response.status === 200) {
                setLists(response?.data)
                callback && callback()
                return response?.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleSetScheduleNotificationApi(payload, callback) {
        const { clientId, companyName, ...others } = payload
        try {
            const response = await Axios({
                url: '/api/v1/client/schedule/notification',
                method: 'post',
                data: {
                    clientId,
                    subject: `Action requested by ${authContext?.user?.name}`,
                    from: authContext?.user?.email,
                    companyName: companyName,
                    payload: { ...others },
                },
            })

            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleClientRevokeAccessApi(companyId, callback) {
        try {
            const response = await Axios({
                url: `/api/v1/intuit/revoke/${companyId}`,
                method: 'get',
            })

            if (response.status === 200) {
                UpdateNotification({
                    title: 'Company Disconnection',
                    message: 'Successfully disconnected',
                })
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleClientDisconnectionApi(realmId, callback) {
        try {
            const response = await Axios({
                url: `/api/v1/intuit/disconnect/${realmId}`,
                method: 'get',
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            callback && callback()
            throw new Error()
        }
    }

    async function handleUpdateIntuitTransactionApi(payload, callback) {
        payload?.isCreateCategory ? localStorage.setItem('refreshTransactions', JSON.stringify(false)) : localStorage.setItem('refreshTransactions', JSON.stringify(true))

        try {
            const response = await Axios({
                url: '/api/v1/intuit/update/transaction',
                method: 'post',
                data: payload,
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            setButtonLoading(false)
            callback && callback()
            throw new Error()
        }
    }

    async function handleUpdateIntuitTransactionsApi(payload, callback) {
        const { companyId, multipleUpdatePayload } = payload
        localStorage.setItem('refreshTransactions', JSON.stringify(true))
        try {
            const response = await Axios({
                url: '/api/v1/intuit/update/transactions',
                method: 'post',
                data: {
                    companyId,
                    payload: multipleUpdatePayload,
                },
            })

            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleAdminCreateListApi(payload, callback) {
        const { companyId, ...others } = payload
        try {
            const response = await Axios({
                url: '/api/v1/intuit/create',
                method: 'post',
                data: {
                    companyId,
                    ...others,
                },
            })

            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            ErrorNotification({
                title: error?.response?.data?.Message,
                message: error?.response?.data?.Detail || error?.statusText,
            })
            setButtonLoading(false)
            callback && callback()
            throw new Error()
        }
    }

    async function handleAddEntityApi(payload, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/intuit/create/entity',
                method: 'post',
                data: payload,
            })

            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            setButtonLoading(false)
            callback && callback()
            throw new Error()
        }
    }

    async function handleCheckClassLocationStatusApi(companyId, callback) {
        try {
            const response = await Axios({
                url: `/api/v1/intuit/class-location-status/${companyId}`,
                method: 'get',
            })

            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    function handleFilterExcludedAccounts(excludedAccounts, lists) {
        return lists?.filter((list) => !excludedAccounts?.includes(list?.label))
    }

    async function handleUploadApprovedAttachmentsApi(transIds, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/intuit/upload',
                method: 'post',
                data: {
                    transIds,
                },
            })

            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            console.log(error)
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleFetchPaymentAccountsApi(companyId, callback) {
        try {
            const response = await Axios({
                url: `/api/v1/intuit/payment-accounts/${companyId}`,
                method: 'get',
            })

            if (response.status === 200) {
                callback && callback()
                setPaymentAccounts(response.data?.accounts)
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleFetchSplitTransactionsApi(payload, callback) {
        try {
            const response = await Axios({
                url: `/api/v1/intuit/split/transaction?companyId=${payload?.companyId}&transType=${payload?.transType}&transId=${payload?.transId}`,
                method: 'get',
            })

            if (response.status === 200) {
                callback && callback()
                setSplitTransactions(response.data)
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleSplitTransactionsApi(payload, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/intuit/split/transaction',
                method: 'post',
                data: {
                    ...payload,
                },
            })

            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    // INVITES
    async function handleFetchCompanyInvitesApi(companyId, callback) {
        try {
            const response = await Axios({
                url: `/api/v1/invited/${companyId}`,
                method: 'get',
            })
            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    // UTILITIES
    async function handleGenerateMagicLinkApi(clientEmail, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/auth/magic/generate-short-link',
                method: 'post',
                data: {
                    email: clientEmail,
                },
            })
            if (response.status === 200) {
                callback && callback()
                return response.data?.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleUploadFilesAttachmentApi(payload, callback) {
        const { files, transId, transType, lineId, companyId } = payload
        const formData = new FormData()
        formData.append('transId', transId)
        formData.append('transType', transType)
        formData.append('lineId', lineId)
        formData.append('companyId', companyId)
        for (let i = 0; i < files.files.length; i++) {
            formData.append('files', files.files[i])
        }

        // localStorage.setItem('refreshTransactions', JSON.stringify(true))
        localStorage.removeItem('refreshTransactions')

        try {
            const response = await Axios({
                url: '/api/v1/utility/upload',
                method: 'post',
                data: formData,
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            ErrorNotification({
                title: 'File Uploads Error.',
                message: error?.response?.data?.message || error?.statusText,
            })
            callback && callback()
            // throw new Error()
        }
    }

    async function handleDeleteFilesAttachmentApi(payload, callback) {
        localStorage.setItem('refreshTransactions', JSON.stringify(true))
        try {
            const response = await Axios({
                url: '/api/v1/utility/deleteupload',
                method: 'post',
                data: payload,
            })
            if (response.status === 200) {
                callback && callback()
                return true
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleVerifyMagicLinkApi(params, callback) {
        try {
            const response = await Axios({
                url: `/api/v1/auth/magic/verify-short-link/${params?.shortId}`,
                method: 'get',
            })

            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    async function handleRegisterEventApi(name, callback) {
        try {
            const response = await Axios({
                url: '/api/v1/event',
                method: 'post',
                data: {
                    name,
                },
            })
            if (response.status === 200) {
                callback && callback()
                return response.data
            }
        } catch (error) {
            // ErrorNotification()
            callback && callback()
            throw new Error()
        }
    }

    function handleResetFilters() {
        setAdvSearchValue({})
        setSearchValue('')
        setSearchQuery('')
        setPage(1)
        // setTotalPerPage(defaultTotalPerPage)
        setDateFrom('1800-01-01')
        setDateTo(new Date().toISOString().split('T')[0])
        setIsToggleApproveAllActive(false)
        setIsToggleBulkChangeActive(false)
        setFetchTransactionType()
    }

    // PRIVATE METHODS
    // function handleChangeUrl() {
    //     window.location.pathname = '/company'
    // }

    return (
        <ApiContext.Provider
            value={{
                // STATES
                companies,
                transactions,
                paymentAccounts,
                transactionsCount,
                buttonLoading,
                searchValue,
                page,
                totalPerPage,
                advSearchValue,
                companyProfile,
                companiesCount,
                company,
                client,
                clients,
                status,
                activity,
                dateFrom,
                dateTo,
                searchQuery,
                view,
                isToggleViewActive,
                isToggleApproveAllActive,
                isToggleBulkChangeActive,
                lists,
                requestToken,
                selectedListItemForBulkChange,
                isDateFilterActive,
                sortField,
                sortOrder,
                refreshTransactions,
                refresh,
                fetchTransactionType,
                selectedBulkOption,
                categorizeMePrevPage,
                underReviewPrevPage,
                approvedPrevPage,
                triggerActivityLogFilter,
                companyFilterLogs,
                staffFilterLogs,
                clientFilterLogs,
                clientStaffFilterLogs,
                splitTransactions,

                // STATE SETTER
                setButtonLoading,
                setAdvSearchValue,
                setSearchValue,
                setSearchQuery,
                setPage,
                setTotalPerPage,
                setCompany,
                setClient,
                setStatus,
                setActivity,
                setView,
                setIsToggleViewActive,
                setIsToggleApproveAllActive,
                setIsToggleBulkChangeActive,
                setClients,
                setDateFrom,
                setDateTo,
                setSelectedListItemForBulkChange,
                setIsDateFilterActive,
                setSortField,
                setSortOrder,
                setRefreshTransactions,
                setRefresh,
                setFetchTransactionType,
                setSelectedBulkOption,
                setCategorizeMePrevPage,
                setUnderReviewPrevPage,
                setApprovedPrevPage,
                setTriggerActivityLogFilter,
                setCompanyFilterLogs,
                setStaffFilterLogs,
                setClientFilterLogs,
                setClientStaffFilterLogs,
                setTransactions,
                setPaymentAccounts,
                setSplitTransactions,

                // PUBLIC METHODS
                handleFetchAccountClientsRequest: handleFetchAccountClientsApi,
                handleGenerateMagicLinkRequest: handleGenerateMagicLinkApi,
                handleFetchCompaniesRequest: handleFetchCompaniesApi,
                handleFetchCompanyProfileRequest: handleFetchCompanyProfileApi,
                handleUpdateCompanyRequest: handleUpdateCompanyApi,
                handleFetchCompaniesCountRequest: handleFetchCompaniesCountApi,
                handleFetchStaffCompaniesRequest: handleFetchStaffCompaniesApi,
                handleFetchTransactionsRequest: handleFetchTransactionsApi,
                handleFetchActivityLogsRequest: handleFetchActivityLogsApi,
                handleEditStaffCompanyListRequest: handleEditStaffCompanyListApi,
                handleFetchStaffUserAccountRequest: handleFetchStaffUserAccountApi,
                handleUpdateClientsRequest: handleUpdateClientsApi,
                handleClientNotificationRequest: handleClientNotificationApi,
                handleClientInvitationRequest: handleClientInvitationApi,
                handleGetAuthorizationUriRequest: handleGetAuthorizationUriApi,
                handleRequestTokenRequest: handleRequestTokenApi,
                handleFetchListRequest: handleFetchListApi,
                handleSetScheduleNotificationRequest: handleSetScheduleNotificationApi,
                handleClientRevokeAccessRequest: handleClientRevokeAccessApi,
                handleClientDisconnectionRequest: handleClientDisconnectionApi,
                // handleActivateDeactivateAccountsRequest: handleActivateDeactivateAccountsApi,
                handleFilterExcludedAccountsRequest: handleFilterExcludedAccounts,
                handleUploadFilesAttachmentRequest: handleUploadFilesAttachmentApi,
                handleDeleteFilesAttachmentRequest: handleDeleteFilesAttachmentApi,
                handleVerifyMagicLinkRequest: handleVerifyMagicLinkApi,
                handleApproveChangesRequest: handleApproveChangesApi,
                handleUpdateIntuitTransactionRequest: handleUpdateIntuitTransactionApi,
                handleUpdateTransactionRequest: handleUpdateTransactionApi,
                handleDeleteApprovedTransactionsRequest: handleDeleteApprovedTransactionsApi,
                handleDeleteApprovedTransactionRequest: handleDeleteApprovedTransactionApi,
                handleUpdateSplitTransactionsRequest: handleUpdateSplitTransactionsApi,
                handleApproveSplitTransactionsRequest: handleApproveSplitTransactionsApi,
                handleUpdateIntuitTransactionsRequest: handleUpdateIntuitTransactionsApi,
                handleAccountingImmediateNotificationRequest: handleAccountingImmediateNotificationApi,
                handleClientCheckExistenceRequest: handleClientCheckExistenceApi,
                handleAdminCreateListRequest: handleAdminCreateListApi,
                handleAddEntityRequest: handleAddEntityApi,
                handleCheckClassLocationStatusRequest: handleCheckClassLocationStatusApi,
                handleDeleteCompanyRequest: handleDeleteCompanyApi,
                handleUploadApprovedAttachmentsRequest: handleUploadApprovedAttachmentsApi,
                handleFetchPaymentAccountsRequest: handleFetchPaymentAccountsApi,
                handleFetchSplitTransactionsRequest: handleFetchSplitTransactionsApi,
                handleSplitTransactionsRequest: handleSplitTransactionsApi,
                handleFetchCompanyInvitesRequest: handleFetchCompanyInvitesApi,
                handleRegisterEventRequest: handleRegisterEventApi,
                handleResetFilters,
                handleInviteStaffRequest: handleInviteStaffApi,
            }}
            {...props}
        />
    )
}
