import { useContext } from 'react'

import { Icon, ViewContext } from 'components/lib'
import Style from './button.tailwind'

export const ClientButton = (props) => {
    // CONTEXT
    const viewContext = useContext(ViewContext)

    return (
        <button disabled={viewContext.loading || props?.disabled} className={`${Style.container} ${props.bg} ${props.width} ${props?.additionalClassNames}`} onClick={props?.callback}>
            {props?.icon && (
                <span className={Style.iconContainer}>
                    <Icon image={props.icon} size={props.size || 14} className={props.color || 'text-black'} />
                </span>
            )}
            <span className={props.color}>{props.label || 'Add label'}</span>
            {props?.iconEnd && (
                <span className={Style.iconContainer}>
                    <Icon image={props.iconEnd} size={props.size || 14} className={props.color || 'text-black'} />
                </span>
            )}
        </button>
    )
}
