import Home from 'views/home'
import MagicPage from 'views/categorizeme/magic-page'
import { Contact } from 'views/website/contact'
import { Pricing } from 'views/website/pricing'
import { Privacy } from 'views/website/privacy'
import { Terms } from 'views/website/terms'
import { NotFound } from 'views/error/404'

const Routes = [
    {
        path: '/',
        view: Home,
        layout: 'landingpage',
        title: 'Categorize Me - Fix Uncategorized Transactions',
    },
    {
        path: '/:shortId',
        view: MagicPage,
        layout: 'fullwith',
        title: 'Magic Auth',
    },
    {
        path: '/contact',
        view: Contact,
        layout: 'landingpage',
        title: 'Contact',
    },
    {
        path: '/pricing',
        view: Pricing,
        layout: 'landingpage',
        title: 'Pricing',
    },
    {
        path: '/privacy',
        view: Privacy,
        layout: 'landingpage',
        title: 'Privacy Policy',
    },
    {
        path: '/terms',
        view: Terms,
        layout: 'landingpage',
        title: 'Terms & Conditions',
    },
    {
        path: '/notfound',
        view: NotFound,
        layout: 'fullwith',
        title: '404 Not Found',
    },
]

export default Routes
