import { Fragment, useContext, useEffect, useState } from 'react'
import { Tooltip, Switch, Select, Skeleton } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'

import { Animate, ClientButton, Icon, SettingsNav, Card, ViewContext, useNavigate } from 'components/lib'
import { ApiContext } from 'context/api'
import { ErrorNotification } from 'utils/notifications'

export function NotificationSettings(props) {
    // CONTEXT
    const viewContext = useContext(ViewContext)
    const { handleSetScheduleNotificationRequest, handleUpdateClientsRequest, handleFetchCompanyProfileRequest, handleUpdateCompanyRequest, companyProfile } = useContext(ApiContext)

    // CONSTANTS
    const navigate = useNavigate()
    const pathname = window.location.pathname
    const companyId = pathname.split('/')[2]
    const id = companyId === ':companyId' ? companyProfile?.id : companyId
    // Get the first client admin from the clients array
    const clientAdmin = companyProfile?.clients?.find((client) => client?.permission === 'user')

    // STATES
    const [enablePeriodicNotification, setEnablePeriodicNotification] = useState()
    const [enableImmediateNotification, setEnableImmediateNotification] = useState()
    const [periodNotification, setPeriodNotification] = useState()
    const [dayOfNotification, setDayOfNotification] = useState()

    // METHODS
    async function handleSaveSettings() {
        if (companyProfile?.editedTransactions.length) {
            return handleApproveAllTransactionsNotification()
        }

        viewContext.setLoading(true)

        const clientPayload = {
            notificationSettings: {
                enablePeriodicNotification,
                enableImmediateNotification,
                periodNotification,
                dayOfNotification,
            },
        }

        if (!clientAdmin?.id) {
            const message = (
                <span>
                    Please{' '}
                    <span
                        onClick={handleChangeUrl}
                        style={{
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        invite
                    </span>{' '}
                    your client prior to customizing their settings.
                </span>
            )
            viewContext.setLoading(false)
            return ErrorNotification({ icon: <IconInfoCircle />, message })
        }

        const updateClientResponse = await handleUpdateClientsRequest({ clientId: clientAdmin?.id, ...clientPayload })

        if (!updateClientResponse) {
            viewContext.setLoading(false)
            return false
        }

        await handleUpdateCompanyRequest({ companyId: id })

        await handleFetchCompanyProfileRequest(id)

        await handleSetScheduleNotificationRequest({ clientId: clientAdmin?.id, companyName: companyProfile?.companyName, ...clientPayload?.notificationSettings }, handleOnLoadActions)
    }

    function handleApproveAllTransactionsNotification() {
        const message = (
            <span>
                You must approve all{' '}
                <span onClick={() => navigate(`/company/${id}/transaction`)} style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}>
                    transactions
                </span>{' '}
                before you edit the settings for this company.
            </span>
        )

        return ErrorNotification({ title: 'Transactions', message, icon: <IconInfoCircle /> })
    }

    function handleChangeUrl() {
        window.location.pathname = '/company'
    }

    function handleOnLoadActions(data) {
        viewContext.setLoading(false)
    }

    /* eslint-disable */
    useEffect(() => {
        // NAVIGATE
        navigate(`/company/${id}/notification-settings`)

        // SET LOADER
        viewContext.setLoading(true)

        // API CALLS
        handleFetchCompanyProfileRequest(id, handleOnLoadActions).then((companyProfileData) => {
            if (companyProfileData) {
                setEnablePeriodicNotification(companyProfileData?.notificationSettings.enablePeriodicNotification)
                setEnableImmediateNotification(companyProfileData?.notificationSettings.enableImmediateNotification)
                setPeriodNotification(companyProfileData?.notificationSettings.periodNotification)
                setDayOfNotification(companyProfileData?.notificationSettings.dayOfNotification)
            }
            if (companyProfileData?.editedTransactions.length) {
                handleApproveAllTransactionsNotification()
            }
        })
    }, [])

    return (
        <>
            <SettingsNav companyId={id} />
            {viewContext.loading ? (
                <Skeleton visible={viewContext.loading} />
            ) : (
                <Animate type='pop'>
                    <Card restrictWidth>
                        <div className='w-full flex flex-col justify-center gap-4'>
                            <p>Reminder notifications will automatically be sent by email to clients on the schedule set below.</p>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <p className='font-bold text-sm'>Enable notifications</p>
                                    <Tooltip label='Enable client notification' color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Switch disabled={companyProfile?.editedTransactions.length} color='cyan' checked={enablePeriodicNotification} onChange={(e) => setEnablePeriodicNotification(e.target.checked)} />
                                </div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <span className='text-red-500'>*</span>
                                    <p className='font-bold text-sm'>Period</p>
                                </div>
                                <div>
                                    <Select
                                        disabled={companyProfile?.editedTransactions.length || !enablePeriodicNotification}
                                        data={['Daily', 'Weekly', 'Monthly']}
                                        value={periodNotification}
                                        onChange={(value) => setPeriodNotification(value)}
                                        placeholder='Choose a period'
                                        searchable
                                        nothingFound='No options'
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <span className='text-red-500'>*</span>
                                    <p className='font-bold text-sm'>Day of week</p>
                                </div>
                                <div>
                                    <Select
                                        disabled={companyProfile?.editedTransactions.length || !enablePeriodicNotification || !periodNotification || periodNotification !== 'Weekly'}
                                        data={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']}
                                        onChange={(value) => setDayOfNotification(value)}
                                        value={dayOfNotification}
                                        searchable
                                        placeholder='Choose a day'
                                        nothingFound='No options'
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <p className='font-bold text-sm'>Notify me immediately when client makes updates</p>
                                    <Tooltip label='Enable immidiate notification when the clients updated the transactions' color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Switch disabled={companyProfile?.editedTransactions.length} color='cyan' checked={enableImmediateNotification} onChange={(e) => setEnableImmediateNotification(e.target.checked)} />
                                </div>
                            </div>

                            <div className='w-20 flex flex-col justify-center gap-1'>
                                <ClientButton disabled={viewContext.loading} bg='bg-cyan-500' icon='save' color='text-white' label='Save' callback={handleSaveSettings} />
                            </div>
                        </div>
                    </Card>
                </Animate>
            )}
        </>
    )
}
