import { TPContainer } from 'components/lib'
import avatarImage1 from './images/avatars/avatar-1.png'
import avatarImage2 from './images/avatars/avatar-2.png'
import avatarImage3 from './images/avatars/avatar-3.png'
import avatarImage4 from './images/avatars/avatar-4.png'
import avatarImage5 from './images/avatars/avatar-5.png'
import avatarImage6 from './images/avatars/avatar-6.png'

const testimonials = [
    [
        {
            content: 'Categorize Me is a game changer for accountants. My clients and I love this system. It saves us so much time in admin work and we even get answers back from clients faster than ever.',
            author: {
                name: 'Amy Northard, CPA',
                role: 'Certified Public Accountant',
                image: avatarImage1,
            },
        },
        {
            content:
                'With Categorize Me, you can easily get all the answers you need from your clients and reclassify uncategorized transactions all at once! It is a win-win for accountants and clients alike - we think you will love it!',
            author: {
                name: 'Hannah McCooey',
                role: 'Bookkeeper',
                image: avatarImage2,
            },
        },
    ],
    [
        {
            content:
                'Categorize Me is a lifesaver for accountants and bookkeepers; it syncs with QuickBooks Online and efficiently categorizes transactions, saving time and improving client communication.',
            author: {
                name: 'Rachel Pollock, CPA',
                role: 'Certified Public Accountant',
                image: avatarImage3,
            },
        },
        {
            content:
                'I love this system! We used to use a competitor in this space but untimately switched to Categorize Me because it is cheaper and has a better user interface.',
            author: {
                name: 'Sarah Wilson',
                role: 'Accountant',
                image: avatarImage4,
            },
        },
    ],
    [
        {
            content:
                'We are thrilled with how much time Categorize Me has saved us and our clients! With this handy system, our clients no longer have to deal with endless spreadsheets for each entity. Instead, they can easily view and categorize all transactions in one place, resulting in a significant boost in productivity for our accounting team. The switch to Categorize Me has been a game-changer, and our clients love it too!',
            author: {
                name: 'Ashley Dedeaux',
                role: 'Certified Public Accountant',
                image: avatarImage5,
            },
        },
        {
            content:
                'I love how convenient it is to use this system to deal with uncategorized items for my clients! No more lengthy email chains or QB extracts to worry about. Plus, the client can easily add comments without having to log in to QB, making it much more accessible for them. I get notified automatically when they are done, so it is a stress-free process for everyone involved!',
            author: {
                name: 'Anna Smith',
                role: 'Senior Accountant',
                image: avatarImage6,
            },
        },
    ],
]

function QuoteIcon(props) {
    return (
        <svg aria-hidden='true' width={105} height={78} {...props}>
            <path d='M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z' />
        </svg>
    )
}

export function TPTestimonials() {
    return (
        <section id='testimonials' aria-label='What our customers are saying' className='bg-slate-50 py-20 sm:py-32'>
            <TPContainer>
                <div className='mx-auto max-w-2xl md:text-center'>
                    <h2 className='font-["Lexend"] text-3xl tracking-tight text-slate-900 sm:text-4xl flex justify-center'>We&nbsp; 
                        <svg  aria-hidden='true' width={35} height={35} className='fill-red-600'>
<path d='M 16.132812 30.132812 C 15.386719 29.289062 13.519531 27.640625 11.984375 26.476562 C 7.441406 23.015625 6.820312 22.515625 4.976562 20.800781 C 1.574219 17.644531 0.132812 14.472656 0.136719 10.171875 C 0.136719 8.070312 0.28125 7.261719 0.863281 6.027344 C 1.851562 3.925781 3.3125 2.367188 5.175781 1.410156 C 6.496094 0.734375 7.144531 0.433594 9.351562 0.421875 C 11.65625 0.410156 12.140625 0.679688 13.496094 1.433594 C 15.148438 2.347656 16.847656 4.300781 17.199219 5.6875 L 17.414062 6.546875 L 17.949219 5.363281 C 20.972656 -1.308594 30.625 -1.207031 33.984375 5.53125 C 35.050781 7.667969 35.167969 12.234375 34.222656 14.804688 C 32.992188 18.15625 30.675781 20.714844 25.324219 24.632812 C 21.8125 27.203125 17.84375 31.089844 17.566406 31.636719 C 17.246094 32.269531 17.550781 31.734375 16.132812 30.132812 Z M 16.132812 30.132812' />
</svg>&nbsp;Accountants</h2>
                    <p className='mt-4 text-lg tracking-tight text-slate-700'>
                        Categorize Me simplifies the process of fixing uncategorized transactions for accounting professionals! With easy note and receipt entry for clients, plus seamless sync with QuickBooks Online, you can say goodbye to spreadsheets.
                    </p>
                </div>
                {/* eslint-disable */}
                <ul role='list' className='mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3'>
                    {testimonials.map((column, columnIndex) => (
                        <li key={columnIndex}>
                            <ul role='list' className='flex flex-col gap-y-6 sm:gap-y-8'>
                                {column.map((testimonial, testimonialIndex) => (
                                    <li key={testimonialIndex}>
                                        <figure className='relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10'>
                                            <QuoteIcon className='absolute top-6 left-6 fill-slate-100' />
                                            <blockquote className='relative'>
                                                <p className='text-lg tracking-tight text-slate-900'>{testimonial.content}</p>
                                            </blockquote>
                                            <figcaption className='relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6'>
                                                <div>
                                                    <div className='font-["Lexend"] text-base text-slate-900'>{testimonial.author.name}</div>
                                                    <div className='mt-1 text-sm text-slate-500'>{testimonial.author.role}</div>
                                                </div>
                                                <div className='overflow-hidden rounded-full bg-slate-50'>
                                                    <img
                                                        className='h-14 w-14 object-cover'
                                                        src={testimonial.author.image}
                                                        alt=''
                                                        width={56}
                                                        height={56}
                                                    />
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </TPContainer>
        </section>
    )
}
