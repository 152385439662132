import { useContext, useRef } from 'react'
import { Tooltip } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'

import { Button, Icon, Label, ClassHelper } from 'components/lib'
import Style from './admin-action-buttons.tailwind'
import { ViewContext } from 'components/lib'
import { ErrorNotification } from 'utils/notifications'

export const AdminActionButtons = (props) => {
    const viewContext = useContext(ViewContext)
    const { notification, resendNotification, navigateSettings, deleteCompany, onLoadActions /* edit */ /* deleteClient */ } = props?.callbacks
    const isInvited = props?.company.invitedClient && !props?.company.isActive
    const messageRef = useRef()

    const textStyle = ClassHelper(Style, {
        textbox: true,
        input: true,
        className: false,
        error: false,
        success: false,
        warning: false,
    })

    function handleNotification() {
        if (isInvited) {
            resendNotification(props?.company, props?.data)
        } else {
            notification(props?.company, props?.data, messageRef.current.value)
            messageRef.current.value = ''
            viewContext.modal.hide()
        }
    }

    function handleMessage() {
        if (!props?.company?.isActive)
            return ErrorNotification({
                title: 'Notification',
                message: 'Client has not yet activated their account',
                icon: <IconInfoCircle />,
            })

        viewContext.modal.show({
            title: 'Message Details',
            body: (
                <>
                    <div className='pb-10'>
                        <Label for='message' text='CUSTOM MESSAGE (OPTIONAL):' />
                        <textarea id='message' className={textStyle} placeholder='Personalize the message to your client…' ref={messageRef}></textarea>
                        <Button cyan text='Send' action={handleNotification} />
                        <Button red text='Cancel' action={() => viewContext.modal.hide()} />
                    </div>
                </>
            ),
        })
    }

    return (
        <div className={Style.container}>
            {/* <Tooltip label='Attach document' color='violet' withArrow>
                <span onClick={attachment} className='cursor-pointer lg:m-2'>
                    <Icon image='paperclip' color='#000' size={20} />
                </span>
            </Tooltip> */}

            <Tooltip label={isInvited ? 'Resend invitation' : 'Notify client'} color='cyan' withArrow>
                <span onClick={isInvited ? handleNotification : handleMessage} className='cursor-pointer lg:m-2'>
                    <Icon image={isInvited ? 'mail' : 'bell'} color='#000' size={20} />
                </span>
            </Tooltip>

            <Tooltip label='Go to settings' color='cyan' withArrow>
                <span onClick={() => navigateSettings(props?.company)} className='cursor-pointer lg:m-2'>
                    <Icon image='settings' color='#000' size={20} />
                </span>
            </Tooltip>

            {/* <Tooltip label='Edit client' color='cyan' withArrow>
                <span onClick={() => edit(props?.client, props?.data)} className='cursor-pointer lg:m-2'>
                    <Icon image='edit' color='#000' size={20} />
                </span>
            </Tooltip> */}

            {!props?.company?.intuitEnabled && (
                <Tooltip label='Delete company' color='cyan' withArrow>
                    <span onClick={() => deleteCompany({ company_Id: props?.company?._id, companyId: props?.company?.id }, onLoadActions)} className='cursor-pointer lg:m-2'>
                        <Icon image='trash-2' color='red' size={20} />
                    </span>
                </Tooltip>
            )}
        </div>
    )
}
