import { Link } from 'react-router-dom'

import { TPContainer, TPNavLink } from 'components/lib'
import logo from './images/logos/logo-mark-color.svg'

export function TPFooter() {
    return (
        <footer className='bg-slate-50'>
            <TPContainer>
                {
                    <div className='py-16'>
                        <Link to='/' aria-label='Home'>
                            {<img src={logo} alt='' width={40} height={40} unoptimized='true' />}
                        </Link>
                        <nav className='mt-10 text-sm' aria-label='quick links'>
                            <div className='-my-1 flex justify-center gap-x-6'>
                                <TPNavLink href='/privacy'>Privacy</TPNavLink>
                                <TPNavLink href='/terms'>Terms</TPNavLink>
                                <TPNavLink href='/contact'>Contact</TPNavLink>
                            </div>
                        </nav>
                    </div>
                }
                <div className='flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between'>
                    <p className='mt-6 mx-auto text-sm text-slate-500 sm:mt-0'>Copyright &copy; {new Date().getFullYear()} Categorize Me. All rights reserved.</p>
                </div>
            </TPContainer>
        </footer>
    )
}
