/***
 *
 *   ACCOUNT NAV
 *   Acount sub nav that renders different options based
 *   on user permissions. Shows billing & user admin to only account owners
 *
 **********/

import { SubNav } from 'components/lib'
// import { useContext } from 'react'
// import { AuthContext } from 'components/lib'

export function AccountNav(props) {
    // const context = useContext(AuthContext)

    return (
        <SubNav
            items={[
                { link: '/account/profile', label: 'Profile' },
                { link: '/account/password', label: 'Password' },
                { link: '/account/2fa', label: '2FA' },
                { link: '/account/billing', label: 'Billing', permission: 'owner' },
                // { link: '/account/apikeys/', label: 'API Keys', permission: 'developer' },
                // { link: '/account/users', label: 'Users', permission: 'admin' },
                // { link: context.permission?.staff ? '/account/accounting-staff' : '/account/client-staff', label: 'Staff', permission: context.permission?.staff ? 'owner' : 'user' },
                { link: '/account/accounting-staff', label: 'Staff', permission: 'owner' },
                { link: '/account/activity-logs', label: 'Activity Logs', permission: 'clientStaff' },
            ]}
        />
    )
}
