import { useState } from 'react'
import { TreeSelect } from 'antd'
import { Icon } from 'components/lib'

export const Tree = (props) => {
    const [value, setValue] = useState()

    const handleSingleOnChange = (newValue) => {
        const { value, ...rest } = props
        setValue(newValue)
        props?.callback({ value: newValue, ...rest })
    }

    const handleMultipleOnChange = (newValue) => {
        setValue(newValue)
        props?.callback(newValue)
    }

    return (
        <TreeSelect
            showSearch={props?.showSearch}
            multiple={props?.multiple}
            allowClear={props?.allowClear}
            disabled={props?.disabled}
            // treeDefaultExpandAll
            placeholder={props?.placeholder || 'Please Select'}
            value={value || props?.value}
            treeData={props?.list}
            treeNodeFilterProp={props?.multiple ? 'label' : 'title'}
            onChange={props?.multiple ? handleMultipleOnChange : handleSingleOnChange}
            style={{
                width: '100%',
                minWidth: '180px',
                height: props?.multiple ? 'unset' : '36px',
                borderRadius: '4px',
                borderColor: '#ced4da',
            }}
            dropdownStyle={{
                maxHeight: 400,
                minWidth: 180,
                overflow: 'auto',
            }}
            switcherIcon={(icon) => (icon.expanded ? <Icon image='chevron-down' className='mt-1' /> : <Icon image='chevron-right' className='mt-1' />)}
        />
    )
}
