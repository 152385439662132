// import { Label} from 'components/lib'
import Style from './checkbox.tailwind.js'

export function Checkbox(props) {
    return (
        <div>
            <input
                type='checkbox'
                name={props.name}
                id={props.id}
                value={props.value}
                className={props?.admin ? Style.inputParent : Style.inputChild}
                // checked={props.checked ? 'checked' : ''}
                onChange={props.callback}
                // onChange={(e) => props.callback(props.index, props.checked, props.option)}
            />

            {/* <Label text={props.option} required={props.required} for={props.option} className={Style.label} /> */}
        </div>
    )
}
