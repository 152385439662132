import { useId } from 'react'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'

import { TPContainer } from 'components/lib'
import screenshotContacts from './images/screenshots/step-three.svg'
import screenshotInventory from './images/screenshots/step-two.svg'
import screenshotProfitLoss from './images/screenshots/step-one.svg'

const features = [
    {
        name: 'Step 1',
        summary: 'Start A Free 7-day Trial',
        description: 'Connect your clients accounts to QuickBooks Online and invite all of your coworkers.',
        image: screenshotProfitLoss,
        icon: function ReportingIcon() {
            let id = useId()
            return (
                <>
                    <defs>
                        <linearGradient id={id} x1='11.5' y1={18} x2={36} y2='15.5' gradientUnits='userSpaceOnUse'>
                            <stop offset='.194' stopColor='#fff' />
                            <stop offset={1} stopColor='#6692F1' />
                        </linearGradient>
                    </defs>
                    <path
                        d='M 10.9375 2.5 L 10.9375 17.5 C 10.9375 17.84375 10.65625 18.125 10.3125 18.125 C 9.96875 18.125 9.6875 17.84375 9.6875 17.5 L 9.6875 3.664062 L 6.90625 5.515625 C 6.621094 5.707031 6.230469 5.628906 6.039062 5.34375 C 5.851562 5.054688 5.933594 4.667969 6.21875 4.476562 L 9.96875 1.976562 C 10.160156 1.855469 10.40625 1.84375 10.609375 1.945312 C 10.8125 2.054688 10.9375 2.269531 10.9375 2.5 Z M 10.9375 2.5 '
                        fill='white'
                    />
                </>
            )
        },
    },
    {
        name: 'Step 2',
        summary: 'Invite Your Clients',
        description: 'Categorize Me automatically notifies your clients when there are new uncategorized transactions. Clients will receive an email with a Magic Link to add descriptions and attachments.',
        image: screenshotInventory,
        icon: function InventoryIcon() {
            return (
                <>
                    <path
                        d='M 14.375 17.5 C 14.375 17.84375 14.09375 18.125 13.75 18.125 L 6.25 18.125 C 6.183594 18.125 6.117188 18.117188 6.054688 18.09375 C 5.796875 18.007812 5.625 17.769531 5.625 17.5 C 5.625 17.359375 5.671875 17.222656 5.757812 17.109375 L 12.601562 7.984375 C 13.242188 6.996094 13.273438 5.730469 12.683594 4.714844 C 12.09375 3.695312 10.980469 3.09375 9.804688 3.160156 C 8.625 3.230469 7.589844 3.953125 7.117188 5.03125 C 7.042969 5.253906 6.851562 5.414062 6.617188 5.449219 C 6.386719 5.484375 6.152344 5.386719 6.015625 5.195312 C 5.878906 5.007812 5.863281 4.753906 5.96875 4.546875 C 6.613281 3.011719 8.078125 1.976562 9.738281 1.878906 C 11.402344 1.777344 12.976562 2.632812 13.800781 4.078125 C 14.625 5.527344 14.558594 7.316406 13.625 8.695312 C 13.625 8.703125 13.617188 8.710938 13.609375 8.71875 L 7.5 16.875 L 13.75 16.875 C 14.09375 16.875 14.375 17.15625 14.375 17.5 Z M 14.375 17.5 '
                        fill='white'
                    />
                </>
            )
        },
    },
    {
        name: 'Step 3',
        summary: 'Categorize, Approve, and Sync',
        description:
            'With Categorize Me, categorizing transactions, assigning classes, and more can be done easily within the same platform, without the need for copy-pasting or manual uploads. Once your clients has provided categories and notes you will be notified to approve the transaction before it is updated in QuickBooks Online.',
        image: screenshotContacts,
        icon: function ContactsIcon() {
            return (
                <>
                    <path
                        d='M 14.375 13.125 C 14.375 15.144531 13.15625 16.96875 11.289062 17.742188 C 9.425781 18.515625 7.273438 18.089844 5.84375 16.664062 C 5.597656 16.421875 5.597656 16.023438 5.839844 15.777344 C 6.082031 15.53125 6.480469 15.53125 6.726562 15.773438 C 7.925781 16.964844 9.777344 17.203125 11.238281 16.355469 C 12.699219 15.507812 13.414062 13.785156 12.976562 12.152344 C 12.542969 10.519531 11.066406 9.378906 9.375 9.375 C 9.140625 9.375 8.929688 9.246094 8.820312 9.039062 C 8.710938 8.832031 8.726562 8.578125 8.867188 8.390625 L 12.546875 3.125 L 6.25 3.125 C 5.90625 3.125 5.625 2.84375 5.625 2.5 C 5.625 2.15625 5.90625 1.875 6.25 1.875 L 13.75 1.875 C 13.984375 1.875 14.195312 2.003906 14.304688 2.210938 C 14.414062 2.417969 14.398438 2.671875 14.257812 2.859375 L 10.492188 8.25 C 12.761719 8.773438 14.371094 10.792969 14.375 13.125 Z M 14.375 13.125 '
                        fill='white'
                    />
                </>
            )
        },
    },
]

function Feature({ feature, isActive, className, ...props }) {
    return (
        <div className={clsx(className, !isActive && 'opacity-75 hover:opacity-100')} {...props}>
            <div className={clsx('w-9 rounded-lg', isActive ? 'bg-cyan-500' : 'bg-slate-500')}>
                <svg aria-hidden='true' className='h-9 w-9' fill='none' style={{ padding: '8px' }}>
                    <feature.icon />
                </svg>
            </div>
            <h3 className={clsx('mt-6 text-sm font-medium', isActive ? 'text-cyan-600' : 'text-slate-600')}>{feature.name}</h3>
            <p className='mt-2 font-["Lexend"] text-xl text-slate-900'>{feature.summary}</p>
            <p className='mt-4 text-sm text-slate-600'>{feature.description}</p>
        </div>
    )
}

function FeaturesMobile() {
    return (
        <div className='-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden'>
            {features.map((feature) => (
                <div key={feature.name}>
                    <Feature feature={feature} className='mx-auto max-w-2xl' isActive />
                    <div className='relative mt-10 pb-10'>
                        <div className='absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6' />
                        <div className='relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10'>
                            <img className='w-full' src={feature.image} alt='' sizes='52.75rem' />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

function FeaturesDesktop() {
    return (
        <Tab.Group as='div' className='hidden lg:mt-20 lg:block'>
            {({ selectedIndex }) => (
                <>
                    <Tab.List className='grid grid-cols-3 gap-x-8'>
                        {features.map((feature, featureIndex) => (
                            <Feature
                                key={feature.name}
                                feature={{
                                    ...feature,
                                    name: (
                                        <Tab className='[&:not(:focus-visible)]:focus:outline-none'>
                                            <span className='absolute inset-0' />
                                            {feature.name}
                                        </Tab>
                                    ),
                                }}
                                isActive={featureIndex === selectedIndex}
                                className='relative'
                            />
                        ))}
                    </Tab.List>
                    <Tab.Panels className='relative mt-20 overflow-hidden rounded-4xl bg-slate-200 px-14 py-16 xl:px-16'>
                        <div className='-mx-5 flex'>
                            {features.map((feature, featureIndex) => (
                                <Tab.Panel
                                    static
                                    key={feature.name}
                                    className={clsx('px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none', featureIndex !== selectedIndex && 'opacity-60')}
                                    style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                                    aria-hidden={featureIndex !== selectedIndex}
                                >
                                    <div className='w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10'>
                                        <img className='w-full' src={feature.image} alt='' sizes='52.75rem' />
                                    </div>
                                </Tab.Panel>
                            ))}
                        </div>
                        <div className='pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10' />
                    </Tab.Panels>
                </>
            )}
        </Tab.Group>
    )
}

export function TPSecondaryFeatures() {
    return (
        <section id='secondary-features' aria-label='Features for simplifying everyday business tasks' className='pt-20 pb-14 sm:pb-20 sm:pt-32 lg:pb-32'>
            <TPContainer>
                <div className='mx-auto max-w-2xl md:text-center'>
                    <h2 className='font-["Lexend"] text-3xl tracking-tight text-slate-900 sm:text-4xl'>Simplify your bookkeeping process.</h2>
                    <p className='mt-4 text-lg tracking-tight text-slate-700'>Experience the power of Categorize Me in just minutes and start boosting your productivity.</p>
                </div>
                <FeaturesMobile />
                <FeaturesDesktop />
            </TPContainer>
        </section>
    )
}
