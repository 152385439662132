import { IconCheck, IconX } from '@tabler/icons-react'
import { showNotification } from '@mantine/notifications'

export function ErrorNotification(props) {
    const defaultTitle = 'Unknown Error'
    const defaultMessage = 'Something went wrong, please try again later.'

    showNotification({
        title: props?.title || defaultTitle,
        message: props?.message || defaultMessage,
        icon: props?.icon || <IconX />,
        color: props?.color || 'red',
    })
}

export function UpdateNotification(props) {
    showNotification({
        title: props?.title || 'Updates',
        message: props?.message || 'Successfully updated.',
        icon: props?.icon || <IconCheck />,
        color: props?.color || 'green',
    })
}
