export const RowVariants = {
    hidden: {
        opacity: 0,
        x: 300,
    },
    visible: {
        opacity: 1,
        x: 0,
    },
}

export const CardVariants = {
    hidden: {
        opacity: 0,
        x: 500,
    },
    visible: {
        opacity: 1,
        x: 0,
    },
}
