const Style = {
    inputParent: `float-left relative w-6 h-6 cursor-pointer mr-2 bg-none 
  border border-solid border-slate-200 bg-center appearance-none
  checked:bg-[url('components/form/checkbox/icons/minus.svg')] 
  checked:bg-cyan-500 checked:bg-[length:10px] p-1`,
    inputChild: `float-left relative w-6 h-6 cursor-pointer mr-2 bg-none 
  border border-solid border-slate-200 bg-center appearance-none
  checked:bg-[url('components/form/checkbox/icons/ico-check-white.svg')] 
  checked:bg-cyan-500 checked:bg-[length:10px] p-1`,
    label: 'cursor-pointer mt-px normal-case',
}

export default Style
