export const Classification = ['Asset', 'Equity', 'Expense', 'Liability', 'Revenue']
export const AccountType = (classification) => {
    if (classification === 'Asset') {
        return ['Bank', 'Other Current Asset', 'Fixed Asset', 'Other Asset', 'Accounts Receivable']
    } else if (classification === 'Equity') {
        return ['Equity']
    } else if (classification === 'Expense') {
        return ['Expense', 'Other Expense', 'Cost of Goods Sold']
    } else if (classification === 'Liability') {
        return ['Accounts Payable', 'Credit Card', 'Long Term Liability', 'Other Current Liability']
    } else if (classification === 'Revenue') {
        return ['Income', 'Other Income']
    } else return []
}
export const AccountSubType = (accountType) => {
    if (accountType === 'Bank') {
        return ['Cash On Hand', 'Checking', 'Money Market', 'Rents Held In Trust', 'Savings', 'Trust Accounts', 'Cash And Cash Equivalents', 'Other Ear Marked Bank Accounts']
    } else if (accountType === 'Other Current Asset') {
        return [
            'Allowance For Bad Debts',
            'Development Costs',
            'Employee Cash Advances',
            'Other Current Assets',
            'Inventory',
            'Investment_Mortgage Real Estate Loans',
            'Investment_Other',
            'Investment_Tax Exempt Securities',
            'Investment_US Government Obligations',
            'Loans To Officers',
            'Loans To Others',
            'Loans To Stockholders',
            'Prepaid Expenses',
            'Retainage',
            'Undeposited Funds',
            'Assets Available ForSale',
            'Bal With Govt Authorities',
            'Called Up Share Capital Not Paid',
            'Expenditure Authorisations And Letters Of Credit',
            'Global Tax Deferred',
            'Global Tax Refund',
            'Internal Transfers',
            'Other Consumables',
            'Provisions Curren Assets',
            'Short Term Investments In Related Parties',
            'Short Term Loans And Advances To Related Parties',
            'Trade And Other Receivables',
        ]
    } else if (accountType === 'Fixed Asset') {
        return [
            'Accumulated Depletion',
            'Accumulated Depreciation',
            'Depletable Assets',
            'Fixed Asset Computers',
            'Fixed Asset Copiers',
            'Fixed Asset Furniture',
            'Fixed Asset Phone',
            'Fixed Asset Photo Video',
            'Fixed Asset Software',
            'Fixed Asset Other Tools Equipment',
            'Furniture And Fixtures',
            'Land',
            'Leasehold Improvements',
            'Other Fixed Assets',
            'Accumulated Amortization',
            'Buildings',
            'Intangible Assets',
            'Machinery And Equipment',
            'Vehicles',
            'Assets In Course Of Construction',
            'Capital Wip',
            'Cumulative Depreciation On Intangible Assets',
            'Intangible Assets Under Development',
            'Land Asset',
            'Non Current Assets',
            'Participating Interests',
            'Provisions Fixed Assets',
        ]
    } else if (accountType === 'Other Asset') {
        return [
            'Lease Buyout',
            'Other Long Term Assets',
            'Security Deposits',
            'Accumulated Amortization Of Other Assets',
            'Goodwill',
            'Licenses',
            'Organizational Costs',
            'Assets Held For Sale',
            'Available For Sale Financial Assets',
            'Deferred Tax',
            'Investments',
            'Long Term Investments',
            'Long Term Loans And Advances To Related Parties',
            'Other Intangible Assets',
            'Other Long Term Investments',
            'Other Long Term Loans And Advances',
            'Prepayments And Accrued Income',
            'Provisions Non Current Assets',
        ]
    } else if (accountType === 'Accounts Receivable') {
        return ['Accounts Receivable']
    } else if (accountType === 'Equity') {
        return [
            'Opening Balance Equity',
            'Partners Equity',
            'Retained Earnings',
            'Accumulated Adjustment',
            'Owners Equity',
            'Paid In Capital Or Surplus',
            '​Partner Contributions',
            'Partner Distributions',
            'Preferred Stock',
            'Common Stock',
            'Treasury Stock',
            'Estimated Taxes',
            'Healthcare',
            'Personal Income',
            'Personal Expense',
            'Accumulated Other Comprehensive Income',
            'Called Up Share Capital',
            'Capital Reserves',
            'Dividend Disbursed',
            'Equity In Earnings Of Subsiduaries',
            'Investment Grants',
            'Money Received Against Share Warrants',
            'Other Free Reserves',
            'Share Application Money Pending Allotment',
            'Share Capital',
            'Funds',
        ]
    } else if (accountType === 'Expense') {
        return [
            'Advertising Promotional',
            'Bad Debts',
            'Bank Charges',
            'Charitable Contributions',
            'Commissions And Fees',
            'Entertainment',
            'Entertainment Meals',
            'Equipment Rental',
            'Finance Costs',
            'Global Tax Expense',
            'Insurance',
            'Interest Paid',
            'Legal Professional Fees',
            'Office Expenses',
            'Office General Administrative Expenses',
            'Other Business Expenses',
            'Othe Miscellaneous Service Cost',
            'Promotional Meals',
            'Rent Or Lease Of Buildings',
            'Repair Maintenance',
            'Shipping Freight Delivery',
            'Supplies Materials',
            'Travel',
            'Travel Meals',
            'Auto',
            'Cost Of Labor',
            'Dues Subscriptions',
            'Payroll Expenses',
            'Taxes Paid',
            'Unapplied Cash Bill Payment Expense',
            'Utilities',
            'Amortization Expense',
            'Appropriations To Depreciation',
            'Borrowing Cost',
            'Distribution Costs',
            'External Services',
            'Extraordinary Charges',
            'Income Tax Expense',
            'Loss On Discontinued Operations Net Of Tax',
            'Management Compensation',
            'Other Current Operating Charges',
            'Other External Services',
            'Other Rental Costs',
            'Other Selling Expenses',
            'Project Studies Surveys Assessments',
            'Purchases Rebates',
            'Shipping And Delivery Expense',
            'Staff Costs',
            'Sundry',
            'Travel Expenses General And Admin Expenses',
            'Travel Expenses Selling Expense',
        ]
    } else if (accountType === 'Other Expense') {
        return [
            'Depreciation',
            'Exchange Gain Or Loss',
            'Other Miscellaneous Expense',
            'Penalties Settlements',
            'Amortization',
            'Gas And Fuel',
            'Home Office',
            'Home Owner Rental Insurance',
            'Other Home Office Expenses',
            'Mortgage Interest',
            'Rent And Lease',
            'Repairs And Maintenance',
            'Parking And Tolls',
            'Vehicle',
            'Vehicle Insurance',
            'Vehicle Lease',
            'Vehicle Loan Interest',
            'Vehicle Loan',
            'Vehicle Registration',
            'Vehicle Repairs',
            'Other Vehicle Expenses',
            'Utilities',
            'Wash And Road Services',
            'Deferred Tax Expense',
            'Depletion',
            'Exceptional Items',
            'Extraordinary Items',
            'Income Tax Other Expense',
            'Mat Credit',
            'Prior Period Items',
            'Tax Roundoff Gain Or Loss',
        ]
    } else if (accountType === 'Cost of Goods Sold') {
        return ['Equipment Rental Cos', 'Other Costs Of Service Cos', 'Shipping Freight Delivery Cos', 'Supplies Materials Cogs', 'Cost Of Labor Cos', 'Cost Of Sales', 'Freight And Delivery Cost']
    } else if (accountType === 'Accounts Payable') {
        return ['Outstanding Dues Micro Small Enterprise', 'Outstanding Dues Other Than Micro Small Enterprise']
    } else if (accountType === 'Credit Card') {
        return ['CreditCard']
    } else if (accountType === 'Long Term Liability') {
        return [
            'Notes Payable',
            'Other Long Term Liabilities',
            'Shareholder Notes Payable',
            'Accruals And Deferred Income',
            'Accrued Long Lerm Liabilities',
            'Accrued Vacation Payable',
            'BankLoans',
            'Debts Related To Participating Interests',
            'Deferred Tax Liabilities',
            'Government And Other Public Authorities',
            'Group And Associates',
            'Liabilities Related To Assets Held For Sale',
            'Long Term Borrowings',
            'Long Term Debit',
            'Long Term Employee Benefit Obligations',
            'Obligations Under Finance Leases',
            'Other Long Term Provisions',
            'Provision For Liabilities',
            'Provisions Non Current Liabilities',
            'Staff And Related Long Term Liability Accounts',
        ]
    } else if (accountType === 'Other Current Liability') {
        return [
            'Direct Deposit Payable',
            'Line Of Credit',
            'Loan Payable',
            'Global Tax Payable',
            'Global Tax Suspense',
            'Other Current Liabilities',
            'Payroll Clearing',
            'Payroll Tax Payable',
            'Prepaid Expenses Payable',
            'Rents In Trust Liability',
            'Trust Accounts Liabilities',
            'Federal Income Tax Payable',
            'Insurance Payable',
            'Sales Tax Payable',
            'State Local Income Tax Payable',
            'Accrued Liabilities',
            'Current Liabilities',
            'Current Portion Employee Benefits Obligations',
            'Current Portion Of Obligations Under Finance Leases',
            'Current Tax Liability',
            'Dividends Payable',
            'Duties And Taxes',
            'Interest Payables',
            'Provision For Warranty Obligations',
            'Provisions Current Liabilities',
            'Short Term Borrowings',
            'Social Security Agencies',
            'Staff And Related Liability Accounts',
            'Sundry Debtors And Creditors',
            'Trade And Other Payables',
        ]
    } else if (accountType === 'Income') {
        return [
            'Non Profit Income',
            'Other Primary Income',
            'Sales Of Product Income',
            'Service Fee Income',
            'Discounts Refunds Given',
            'Unapplied Cash Payment Income',
            'Cash Receipt Income',
            'Operating Grants',
            'Other Current Operating Income',
            'OwnWork Capitalized',
            'Revenue General',
            'Sales Retail',
            'Sales Wholesale',
            'Savings By Tax Scheme',
        ]
    } else if (accountType === 'Other Income') {
        return [
            'Dividend Income',
            'Interest Earned',
            'Other Investment Income',
            'Other Miscellaneous Income',
            'Tax Exempt Interest',
            'Gain Loss On Sale Of Fixed Assets',
            'Gain Loss On Sale Of Investments',
            'Loss On Disposal Of Assets',
            'Other Operating Income',
            'Unrealised Loss On Securities Net Of Tax',
        ]
    } else return []
}
export const bulkChangeOptions = [
    { id: '1', value: 'Approve All' },
    { id: '2', value: 'Select Category', placeholder: 'Select Category...', error: 'Please select a category.', field: 'category' },
    { id: '3', value: 'Select Class', placeholder: 'Select Class...', error: 'Please select a class.', field: 'class' },
    { id: '4', value: 'Select Location', placeholder: 'Select Location...', error: 'Please select a location.', field: 'location' },
    { id: '5', value: 'Select Vendor', placeholder: 'Select Vendor', error: 'Please select a vendor.', field: 'vendor' },
    { id: '6', value: 'Select Customer', placeholder: 'Select Customer', error: 'Please select a customer.', field: 'customer' },
    { id: '7', value: 'Split Transaction' },
]
