const Style = {
    table: 'w-full border-separate -mb-6',
    search: 'mb-4 md:mb-0',
    loading: 'relative py-8',
    badge: 'ml-0',
    empty: 'py-3',
    thead: 'hidden md:hidden font-semibold lg:table-header-group',
    th_actions: 'text-right',

    th: 'text-left outline-0 capitalize py-5 px-2 border-b border-dotted border-slate-100 first:pl-0 last:pr-0',
    sort: `relative cursor-pointer after:absolute after:right-0 after:top-1/2 after:mt-1
    after:w-3 after:h-3 after:opacity-50 after:-translate-y-1/2 after:bg-contain`,

    asc: `after:bg-[url('components/table/icons/ico-sort-asc.svg')]`,
    desc: `after:bg-[url('components/table/icons/ico-sort-dsc.svg')]`,

    cell: `float-left w-full p-2 mb-2 first:pl-0 last:pr-0 last:pb-5 last:border-b last:border-solid 
    last:border-slate-100 lg:float-none lg:w-auto lg:mb-0 lg:py-5 lg:px-2 lg:border-b lg:border-solid lg:border-slate-100`,

    actions: `text-left float-left clear-left mb-4 whitespace-nowrap
  lg:float-none lg:clear-none lg:text-right lg:mb-0 border-b border-slate-100 border-solid`,

    actionButton: 'inline-block whitespace-nowrap -top-1 mr-3 bg-transparent last:mr-0',
}

export default Style
