import { SubNav } from 'components/lib'

export function SettingsNav(props) {
    return (
        <SubNav
            items={[
                { link: `/company/${props?.companyId}/general-settings`, label: 'General Settings', permission: 'staff' },
                { link: `/company/${props?.companyId}/notification-settings`, label: 'Notification Settings', permission: 'staff' },
                { link: `/company/${props?.companyId}/client-users`, label: 'Client Users', permission: 'user' },
                { link: `/company/${props?.companyId}/other-settings`, label: 'Other Settings', permission: 'staff' },
            ]}
        />
    )
}
