/***
 *
 *   FULLWITH LAYOUT
 *   Layout for the UTILITI pages (i.g. loading effect, error page)
 *
 **********/

import Style from './fullwith.tailwind.js'

export function FullWithLayout(props) {
    return <main className={Style.center}>{<props.children {...props.data} />}</main>
}
