/***
 *
 *   SIGN UP (user)
 *   Signup form for child users
 *
 **********/

import React, { useContext /* useEffect */ } from 'react'
import { Animate, Row, AuthContext, Card, Form, Link /* useAPI */ /* SocialSignin */ } from 'components/lib'

export function SignupUser(props) {
    const context = useContext(AuthContext)
    // const urlParams = new URLSearchParams(window.location.search)
    const url = window.location.href
    const id = url.substring(url.indexOf('?id=') + 4, url.indexOf('&email'))
    const email = url.substring(url.indexOf('&email') + 7)
    // const { data } = useAPI('/api/v1/invite?type=user')

    // useEffect(() => {
    //     if (data) {
    //         const el = document.getElementById('email')
    //         el.value = data[0]?.email
    //     }
    // }, [data])

    return (
        <Animate type='pop'>
            <Row title='Create Your Account'>
                <Card restrictWidth center>
                    {/* <SocialSignin network={['facebook', 'twitter']} showOr invite={ id } /> */}

                    <Form
                        data={{
                            name: {
                                label: 'First Name',
                                value: '',
                                type: 'text',
                                required: true,
                                errorMessage: 'Please enter your first name',
                            },
                            email: {
                                label: 'Email',
                                value: email,
                                type: 'email',
                                required: true,
                                isDisabled: true,
                            },
                            password: {
                                label: 'Password',
                                type: 'password',
                                required: true,
                                complexPassword: true,
                            },
                            confirm_password: {
                                type: 'hidden',
                                value: null,
                            },
                            invite_id: {
                                type: 'hidden',
                                value: id,
                            },
                        }}
                        url='/api/v1/user'
                        method='POST'
                        // redirect='/dashboard'
                        buttonText='Create Account'
                        callback={context.signin}
                    />

                    <div className='mt-4'>
                        Already registered? <Link url='/signin' text='Sign In' />
                    </div>
                </Card>
            </Row>
        </Animate>
    )
}
