/*
 * EVENT
 * log a new client event in the database
 */

import Axios from 'utils/axios'

export async function Event(name, metadata) {
    Axios.post('/api/v1/event', {
        name: name,
        metadata: metadata,
    })
}
