import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Alert } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'

import { Loader, ClientButton, ViewContext, Link } from 'components/lib'
import { ApiContext } from 'context/api'

const MagicPage = (props) => {
    const viewContext = useContext(ViewContext)
    const { handleVerifyMagicLinkRequest } = useContext(ApiContext)
    const params = useParams()

    const { data: verifyShortLink, isLoading: isVerifyShortLinkLoading } = useQuery({
        queryFn: () => handleVerifyMagicLinkRequest(params, handleOnLoadActions),
        queryKey: ['verifyMagicLink'],
    })

    function handleOnLoadActions() {
        viewContext.setLoading(false)
    }

    if (isVerifyShortLinkLoading) {
        return <Loader />
    } else {
        if (verifyShortLink) {
            return window.location.replace(verifyShortLink?.data)
        } else {
            return (
                <div className='flex flex-col justify-center gap-2 text-sm'>
                    <Alert icon={<IconInfoCircle size={16} />} title='Invalid Magic Link!' color='red'>
                        Please visit the sign in page to generate a new link.
                    </Alert>
                    <Link className='no-underline' url='/signin'>
                        <ClientButton label='Go to Signin Page >' bg='bg-cyan-500' color='text-white' />
                    </Link>
                </div>
            )
        }
    }
}

export default MagicPage
