import { Fragment, useEffect } from 'react'
import { useNavigate } from 'components/lib'

import { TPHero, TPPrimaryFeatures, TPSecondaryFeatures, TPCallToAction, TPTestimonials, TPPricing, TPFaqs } from 'components/lib'

function Home() {
    const user = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()

    /* eslint-disable */
    useEffect(() => {
        if (user && user?.jwt_token) {
            navigate('/company')
        }
    }, [])

    return (
        <Fragment>
            <TPHero />
            <TPPrimaryFeatures />
            <TPSecondaryFeatures />
            <TPCallToAction />
            <TPTestimonials />
            <TPPricing />
            <TPFaqs />
        </Fragment>
    )
}

export default Home
